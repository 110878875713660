import React, { useEffect, useState } from "react";

import { motion, useScroll } from "framer-motion";
import Progress from "../Progress/Progress";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getCatalogeAction } from "../../Action/CatalogeAction/CatalogeAction";
import { getBgCatalogeAction } from "../../Action/CatalogeAction/BgCatalogeAction";
import { getToken } from "../../Action/login";
import axios from "axios";
import Swal from "sweetalert2";
const Catalog = () => {

  const navigate = useNavigate();
  const getLanguge = useSelector((state) => state.getLanguge);
  const { lang } = getLanguge;

  const [error, setError] = useState(null);

  const downloadPdf = async (pdf) => {
    const parts = pdf.split('/');
    let name = '';
    Swal.showLoading()
    // Loop through the parts of the URL
    for (const part of parts) {
        // Check if the part starts with "Image__"
        if (part.startsWith("Image__")) {
            name = part;
            break;
        }
    }

    try {
        const response = await axios.get(
            "https://admin.omtayalitim.com/app/api/v1/pdf/download/" + name,
            {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }, // Important
            }
        );
      
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        
        // Change the downloaded file name to "omta_pdf.pdf"
        link.setAttribute("download", "omta_pdf.pdf");
        
        link.href = url;
        document.body.appendChild(link);
        link.click();

        // Cleanup
        window.URL.revokeObjectURL(url);
        if(response.status==200){
          Swal.hideLoading()
        }
    } catch (error) {
        setError("Failed to download PDF");
    }
};


  const dispatch = useDispatch();
  const getCataloge = useSelector((state) => state.getCataloge);
  const { cataloges, loading } = getCataloge;
  useEffect(() => {
    dispatch(getBgCatalogeAction());
  }, []);

  const getBgCataloge = useSelector((state) => state.getBgCataloge);
  const { bgCataloge } = getBgCataloge;
  useEffect(() => {
    dispatch(getCatalogeAction(1, 1000));
  }, []);
  const itemsPerPageDesktop = 5;
  const itemsPerPageMobile = 1;
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = () => {
    // Adjust the number of items based on the screen size
    return window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop;
  };

  if (!cataloges || cataloges.length === 0) {
    return null; // or display a loading indicator, message, etc.
  }

  const startIdx = currentPage * itemsPerPage();
  const endIdx = Math.min((currentPage + 1) * itemsPerPage(), cataloges.length);

  const handleNext = () => {
    setCurrentPage(
      (prevPage) =>
        (prevPage + 1) % Math.ceil(cataloges.length / itemsPerPage())
    );
  };

  const handlePrev = () => {
    setCurrentPage(
      (prevPage) =>
        (prevPage - 1 + Math.ceil(cataloges.length / itemsPerPage())) %
        Math.ceil(cataloges.length / itemsPerPage())
    );
  };

  return (
    <>
      {loading ||
      cataloges == undefined ||
      bgCataloge == undefined ||
      bgCataloge.length == 0 ? (
        <div className="w-full h-full absolute left-0 top-0 bg-black">
          <span className="loader absolute top-[50%] left-[50%] text-white"></span>
        </div>
      ) : (
        <>
          <motion.div
          onClick={()=>navigate('/cataloge')}
            className="   bg-black bg-center lg:bg-top  lg:bg-cover relative h-screen brightness-[.7] w-full  page-section"
            id="cataloge"
            style={{
              backgroundSize: "cover", 
              backgroundRepeat:"no-repeat",
              backgroundImage: `url("${bgCataloge.map((bg) => {
                return bg.bg_image;
              })}")`,
            }}
          />
          <div className="relative">
            <motion.div
              initial={{ opacity: 0.1, transition: { duration: 0.5 } }}
              animate={{ x: 0, transition: { duration: 0.5 }, opacity: 1 }}
              className=" text-white px-4 py-2  w-full absolute -top-[80%] lg:-top-[90%] ml-2"
            >
              <p
                className={
                  lang == "en" || lang == "tu"
                    ? "text-xl uppercase ml-2  "
                    : lang == "ar"
                    ? "text-xl uppercase ml-2 text-right  "
                    : "text-xl uppercase ml-2  "
                }
              >
                {lang == "en"
                  ? "Look at the"
                  : lang == "tu"
                  ? "şuna bakın"
                  : lang == "ar"
                  ? "انظر الى"
                  : "Look at the"}
              </p>

              <p
                className={
                  lang == "en" || lang == "tu"
                    ? "text-5xl xl:text-7xl   "
                    : lang == "ar"
                    ? "text-5xl xl:text-7xl    text-right "
                    : "text-5xl xl:text-7xl   "
                }
              >
                {lang == "en"
                  ? "Catalogue"
                  : lang == "tu"
                  ? "Katalog"
                  : lang == "ar"
                  ? "فهرس"
                  : "Catalogue"}
              </p>

              <p
                className={
                  lang == "en" || lang == "tu"
                    ? "text-base lg:text-xl  ml-2  "
                    : lang == "ar"
                    ? "text-base lg:text-xl  ml-2 text-right  "
                    : "text-base lg:text-xl  ml-2  lg:"
                }
              >
                {lang == "en"
                  ? "Viewing the catalogue"
                  : lang == "tu"
                  ? "Kataloğu görüntüleme"
                  : lang == "ar"
                  ? "عرض الكتالوج"
                  : "Viewing the catalogue"}
              </p>
              <p></p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 100, transition: { duration: 0.5 } }}
              animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
              className="bg-[#1e1e1e]  text-white py-10 px-2 lg:px-4 w-full "
            >
              <motion.div
                initial={{ opacity: 0.1, y: -10, transition: { duration: 1 } }}
                animate={{ y: 0, transition: { duration: 1 }, opacity: 1 }}
                className="flex   lg:flex gap-x-4 gap-y-3 justify-center overflow-x-auto relative"
              >
                <button
                  onClick={handlePrev}
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 px-4 py-2 text-white bg-gray-800 rounded-l-full cursor-pointer hover:bg-gray-700 focus:outline-none focus:ring focus:border-blue-300"
                >
                  &lt;
                </button>
                <button
                  onClick={handleNext}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 px-4 py-2 text-white bg-gray-800 rounded-r-full cursor-pointer hover:bg-gray-700 focus:outline-none focus:ring focus:border-blue-300"
                >
                  &gt;
                </button>

                {cataloges.slice(startIdx, endIdx).map((item, index) => (
                  <motion.div
                    key={index + startIdx}
                    initial={{ opacity: 0, y: 20, transition: { duration: 1 } }}
                    animate={{ opacity: 1, y: 0, transition: { duration: 1 } }}
                    exit={{ opacity: 0, y: 20, transition: { duration: 1 } }}
                    className="flip-card"
                  >
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <motion.img
                          src={item.image}
                          className="h-full  w-full object-cover"
                          alt={`Gallery Item ${index + startIdx}`}
                     
                        />
                      </div>
                      <div className="flip-card-back">
                        <div className="flex flex-col items-center">
                          {item.pdfs.map((pdf) => {
                            return (
                              <Link to={pdf.pdf} target="_blank">
                                <p className="titleAll text-lg hover:underline  cursor-pointer">
                                  PDF
                                </p>
                              </Link>
                            );
                          })}

                          <div>
                            {item.pdfs.map((pdf, index) => (
                              <button onClick={() => downloadPdf(pdf.pdf)}>
                                Download PDF
                              </button>
                            ))}
                          </div>
                          <p
                            onClick={() => {
                              navigate("/cataloge/details/" + item.id);
                            }}
                            className="cursor-pointer uppercase  hover:underline hidden"
                          >
                            {lang == "en"
                              ? "Picture"
                              : lang == "tu"
                              ? "Resim"
                              : lang == "ar"
                              ? " صورة"
                              : "Picture"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
            <Progress />
          </div>
        </>
      )}
    </>
  );
};

export default Catalog;
