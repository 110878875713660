import { api } from "../../Api/api";

import Swal from "sweetalert2";
import { getToken } from "../login";
import {
  CREATE_SLIDER_FAIL,
  CREATE_SLIDER_REQUEST,
  CREATE_SLIDER_SUCCESS,
  DELETE_SLIDER_FAIL,
  DELETE_SLIDER_REQUEST,
  DELETE_SLIDER_SUCCESS,
  FETCH_SLIDER_FAIL,
  FETCH_SLIDER_REQUEST,
  FETCH_SLIDER_SUCCESS,
  SHOW_SLIDER_FAIL,
  SHOW_SLIDER_REQUEST,
  SHOW_SLIDER_SUCCESS,
  UPDATE_SLIDER_FAIL,
  UPDATE_SLIDER_REQUEST,
  UPDATE_SLIDER_SUCCESS,
} from "../../ActionType/SliderType/slider";

export const getSliderAction = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  await api
    .get("slider", config)
    .then((res) => {
      dispatch({
        type: FETCH_SLIDER_SUCCESS,
        payload: res.data,
        // total: res.data.total,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_SLIDER_FAIL,
        payload: [],
        error: "",
      });
    });
};

export const createSliderAction = (image) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  const formData = new FormData();

  formData.append("image", image);

  dispatch({
    type: CREATE_SLIDER_REQUEST,
  });

  api
    .post("slider/create", formData, config)
    .then((res) => {
      console.log(res);
      if (res.status == 201) {
        dispatch({
          type: CREATE_SLIDER_SUCCESS,
          payload: res.data,
        });

        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Saved",
        });
      }
    })
    .catch((err) => {
      
      dispatch({
        type: CREATE_SLIDER_FAIL,
        payload: [],
      });
    });
};

export const deleteSliderAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: DELETE_SLIDER_REQUEST,
  });
  api
    .delete(`slider/delete/${id}`, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: DELETE_SLIDER_SUCCESS,
          payload: res.data,
        });
        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Delete",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: DELETE_SLIDER_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      console.log(err);
    });
};

export const showSliderAction = (id) => (dispatch) => {
  //   console.log(formData)
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: SHOW_SLIDER_REQUEST,
    // payload: res.data,
  });
  api
    .get(`slider/show/${id}`, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: SHOW_SLIDER_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SHOW_SLIDER_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      // console.log(err);
    });
};

export const updateSliderAction = (id, image) => (dispatch) => {
  const formData = new FormData();

  formData.append("image", image);
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: UPDATE_SLIDER_REQUEST,
    // payload: res.data,
  });
  api
    .post(`slider/update/${id}`, formData, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: UPDATE_SLIDER_SUCCESS,
          payload: res.data,
        });
        //   console.log(res)
        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Saved",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_SLIDER_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      console.log(err);
    });
};
