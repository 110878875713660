import Swal from "sweetalert2";

import { api } from "../../Api/api";
import { getToken } from "../login";
import {
  CREATE_CATALOGE_FAIL,
  CREATE_CATALOGE_REQUEST,
  CREATE_CATALOGE_SUCCESS,
  DELETE_CATALOGE_FAIL,
  DELETE_CATALOGE_REQUEST,
  DELETE_CATALOGE_SUCCESS,
  FETCH_CATALOGE_FAIL,
  FETCH_CATALOGE_REQUEST,
  FETCH_CATALOGE_SUCCESS,
  SHOW_CATALOGE_FAIL,
  SHOW_CATALOGE_REQUEST,
  SHOW_CATALOGE_SUCCESS,
  UPDATE_CATALOGE_FAIL,
  UPDATE_CATALOGE_SUCCESS,
} from "../../ActionType/CatalogeType/CatalogeType";

export const createCatalogeAction =
  (title, ar_text, en_text, ku_text, image) => (dispatch) => {
    const formData = new FormData();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: CREATE_CATALOGE_REQUEST,
    });
    formData.append("title", title);
    formData.append("ar_text", ar_text);
    formData.append("en_text", en_text);
    formData.append("ku_text", ku_text);
    formData.append("image", image);

    api
      .post("cataloge/create", formData, config)
      .then((response) => {
        const { status, data } = response;
        if (status == 201) {
          dispatch({
            type: CREATE_CATALOGE_SUCCESS,
            payload: data,
          });
          Swal.fire({
            icon: "success",
            timer: 2000,

            title: "Goode Job",
            text: "Information was Saved Successfuly",
          });
        }
      })
      .catch((erro) => {
        dispatch({
          type: CREATE_CATALOGE_FAIL,
        });
        Swal.fire({
          icon: "error",
          timer: 2000,

          title: "Opps ...",
          text: "Check Your Request",
        });
      });
  };

export const getCatalogeAction = (page) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    params: {
      page,
    },
  };

  dispatch({
    type: FETCH_CATALOGE_REQUEST,
  });

  api
    .get("cataloge", config)
    .then((response) => {
        console.log(response)
      // console.log(response)
      const { status, data } = response;
      if (status == 200) {
        dispatch({
          type: FETCH_CATALOGE_SUCCESS,
          payload: data.data,
          total: data.total,
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: FETCH_CATALOGE_FAIL,
        // payload:data,
      });
      // Swal.fire({
      //   icon: "error",
      //   timer: 2000,

      //   title: "Opps ...",
      //   text: "Check Your Request",
      // });
    });
};

export const deleteCatalogeAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: DELETE_CATALOGE_REQUEST,
  });

  api
    .delete("cataloge/delete/" + id, config)
    .then((response) => {
      const { status, data } = response;
      if (status == 200) {
        dispatch({
          type: DELETE_CATALOGE_SUCCESS,
          payload: data.data,
        });
        Swal.fire({
          icon: "success",
          timer: 2000,

          title: "Goode Job",
          text: "Information was Delete Successfuly",
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: DELETE_CATALOGE_FAIL,
        // payload:data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,

        title: "Opps ...",
        text: "Check Your Request",
      });
    });
};

export const showCatalogeAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: SHOW_CATALOGE_REQUEST,
  });

  api
    .get("cataloge/show/" + id, config)
    .then((response) => {
      console.log(response)
      const { status, data } = response;
      if (status == 200) {
        // console.log(data);
        dispatch({
          type: SHOW_CATALOGE_SUCCESS,
          payload: data,
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: SHOW_CATALOGE_FAIL,
        // payload:data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,

        title: "Opps ...",
        text: "Check Your Request",
      });
    });
};

export const updateCatalogeAction =
  (id, title, ar_text, en_text, ku_text, image) => (dispatch) => {
    const formData = new FormData();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    formData.append("title", title);
    formData.append("ar_text", ar_text);
    formData.append("en_text", en_text);
    formData.append("ku_text", ku_text);
    formData.append("image", image);

    api
      .post(`cataloge/update/${id}`, formData, config)
      .then((response) => {
        // console.log(response);
        const { status, data } = response;
        if (status == 200) {
          // console.log(data);
          dispatch({
            type: UPDATE_CATALOGE_SUCCESS,
            payload: data,
          });

          Swal.fire({
            icon: "success",
            timer: 2000,

            title: "Goode Job",
            text: "Information was Update Successfuly",
          });
        }
      })
      .catch((erro) => {
        dispatch({
          type: UPDATE_CATALOGE_FAIL,
          // payload:data,
        });
        Swal.fire({
          icon: "error",
          timer: 2000,

          title: "Opps ...",
          text: "Check Your Request",
        });
      });
  };
