import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { getGalleryAction } from "../../Action/GalleryAction/GalleryAction";
import Progress from "../Progress/Progress";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { getBgGalleryAction } from "../../Action/GalleryAction/BgGalleryAction";

const Galary = () => {
  const [hiddenBottomImgae, setHiddenImageBottom] = useState(false);
  const dispatch = useDispatch();
  const getLanguge = useSelector((state) => state.getLanguge);
  const { lang } = getLanguge;
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const openImage = (index) => {
    // console.log(index)
    setCurrentImageIndex(index);
  };

  const closeImage = () => {
    setCurrentImageIndex(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowLeft") {
      // handlePrev();
      prevImageModal();
    } else if (e.key === "ArrowRight") {
      // handleNext();
      nextImageModal();
    }
  };

  useEffect(() => {
    if (currentImageIndex == null) {
      return;
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentImageIndex]);
  const nextImageModal = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === gallerys.length - 1 ? 0 : prevIndex + 1
    );
    handleSliderChange(currentImageIndex);
  };

  const prevImageModal = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? gallerys.length - 1 : prevIndex - 1
    );
    handleSliderChange(currentImageIndex);
  };

  const getBgGallery = useSelector((state) => state.getBgGallery);
  const { bgGallery } = getBgGallery;

  const handleSliderChange = (newIndex) => {
    // Calculate the new current page based on the selected image index
    const newPage = Math.floor(newIndex / itemsPerPage());

    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    dispatch(getBgGalleryAction());
    dispatch(getGalleryAction());
  }, []);

  const getGallery = useSelector((state) => state.getGallery);
  const { loading, gallerys } = getGallery;
  const itemsPerPageDesktop = 7;
  const itemsPerPageMobile = 2;
  const [currentPage, setCurrentPage] = useState(0);
  // const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const itemsPerPage = () => {
    return window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop;
  };

  if (!gallerys || gallerys.length === 0) {
    return null;
  }

  const totalItems = gallerys.length;
  const showSlider = totalItems >= itemsPerPageDesktop; // Condition to show slider

  const startIdx = currentPage * itemsPerPage();
  const endIdx = Math.min((currentPage + 1) * itemsPerPage(), gallerys.length);

  const handleNext = () => {
    setCurrentPage(
      (prevPage) => (prevPage + 1) % Math.ceil(totalItems / itemsPerPage())
    );
  };

  const handlePrev = () => {
    setCurrentPage(
      (prevPage) =>
        (prevPage - 1 + Math.ceil(totalItems / itemsPerPage())) %
        Math.ceil(totalItems / itemsPerPage())
    );
  };

  return (
    <>
      {loading || bgGallery == undefined ? (
        <div className="w-full h-full absolute left-0 top-0 bg-black">
          <span className="loader absolute top-[50%] left-[50%] text-white"></span>
        </div>
      ) : (
        <>
          <div
            onClick={() => navigate("/galary")}
            className="    bg-center lg:bg-top lg:bg-cover relative  h-screen brightness-[.7] w-full  page-section"
            id="gallery"
            style={{
              backgroundSize: "cover",
              backgroundImage: `url("${bgGallery.map((bg) => {
                return bg.bg_image;
              })}")`,
            }}
          />
          <div className="relative">
            <motion.div
              initial={{ opacity: 0.1, transition: { duration: 0.5 } }}
              animate={{ x: 0, transition: { duration: 0.5 }, opacity: 1 }}
              className={`text-white px-4 py-2 absolute  w-full -top-[80%] lg:-top-[90%] ml-2 `}
            >
              <div
                className={
                  lang == "en" || lang == "tu"
                    ? " w-full"
                    : lang == "ar"
                    ? "w-full text-right"
                    : "w-full"
                }
              >
                <p className="text-lg lg:text-2xl uppercase ml-2  ">
                  {lang == "en"
                    ? "Look at the"
                    : lang == "tu"
                    ? "şuna bakın"
                    : lang == "ar"
                    ? "انظر الى"
                    : "Look at the"}
                </p>
                <span
                  className={
                    lang == "en" || lang == "tu"
                      ? `text-5xl  lg:text-7xl  `
                      : lang == "ar"
                      ? "text-5xl lg:text-7xl text-right  "
                      : "text-5xl lg:text-7xl   "
                  }
                >
                  {lang == "en"
                    ? "Gallery"
                    : lang == "tu"
                    ? "Galeri"
                    : lang == "ar"
                    ? "صالة عرض"
                    : "Gallery"}
                </span>

                <p
                  className={
                    lang == "en" || lang == "tu"
                      ? `text-base  `
                      : lang == "ar"
                      ? "text-base text-right  "
                      : "text-base   "
                  }
                >
                  {lang == "en"
                    ? "Some examples of photos"
                    : lang == "tu"
                    ? "Bazı fotoğraf örnekleri"
                    : lang == "ar"
                    ? " بعض الأمثلة على الصور"
                    : "Some examples of photos"}
                </p>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 100, transition: { duration: 0.5 } }}
              animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
              className="bg-[#1e1e1e]  text-white py-10 px-2 lg:px-4    w-full "
            >
              <motion.div
                initial={{ opacity: 0.1, y: -10, transition: { duration: 1 } }}
                animate={{ y: 0, transition: { duration: 1 }, opacity: 1 }}
                className="flex   lg:flex gap-x-4 gap-y-3 justify-center  relative"
              >
                <button
                  onClick={handlePrev}
                  className="absolute  left-0 top-1/2 transform -translate-y-1/2 px-4 py-2 text-white bg-gray-800 rounded-l-full cursor-pointer hover:bg-gray-700 focus:outline-none focus:ring focus:border-blue-300"
                >
                  &lt;
                </button>
                <button
                  onClick={handleNext}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 px-4 py-2 text-white bg-gray-800 rounded-r-full cursor-pointer hover:bg-gray-700 focus:outline-none focus:ring focus:border-blue-300"
                >
                  &gt;
                </button>

                {gallerys.slice(startIdx, endIdx).map((item, index) => (
                  <motion.div
                    key={index + startIdx}
                    // initial={{ opacity: 0, y: 20, transition: { duration: 1 } }}
                    // animate={{ opacity: 1, y: 0, transition: { duration: 1 } }}
                    // exit={{ opacity: 0, y: 20, transition: { duration: 1 } }}
                    className=""
                  >
                    <div className="">
                      <div className="">
                        <Link onClick={() => openImage(index + startIdx)}>
                          <motion.img
                            src={item.image}
                            className="w-[120px] h-[150px] object-cover duration-500 hover:scale-105"
                            alt={`Gallery Item ${index + startIdx}`}
                          />
                        </Link>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
          </div>
        </>
      )}

      <AnimatePresence>
        {currentImageIndex !== null && (
          <motion.div
            key="modal"
            className="fixed top-0 z-50  w-full bg-[#100f0f] left-0 "
            // onMouseMove={()=>setHiddenImageBottom(false)}
          >
            <motion.div
              key="modalContent"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="  "
              transition={{ duration: 0.5 }}
            >
              <motion.img
                onMouseMove={() => setHiddenImageBottom(false)}
                onMouseLeave={() => setHiddenImageBottom(true)}
                key={gallerys[currentImageIndex].image}
                src={gallerys[currentImageIndex].image}
                alt={`Gallery Item ${currentImageIndex}`}
                className="w-screen h-screen  z-50 m-auto brightness-90  object-contain "
                transition={{ duration: 0.5 }}
              />
      
                <motion.div
                  // initial={{ opacity: 0.1, y: -10, transition: { duration: 1 } }}
                  // animate={{ y: 0, transition: { duration: 1 }, opacity: 1 }}
                  className={`flex   lg:flex gap-x-4 gap-y-3 justify-center ${hiddenBottomImgae ?"opacity-100 duration-700":"md:opacity-0 duration-700"}  items-center  relative`}
                  
                >
                  <button
                    onClick={handlePrev}
                    className="absolute left-0   bg-[#ab8554] rounded-md shadow-md lg:left-72 bottom-56 lg:bottom-20 transform -translate-y-1/2 px-4 py-2 text-white   cursor-pointer  "
                  >
                    <FaArrowLeft />
                  </button>
                  <button
                    onClick={handleNext}
                    className="absolute right-0   rotate-180 lg:right-72 bg-[#ab8554] rounded-md shadow-md bottom-56 lg:bottom-20 transform -translate-y-1/2 px-4 py-2 text-white   cursor-pointer"
                  >
                    <FaArrowLeft />
                  </button>

                  {gallerys.slice(startIdx, endIdx).map((item, index) => (
                    <motion.div key={index + startIdx} >
                      <div className="">
                        <div className="">
                          <Link onClick={() => openImage(index + startIdx)}>
                            <motion.img
                              src={item.image}
                              className={`relative  bottom-56 h-16 lg:bottom-20 rounded-md border-4 object-cover duration-500 hover:scale-105 ${
                                index + startIdx === currentImageIndex
                                  ? "relative bottom-56 lg:bottom-20 h-16 rounded-md border-4 object-cover duration-500 hover:scale-110 border-[#AA8453]  "
                                  : ""
                              }`}
                              alt={`Gallery Item ${index + startIdx}`}
                              // initial={{ opacity: 0 }}
                              // animate={{
                              //   opacity: 1,
                              //   transition: { duration: 0.5 },
                              // }}
                            />
                          </Link>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </motion.div>
          

              <button
                onClick={(e) => prevImageModal(e)}
                className="absolute top-1/2  bg-[#ab8554] px-3 py-2 left-4 transform rotate-180 animate-pulse text-xl -translate-y-1/2 text-gray-100 hover:text-white duration-300"
              >
                <FaArrowRight />
              </button>
              <button
                onClick={(e) => nextImageModal(e)}
                className="absolute top-1/2 right-4 transform bg-[#ab8554] px-3 py-2 text-xl -translate-y-1/2 animate-pulse text-gray-100 hover:text-white duration-300"
              >
                <FaArrowRight />
              </button>
              <span
                className="close absolute top-20 lg:top-4 right-4 text-white text-3xl lg:text-5xl cursor-pointer"
                onClick={closeImage}
              >
                &times;
              </span>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <style>
        {`
            body {
              overflow: ${currentImageIndex == null ? "auto" : "hidden"};
            }
          `}
      </style>
      {currentImageIndex == null ? <Progress /> : ""}
    </>
  );
};

export default Galary;
