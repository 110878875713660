import Swal from "sweetalert2";
import {
  CREATE_IMAGE_ACTIVITY_FAIL,
  CREATE_IMAGE_ACTIVITY_SUCCESS,
  DELETE_IMAGE_ACTIVITY_FAIL,
  DELETE_IMAGE_ACTIVITY_REQUEST,
  DELETE_IMAGE_ACTIVITY_SUCCESS,
  SHOW_IMAGE_BY_ACTIVITY_ID_FAIL,
  SHOW_IMAGE_BY_ACTIVITY_ID_REQUEST,
  SHOW_IMAGE_BY_ACTIVITY_ID_SUCCESS,
  UPDATE_IMAGE_ACTIVITY_FAIL,
  UPDATE_IMAGE_ACTIVITY_REQUEST,
  UPDATE_IMAGE_ACTIVITY_SUCCESS,
} from "../../ActionType/ActivityType/ActivityType";
import { api } from "../../Api/api";
import { getToken } from "../login";

export const createImageActivityAction = (activityId, image) => (dispatch) => {
  const formData = new FormData();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  formData.append("activity_id", activityId);

  image.map((img) => {
    formData.append("image[]", img);
  });

  api
    .post("imageActivity/create", formData, config)
    .then((response) => {
      const { status, data } = response;
      if (status == 200) {
        dispatch({
          type: CREATE_IMAGE_ACTIVITY_SUCCESS,
          payload: data,
        });
        Swal.fire({
          icon: "success",
          timer: 2000,

          title: "Goode Job",
          text: "Information was Saved Successfuly",
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: CREATE_IMAGE_ACTIVITY_FAIL,
        // payload: data,
      });
      console.log(erro);
      Swal.fire({
        icon: "error",
        timer: 2000,

        title: "Opps ...",
        text: "Check Your Request",
      });
    });
};

export const showImageByActivityIdAction = (activityId) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: SHOW_IMAGE_BY_ACTIVITY_ID_REQUEST,
    // payload: data,
  });
  api
    .get(`imageActivity/showByActivityId/${activityId}`, config)
    .then((response) => {
      const { status, data } = response;
      if (status == 200) {
        dispatch({
          type: SHOW_IMAGE_BY_ACTIVITY_ID_SUCCESS,
          payload: data,
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: SHOW_IMAGE_BY_ACTIVITY_ID_FAIL,
        // payload: data,
      });
    });
};

export const deleteImageActivityAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: DELETE_IMAGE_ACTIVITY_REQUEST,
    // payload: data,
  });
  api
    .delete(`imageActivity/delete/${id}`, config)
    .then((response) => {
      const { status, data } = response;
      if (status == 200) {
        dispatch({
          type: DELETE_IMAGE_ACTIVITY_SUCCESS,
          payload: data,
        });
        Swal.fire({
          icon: "success",
          timer: 1500,
          title: "Good Job",
          text: "Data Was Deleted",
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: DELETE_IMAGE_ACTIVITY_FAIL,
        // payload: data,
      });
    });
};

export const updateImageActivityAction = (id, image) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  const formData = new FormData();
  formData.append("image", image);

//   console.log(id,image)
  api
    .post(`imageActivity/update/${id}`,formData,config)
    .then((response) => {
      const { status, data } = response;
      dispatch(showImageByActivityIdAction(data.id))
      if (status == 200) {
        dispatch({
          type: UPDATE_IMAGE_ACTIVITY_SUCCESS,
          payload: data,
        });
        Swal.fire({
          icon: "success",
          timer: 1500,
          title: "Good Job",
          text: "Data Was updated",
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: UPDATE_IMAGE_ACTIVITY_FAIL,
        // payload: data,
      });
    });
};
