import {
  CREATE_ACTIVITY_FAIL,
  CREATE_ACTIVITY_REQUEST,
  CREATE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAIL,
  DELETE_ACTIVITY_REQUEST,
  DELETE_ACTIVITY_SUCCESS,
  FETCH_ACTIVITY_FAIL,
  FETCH_ACTIVITY_REQUEST,
  FETCH_ACTIVITY_SUCCESS,
  SHOW_ACTIVITY_FAIL,
  SHOW_ACTIVITY_REQUEST,
  SHOW_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAIL,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_SUCCESS,
} from "../ActionType/ActivityType/ActivityType";

export const createActivityReduer = (
  state = { loading: true, activity: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_ACTIVITY_REQUEST:
      return {
        loading: true,
      };
    case CREATE_ACTIVITY_SUCCESS:
      return {
        loading: false,
        activity: action.payload,
        error: "",
      };
    case CREATE_ACTIVITY_FAIL:
      return {
        loading: false,
        activity: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};

export const getActivityReduer = (
  state = { loading: true, activitys: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_ACTIVITY_REQUEST:
      return {
        loading: true,
      };
    case FETCH_ACTIVITY_SUCCESS:
      return {
        loading: false,
        activitys: action.payload,
        total: action.total,
        error: "",
      };
    case FETCH_ACTIVITY_FAIL:
      return {
        loading: false,
        activitys: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};

export const deleteActivitytReduer = (
  state = { loading: true, activity: [], error: "" },
  action
) => {
  switch (action.type) {
    case DELETE_ACTIVITY_REQUEST:
      return {
        loading: true,
      };
    case DELETE_ACTIVITY_SUCCESS:
      return {
        loading: false,
        activity: action.payload,
        error: "",
      };
    case DELETE_ACTIVITY_FAIL:
      return {
        loading: false,
        activity: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};

export const showActivityReduer = (
  state = { loading: true, activity: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_ACTIVITY_REQUEST:
      return {
        loading: true,
      };
    case SHOW_ACTIVITY_SUCCESS:
      return {
        loading: false,
        activity: action.payload,
        error: "",
      };
    case SHOW_ACTIVITY_FAIL:
      return {
        loading: false,
        activity: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};

export const updateActivityReduer = (
  state = { loading: true, activity: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_ACTIVITY_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_ACTIVITY_SUCCESS:
      return {
        loading: false,
        activity: action.payload,
        error: "",
      };
    case UPDATE_ACTIVITY_FAIL:
      return {
        loading: false,
        activity: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};
