import React, { useEffect, useState } from "react";

import { AnimatePresence, motion, useScroll } from "framer-motion";
import Progress from "../Progress/Progress";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { showCatalogeAction } from "../../Action/CatalogeAction/CatalogeAction";
import { showPictureAction } from "../../Action/PictureAction/PictureAction";
import Home from "../Home/Home";
import home from "../../Image/home.png";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
// import Catalog from "./Catalog";

// import { getCatalogeAction } from "../../Action/CatalogeAction/CatalogeAction";
const CatalogeDetails = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const handleKeyDown = (e) => {
    if (e.key === "ArrowLeft") {
      // handlePrev();
      prevImageModal();
    } else if (e.key === "ArrowRight") {
      // handleNext();
      nextImageModal();
    }
  };
  const openImage = (index) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    if (currentImageIndex == null) {
      return;
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentImageIndex]);

  const closeImage = () => {
    setCurrentImageIndex(null);
  };
  const handleSliderChange = (newIndex) => {
    // Calculate the new current page based on the selected image index
    const newPage = Math.floor(newIndex / itemsPerPage());

    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  };

  const nextImageModal = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === picture.length - 1 ? 0 : prevIndex + 1
    );
    handleSliderChange(currentImageIndex + 1);
  };

  const prevImageModal = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? picture.length - 1 : prevIndex - 1
    );
    handleSliderChange(currentImageIndex );
  };

  const { id } = useParams();
  const getLanguge = useSelector((state) => state.getLanguge);
  const { lang } = getLanguge;

  const dispatch = useDispatch();
  const showPicture = useSelector((state) => state.showPicture);
  const { picture, loading } = showPicture;
  useEffect(() => {
    dispatch(showPictureAction(id));
  }, []);
  const itemsPerPageDesktop = 5;
  const itemsPerPageMobile = 1;
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = () => {
    // Adjust the number of items based on the screen size
    return window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop;
  };

  if (!picture || picture.length === 0) {
    return null; // or display a loading indicator, message, etc.
  }

  const startIdx = currentPage * itemsPerPage();
  const endIdx = Math.min((currentPage + 1) * itemsPerPage(), picture.length);

  const handleNext = () => {
    setCurrentPage(
      (prevPage) => (prevPage + 1) % Math.ceil(picture.length / itemsPerPage())
    );
  };

  const handlePrev = () => {
    setCurrentPage(
      (prevPage) =>
        (prevPage - 1 + Math.ceil(picture.length / itemsPerPage())) %
        Math.ceil(picture.length / itemsPerPage())
    );
  };

  return (
    <>
      {loading || picture == undefined ? (
        <div className="w-full h-full absolute left-0 top-0 bg-black">
          <span className="loader absolute top-[50%] left-[50%] text-white"></span>
        </div>
      ) : (
        <motion.div
          className="  bg-cover h-screen w-full relative"
          style={{
            backgroundSize: "cover",
            backgroundImage: `url("${picture[0].picture}")`,
          }}
        >
          <motion.div
            initial={{ opacity: 0.1, transition: { duration: 0.5 } }}
            animate={{ x: 0, transition: { duration: 0.5 }, opacity: 1 }}
            className=" text-white px-4 py-2 absolute w-full top-[30%] ml-2"
          >
            <p
              className={
                lang == "en" || lang == "tu"
                  ? "text-xl uppercase ml-2  tracking-[0.2rem]"
                  : lang == "ar"
                  ? "text-xl uppercase ml-2 text-right  tracking-[0.2rem]"
                  : "text-xl uppercase ml-2  tracking-[0.2rem]"
              }
            >
              {lang == "en"
                ? "Look at the"
                : lang == "tu"
                ? "şuna bakın"
                : lang == "ar"
                ? "انظر الى"
                : "Look at the"}
            </p>

            <p
              className={
                lang == "en" || lang == "tu"
                  ? "text-6xl tracking-wider  h1"
                  : lang == "ar"
                  ? "text-6xl tracking-wider  h1 text-right "
                  : "text-6xl tracking-wider  h1"
              }
            >
              {lang == "en"
                ? "Catalogue"
                : lang == "tu"
                ? "Katalog"
                : lang == "ar"
                ? "فهرس"
                : "Catalogue"}
            </p>

            <p
              className={
                lang == "en" || lang == "tu"
                  ? "text-xl  ml-2  tracking-[0.2rem]"
                  : lang == "ar"
                  ? "text-xl  ml-2 text-right  tracking-[0.2rem]"
                  : "text-xl  ml-2  tracking-[0.2rem]"
              }
            >
              {lang == "en"
                ? "Viewing the catalogue"
                : lang == "tu"
                ? "Kataloğu görüntüleme"
                : lang == "ar"
                ? "عرض الكتالوج"
                : "Viewing the catalogue"}
            </p>
            <p></p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 100, transition: { duration: 0.5 } }}
            animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
            className="bg-[#1e1e1e]  text-white py-10 px-2 lg:px-4 w-full absolute bottom-0"
          >
            <motion.div
              initial={{ opacity: 0.1, y: -10, transition: { duration: 1 } }}
              animate={{ y: 0, transition: { duration: 1 }, opacity: 1 }}
              className="flex   lg:flex gap-x-4 gap-y-3 justify-center overflow-x-auto relative"
            >
              <button
                onClick={handlePrev}
                className="absolute left-0 top-1/2 transform -translate-y-1/2 px-4 py-2 text-white bg-gray-800 rounded-l-full cursor-pointer hover:bg-gray-700 focus:outline-none focus:ring focus:border-blue-300"
              >
                &lt;
              </button>
              <button
                onClick={handleNext}
                className="absolute right-0 top-1/2 transform -translate-y-1/2 px-4 py-2 text-white bg-gray-800 rounded-r-full cursor-pointer hover:bg-gray-700 focus:outline-none focus:ring focus:border-blue-300"
              >
                &gt;
              </button>

              {picture.slice(startIdx, endIdx).map((item, index) => (
                <motion.div
                  key={index + startIdx}
                  initial={{ opacity: 0, y: 20, transition: { duration: 1 } }}
                  animate={{ opacity: 1, y: 0, transition: { duration: 1 } }}
                  exit={{ opacity: 0, y: 20, transition: { duration: 1 } }}
                  className="flip-card"
                >
                  <div className="flip-card">
                    <div className="flip-card">
                      <Link onClick={() => openImage(index + startIdx)}>
                        <motion.img
                          src={item.picture}
                          className="h-40   w-full object-cover"
                          alt={`Gallery Item ${index + startIdx}`}
                    
                        />
                      </Link>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
          <Progress />
        </motion.div>
      )}

      <AnimatePresence>
        {currentImageIndex !== null && (
          <motion.div
            key="modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed top-0 z-50 bg-black w-full  left-0 "
          >
            <motion.div
              key="modalContent"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="bg-black relative z-50"
              transition={{ duration: 2 }}
            >
              <motion.img
                key={picture[currentImageIndex].picture}
                src={picture[currentImageIndex].picture}
                alt={`Gallery Item ${currentImageIndex}`}
                className="h-screen w-full brightness-90 object-contain bg-black"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 2 }}
              />
              <motion.div
                initial={{ opacity: 0.1, y: -10, transition: { duration: 1 } }}
                animate={{ y: 0, transition: { duration: 1 }, opacity: 1 }}
                className="flex   lg:flex gap-x-4 gap-y-3 justify-center  items-center  relative"
              >
                <button
                  onClick={handlePrev}
                  className="absolute left-2 animate-pulse rounded-md shadow-md bg-[#ab8554]  lg:left-96 bottom-56 lg:bottom-24 transform -translate-y-1/2 px-4 py-2 text-white   cursor-pointer  "
                >
                  <FaArrowLeft />
                </button>
                <button
                  onClick={handleNext}
                  className="absolute right-2 rounded-md shadow-md  bg-[#ab8554] animate-pulse rotate-180 lg:right-96 bottom-56 lg:bottom-24 transform -translate-y-1/2 px-4 py-2 text-white   cursor-pointer"
                >
                  <FaArrowLeft />
                </button>

                {picture.slice(startIdx, endIdx).map((item, index) => (
                  <motion.div
                    key={index + startIdx}
                    // initial={{ opacity: 0, y: 20, transition: { duration: 1 } }}
                    // animate={{ opacity: 1, y: 0, transition: { duration: 1 } }}
                    // exit={{ opacity: 0, y: 20, transition: { duration: 1 } }}
                    className=""
                  >
                    <div className="">
                      <div className="">
                        <Link onClick={() => openImage(index + startIdx)}>
                          <motion.img
                            src={item.picture}
                            className={`relative bottom-56 lg:bottom-24 h-20 md:h-16 rounded-md border-4 object-cover duration-500 hover:scale-105 ${
                              index + startIdx === currentImageIndex
                                ? "relative bottom-56 md:h-16 h-20  lg:bottom-24 rounded-md border-4 object-cover duration-500 hover:scale-110 border-[#AA8453]  "
                                : ""
                            }`}
                            alt={`Gallery Item ${index + startIdx}`}
                            // initial={{ opacity: 0 }}
                            // animate={{
                            //   opacity: 1,
                            //   transition: { duration: 0.5 },
                            // }}
                          />
                        </Link>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>

              <button
                onClick={(e) => prevImageModal(e)}
                className="absolute top-1/2  left-4 transform rotate-180 animate-pulse text-xl -translate-y-1/2 text-gray-100 hover:text-white duration-300"
              >
                <FaArrowRight />
              </button>
              <button
                onClick={(e) => nextImageModal(e)}
                className="absolute top-1/2 right-4 transform text-xl -translate-y-1/2 animate-pulse text-gray-100 hover:text-white duration-300"
              >
                <FaArrowRight />
              </button>
              <span
                className="close absolute top-20 lg:top-4 right-4 text-white text-3xl lg:text-5xl cursor-pointer"
                onClick={closeImage}
              >
                &times;
              </span>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default CatalogeDetails;
