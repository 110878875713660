// Navbar.js

import React, { useState } from "react";
import "./css/Navbar.css"; // Import your CSS file for styling
import { RxHamburgerMenu } from "react-icons/rx";
import { IoEarthSharp, IoLogoFacebook } from "react-icons/io5";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../Image/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_LANGUGE_SUCCESS } from "../../ActionType/langugeType";
import {
  FaInstagram,
  FaTelegram,
  FaTelegramPlane,
  FaTiktok,
} from "react-icons/fa";
import { MdWifiCalling3 } from "react-icons/md";
const Navbar = () => {
  const getLanguge = useSelector((state) => state.getLanguge);
  const { lang } = getLanguge;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const [hovered, setHovered] = useState(false);
  const handleHover = () => {
    setHovered(!hovered);
  };

  const dispatch = useDispatch();

  // const [lang, setLang] = useState("en");
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const languages = ["English", "Arabic", "Turkish"];
  const [isOpenLanguge, setIsOpenLanguege] = useState(false);

  

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <nav className={`navbar text-[#e5e7eb]  pb-5 ${isMenuOpen ? "active" : ""}`}>
      <div
        className={
          lang == "en" || lang == "tu"
            ? "navbar-container"
            : lang == "ar"
            ? "navbar-container-arabic"
            : "navbar-container"
        }
      >
        <div className="navbar-toggle" onClick={toggleMenu}>
          <div className="burger-icon">
            <RxHamburgerMenu />
          </div>
        </div>
        <div className="text-[rgba(255,255,255,0.5)]">
          <div className="relative pb inline-block">
            <div
              onClick={() => setIsOpenLanguege(!isOpenLanguge)}
              className={
                lang == "en" || lang == "tu"
                  ? "bg-slate-800 items-center gap-4 w-full m-auto px-4 py-2 rounded-sm shadow-sm flex cursor-pointer"
                  : lang == "ar"
                  ? "bg-slate-800 items-center gap-4 w-full m-auto px-4 py-2 rounded-sm shadow-sm flex flex-row-reverse cursor-pointer"
                  : "bg-slate-800 items-center gap-4 w-full m-auto px-4 py-2 rounded-sm shadow-sm flex cursor-pointer"
              }
            >
              <p>{selectedLanguage}</p>
              <div className="text-[#AA8453]">
                <IoEarthSharp />
              </div>
            </div>

            {/* Dropdown content */}

            <div
              className={`bg-slate-800 cursor-pointer  text-[rgba(255,255,255,0.5)] absolute w-full ${
                isOpenLanguge
                  ? "opacity-100 duration-500 top-10"
                  : "opacity-0 -top-96 duration-500"
              }  flex flex-col gap-y-2 curs text-[rgba(255,255,255,0.5)] `}
            >
              {languages.map((language) => {
                return (
                  <Link
                    onClick={() => {
                      setSelectedLanguage(language);
                      dispatch({
                        type: FETCH_LANGUGE_SUCCESS,
                        payload: language.slice(0, 2).toLowerCase(),
                      });
                      setIsOpenLanguege(false);
                    }}
                    className="hover:bg-slate-900  text-[rgba(255,255,255,0.5)] px-2 py-1 "
                    key={language}
                    // to={`/${language.slice(0,2).toLowerCase()}`}
                  >
                    {language}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        <div className="navbar-logo">
          <div className="flex flex-col">
            <Link to={"/"} onClick={scrollToTop}>
              <img src={Logo} className="w-[6.3rem] m-auto" alt="" />
            </Link>
          </div>
        </div>

        <div className={`navbar-menu text-[#e5e7eb] ${isMenuOpen ? "active" : ""}`}>
          {/* Add your navigation links here */}

          <NavLink
            to={``}
            smooth={true}
            duration={500}
            onClick={() => setIsMenuOpen(false)}
            className={({ isActive }) =>
              isActive
                ? `text-[#AA8453] text-lg mt-4 uppercase  ${
                    lang == "en" || lang == "tu"
                      ? "  pl-2 xl:pl-10"
                      : lang == "ar"
                      ? "text-right xl:pr-10"
                      : "  pl-2 xl:pl-10"
                  }  hover:text-[#AA8453] duration-500`
                : `text-lg mt-4 ${
                    lang == "en" || lang == "tu"
                      ? "  pl-2 xl:pl-10"
                      : lang == "ar"
                      ? "text-right xl:pr-10"
                      : "  pl-2 xl:pl-10"
                  } hover:text-[#AA8453] text-[#e5e7eb]  duration-500 uppercase`
            }
          >
            <div>
              {lang == "en" ? (
                <p>Home</p>
              ) : lang == "tu" ? (
                <p>ANA SAYFA</p>
              ) : lang == "ar" ? (
                <p>الصفحة الرئيسية</p>
              ) : (
                <p>Home</p>
              )}
            </div>
          </NavLink>

          <NavLink
            smooth={true}
            duration={500}
            to={`/about`}
            onClick={() => setIsMenuOpen(false)}
            className={({ isActive }) =>
              isActive
                ? `text-[#AA8453] text-lg mt-4 uppercase  ${
                    lang == "en" || lang == "tu"
                      ? "  pl-2 xl:pl-10"
                      : lang == "ar"
                      ? "text-right xl:pr-10"
                      : "  pl-2 xl:pl-10"
                  }  hover:text-[#AA8453] duration-500`
                : `text-lg mt-4 ${
                    lang == "en" || lang == "tu"
                      ? "  pl-2 xl:pl-10"
                      : lang == "ar"
                      ? "text-right xl:pr-10"
                      : "  pl-2 xl:pl-10"
                  } hover:text-[#AA8453]  duration-500 uppercase`
            }
          >
            <div>
              {lang == "en" ? (
                <p>About</p>
              ) : lang == "tu" ? (
                <p>HAKKINDA</p>
              ) : lang == "ar" ? (
                <p>معلومات عنا</p>
              ) : (
                <p>About</p>
              )}
            </div>
          </NavLink>

          <NavLink
            onClick={() => setIsMenuOpen(false)}
            smooth={true}
            duration={500}
            to={`/galary`}
            className={({ isActive }) =>
              isActive
                ? `text-[#AA8453] text-lg mt-4 uppercase  ${
                    lang == "en" || lang == "tu"
                      ? "  pl-2 xl:pl-10"
                      : lang == "ar"
                      ? "text-right xl:pr-10"
                      : "  pl-2 xl:pl-10"
                  }  hover:text-[#AA8453] duration-500`
                : `text-lg mt-4 ${
                    lang == "en" || lang == "tu"
                      ? "  pl-2 xl:pl-10"
                      : lang == "ar"
                      ? "text-right xl:pr-10"
                      : "  pl-2 xl:pl-10"
                  } hover:text-[#AA8453]  duration-500 uppercase`
            }
          >
            <div>
              {lang == "en" ? (
                <p>Gallery</p>
              ) : lang == "tu" ? (
                <p>GALERI</p>
              ) : lang == "ar" ? (
                <p> معرض الصور</p>
              ) : (
                <p>Gallery</p>
              )}
            </div>
          </NavLink>
          <NavLink
            onClick={() => setIsMenuOpen(false)}
            smooth={true}
            duration={500}
            to={`/cataloge`}
            className={({ isActive }) =>
              isActive
                ? `text-[#AA8453] text-lg mt-4 uppercase  ${
                    lang == "en" || lang == "tu"
                      ? "  pl-2 xl:pl-10"
                      : lang == "ar"
                      ? "text-right xl:pr-10"
                      : "  pl-2 xl:pl-10"
                  }  hover:text-[#AA8453] duration-500`
                : `text-lg mt-4 ${
                    lang == "en" || lang == "tu"
                      ? "  pl-2 xl:pl-10"
                      : lang == "ar"
                      ? "text-right xl:pr-10"
                      : "  pl-2 xl:pl-10"
                  } hover:text-[#AA8453]  duration-500 uppercase`
            }
          >
            <div>
              {lang == "en" ? (
                <p>Catalogue</p>
              ) : lang == "tu" ? (
                <p>KATALOG</p>
              ) : lang == "ar" ? (
                <p>فهرس</p>
              ) : (
                <p>Catalogue</p>
              )}
            </div>
          </NavLink>
          <NavLink
            onClick={() => setIsMenuOpen(false)}
            smooth={true}
            duration={500}
            to={`/product`}
            className={({ isActive }) =>
              isActive
                ? `text-[#AA8453] text-lg mt-4 uppercase  ${
                    lang == "en" || lang == "tu"
                      ? "  pl-2 xl:pl-10"
                      : lang == "ar"
                      ? "text-right xl:pr-10"
                      : "  pl-2 xl:pl-10"
                  }  hover:text-[#AA8453] duration-500`
                : `text-lg mt-4 ${
                    lang == "en" || lang == "tu"
                      ? "  pl-2 xl:pl-10"
                      : lang == "ar"
                      ? "text-right xl:pr-10"
                      : "  pl-2 xl:pl-10"
                  } hover:text-[#AA8453]  duration-500 uppercase`
            }
          >
            <div>
              {lang == "en" ? (
                <p>PRODUCTS</p>
              ) : lang == "tu" ? (
                <p>ÜRÜNLER</p>
              ) : lang == "ar" ? (
                <p>منتجات</p>
              ) : (
                <p>PRODUCTS</p>
              )}
            </div>
          </NavLink>

          <NavLink
            onClick={() => setIsMenuOpen(false)}
            smooth={true}
            duration={500}
            to={`activity`}
            className={({ isActive }) =>
              isActive
                ? `text-[#AA8453] text-lg mt-4 uppercase  ${
                    lang == "en" || lang == "tu"
                      ? "  pl-2 xl:pl-10"
                      : lang == "ar"
                      ? "text-right xl:pr-10"
                      : "  pl-2 xl:pl-10"
                  }  hover:text-[#AA8453] duration-500`
                : `text-lg mt-4 ${
                    lang == "en" || lang == "tu"
                      ? "  pl-2 xl:pl-10"
                      : lang == "ar"
                      ? "text-right xl:pr-10"
                      : "  pl-2 xl:pl-10"
                  } hover:text-[#AA8453]  duration-500 uppercase`
            }
          >
            <div>
              {lang == "en" ? (
                <p>ACTIVITY</p>
              ) : lang == "tu" ? (
                <p>AKTIVITE</p>
              ) : lang == "ar" ? (
                <p>نشاط</p>
              ) : (
                <p>ACTIVITY</p>
              )}
              {/* <p>Activity</p> */}
            </div>
          </NavLink>
          <NavLink
            onClick={() => setIsMenuOpen(false)}
            smooth={true}
            duration={500}
            to={`contact`}
            className={({ isActive }) =>
              isActive
                ? `text-[#AA8453] text-lg mt-4 uppercase  ${
                    lang == "en" || lang == "tu"
                      ? "  pl-2 xl:pl-10"
                      : lang == "ar"
                      ? "text-right xl:pr-10"
                      : "  pl-2 xl:pl-10"
                  }  hover:text-[#AA8453] duration-500`
                : `text-lg mt-4 ${
                    lang == "en" || lang == "tu"
                      ? "  pl-2 xl:pl-10"
                      : lang == "ar"
                      ? "text-right xl:pr-10"
                      : "  pl-2 xl:pl-10"
                  } hover:text-[#AA8453]  duration-500 uppercase`
            }
          >
            <div>
              {lang == "en" ? (
                <p>CONTACT</p>
              ) : lang == "tu" ? (
                <p>BIZE ULAŞIN</p>
              ) : lang == "ar" ? (
                <p>اتصل بنا</p>
              ) : (
                <p>CONTACT</p>
              )}
            </div>
          </NavLink>
          <div className="flex gap-0  mt-4  justify-center">
            <Link
              to={"https://www.facebook.com/omtayalitimcompany?mibextid=zOiqff"}
              target="_blank"
            >
              <p className="icon text-xl">
                <IoLogoFacebook />
              </p>
            </Link>

            <Link
              to={
                "https://www.instagram.com/omtacompany/?igsh=MXBxanlhb2twdDc2Yg%3D%3D"
              }
              target="_blank"
            >
              <p className="icon text-xl">
                <FaInstagram />
              </p>
            </Link>

            <Link
              to={"https://www.tiktok.com/@omta.yalitim?_t=8jZzWkklwOb&_r=1"}
              target="_blank"
            >
              <p className="icon text-xl">
                <FaTiktok />
              </p>
            </Link>

            <Link to={"https://t.me/omtaofficial"} className="icon text-xl text-[rgba(255,255,255,0.5)]" target="_blank">
              <FaTelegramPlane />
            </Link>

            <div className="relative pb-5 left-4 inline-block text-[rgba(255,255,255,0.5)]">
              <p
                className={`icon  text-xl mt-0.5 cursor-pointer transition-transform ${
                  hovered ? "transform-scale-110" : ""
                }`}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
              >
                <MdWifiCalling3 />
              </p>

              <div
                className={
                  hovered
                    ? ` bg-slate-900  p-2 rounded-md tracking-wider shadow-md duration-500 absolute -left-40 -top-12 opacity-100`
                    : "bg-slate-900  p-2 rounded-md shadow-md duration-500 absolute -left-40 -top-12 opacity-0"
                }
              >
                <p>+905337400302</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
            body {
              overflow: ${isMenuOpen ? "hidden" : "auto"};
            }
          `}
      </style>
    </nav>
  );
};

export default Navbar;
