import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaArrowUp } from "react-icons/fa";
import { useSelector } from "react-redux";

const Progress = () => {
  const [progress, setProgress] = useState(0);
  const [scroll, setScroll] = useState(0);
  const [showProgress, setShowProgress] = useState(false); // Initially set to false
  const getLanguage = useSelector((state) => state.getLanguge);
  const { lang } = getLanguage;
  const [isContactPage, setIsContactPage] = useState(false);

  const handleScroll = () => {
    let scrollableHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = window.scrollY;
    setScroll(scrolled);
    const scrollPositionToShowProgress =  scrollableHeight - 50; // Adjust this value as needed
    setShowProgress(scrolled < scrollPositionToShowProgress);
    const scrollProgress = (scrolled / scrollableHeight) * 100;
    setProgress(scrollProgress);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isContactPage]); // re-add event listener if isContactPage changes

  useEffect(() => {
    // Trigger handleScroll once on initial load to set showProgress correctly
    handleScroll();
  }, []); // empty dependency array to run only once on mount

  return (
    <>
      {showProgress && (
        <div>
          <div
            className={
              lang == "en" || lang == "tu"
                ? `fixed right-5 bottom-[2rem]  lg:bottom-[2.7rem]   z-50 cursor-pointer text-[#AA8453] `
                : lang == "ar"
                ? `fixed left-5 bottom-[2rem] lg:bottom-[2.7rem]    z-50 cursor-pointer text-[#AA8453]`
                : `fixed right-5 bottom-[2rem] lg:bottom-[2.7rem]  z-50 cursor-pointer text-[#AA8453] `
            }
            onClick={scrollToTop}
          >
            <FaArrowUp />
          </div>
          <div
            className={
              lang == "en" || lang == "tu"
                ? "fixed right-2 bottom-5 lg:bottom-8 cursor-pointer"
                : lang == "ar"
                ? "fixed left-2 bottom-5 cursor-pointer lg:bottom-8"
                : "fixed right-2 bottom-5 cursor-pointer lg:bottom-8"
            }
            style={{ width: "40px" }}
            onClick={scrollToTop}
          >
            <CircularProgressbar
              value={progress}
              className="z-50"
              styles={buildStyles({
                textColor: "red",
                pathColor: "#AA8453",
              })}
              strokeWidth={5}
              backgroundPadding={1}
              text={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <span style={{ color: "red", marginBottom: "-20px" }}>
                    {progress}%
                  </span>
                  <FaArrowUp style={{ color: "red" }} />
                </div>
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Progress;
