export const CREATE_PDF_REQUEST="CREATE_PDF_REQUEST"
export const CREATE_PDF_SUCCESS="CREATE_PDF_SUCCESS"
export const CREATE_PDF_FAIL="CREATE_PDF_FAIL"


export const FETCH_PDF_REQUEST="FETCH_PDF_REQUEST"
export const FETCH_PDF_SUCCESS="FETCH_PDF_SUCCESS"
export const FETCH_PDF_FAIL="FETCH_PDF_FAIL"


export const DELETE_PDF_REQUEST="DELETE_PDF_REQUEST"
export const DELETE_PDF_SUCCESS="DELETE_PDF_SUCCESS"
export const DELETE_PDF_FAIL="DELETE_PDF_FAIL"

export const SHOW_PDF_REQUEST="SHOW_PDF_REQUEST"
export const SHOW_PDF_SUCCESS="SHOW_PDF_SUCCESS"
export const SHOW_PDF_FAIL="SHOW_PDF_FAIL"