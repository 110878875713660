import Swal from "sweetalert2";

import { api } from "../../Api/api";
import { getToken } from "../login";
import { CREATE_ACTIVITY_SUCCESS, DELETE_ACTIVITY_FAIL, DELETE_ACTIVITY_REQUEST, DELETE_ACTIVITY_SUCCESS, FETCH_ACTIVITY_FAIL, FETCH_ACTIVITY_REQUEST, FETCH_ACTIVITY_SUCCESS, SHOW_ACTIVITY_FAIL, SHOW_ACTIVITY_REQUEST, SHOW_ACTIVITY_SUCCESS } from "../../ActionType/ActivityType/ActivityType";
import { UPDATE_ABOUT_FAIL, UPDATE_ABOUT_SUCCESS } from "../../ActionType/AboutType/about";

export const createActivityAction =
  (title_en,title_tu,title_ar, ar_text, en_text, ku_text, image) => (dispatch) => {
    const formData = new FormData();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    formData.append("title", title_en);
    formData.append("title_tu", title_tu);
    formData.append("title_ar", title_ar);
    formData.append("ar_text", ar_text);
    formData.append("en_text", en_text);
    formData.append("ku_text", ku_text);
    formData.append("image", image);

    api
      .post("activity/create", formData, config)
      .then((response) => {
        const { status, data } = response;
        if (status == 201) {
          dispatch({
            type: CREATE_ACTIVITY_SUCCESS,
            payload: data,
          });
          Swal.fire({
            icon: "success",
            timer: 2000,

            title: "Goode Job",
            text: "Information was Saved Successfuly",
          });
        }
      })
      .catch((erro) => {
        console.log(erro);
        Swal.fire({
          icon: "error",
          timer: 2000,

          title: "Opps ...",
          text: "Check Your Request",
        });
      });
  };

export const getActivityAction = (page) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    params:{
        page
    }
  };

  

  api
    .get("activity", config)
    .then((response) => {
      // console.log(response)
      const { status, data } = response;
      if (status == 200) {
        dispatch({
          type: FETCH_ACTIVITY_SUCCESS,
          payload: data.data,
          total:data.total,
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: FETCH_ACTIVITY_FAIL,
        // payload:data,
      });
      
    });
};

export const deleteActivityAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: DELETE_ACTIVITY_REQUEST,
  });

  api
    .delete("activity/delete/" + id, config)
    .then((response) => {
      const { status, data } = response;
      if (status == 200) {
        
        dispatch({
          type: DELETE_ACTIVITY_SUCCESS,
          payload: data.data,
          
        });
        Swal.fire({
          icon: "success",
          timer: 2000,

          title: "Goode Job",
          text: "Information was Delete Successfuly",
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: DELETE_ACTIVITY_FAIL,
        // payload:data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,

        title: "Opps ...",
        text: "Check Your Request",
      });
    });
};

export const showActivityAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: SHOW_ACTIVITY_REQUEST,
  });

  api
    .get("activity/show/" + id, config)
    .then((response) => {
      // console.log(response)
      const { status, data } = response;
      if (status == 200) {
        console.log(data);
        dispatch({
          type: SHOW_ACTIVITY_SUCCESS,
          payload: data,
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: SHOW_ACTIVITY_FAIL,
        // payload:data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,

        title: "Opps ...",
        text: "Check Your Request",
      });
    });
};

export const updateActivityAction =  (id,title_en,title_ar,title_tu, ar_text, en_text, ku_text, image) => (dispatch) => {
    const formData = new FormData();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    formData.append("title", title_en);
    formData.append("title_ar", title_ar);
    formData.append("title_tu", title_tu);
    formData.append("ar_text", ar_text);
    formData.append("en_text", en_text);
    formData.append("ku_text", ku_text);
    formData.append("image", image);
    

  api
    .post(`activity/update/${id}` ,formData, config)
    .then((response) => {
      
      const { status, data } = response;
      if (status == 200) {
        // console.log(data);
        dispatch({
          type: UPDATE_ABOUT_SUCCESS,
          payload: data,
        });

        Swal.fire({
            icon: "success",
            timer: 2000,

            title: "Goode Job",
            text: "Information was Update Successfuly",
          });
      }
    })
    .catch((erro) => {
      dispatch({
        type: UPDATE_ABOUT_FAIL,
        // payload:data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,

        title: "Opps ...",
        text: "Check Your Request",
      });
    });
};
