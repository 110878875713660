export const CREATE_PICTURE_REQUEST = "CREATE_PICTURE_REQUEST";
export const CREATE_PICTURE_SUCCESS = "CREATE_PICTURE_SUCCESS";
export const CREATE_PICTURE_FAIL = "CREATE_PICTURE_FAIL";

export const FETCH_PICTURE_REQUEST = "FETCH_PICTURE_REQUEST";
export const FETCH_PICTURE_SUCCESS = "FETCH_PICTURE_SUCCESS";
export const FETCH_PICTURE_FAIL = "FETCH_PICTURE_FAIL";

export const DELETE_PICTURE_REQUEST = "DELETE_PICTURE_REQUEST";
export const DELETE_PICTURE_SUCCESS = "DELETE_PICTURE_SUCCESS";
export const DELETE_PICTURE_FAIL = " DELETE_PICTURE_FAIL";

export const SHOW_PICTURE_REQUEST = "SHOW_PICTURE_REQUEST";
export const SHOW_PICTURE_SUCCESS = "SHOW_PICTURE_SUCCESS";
export const SHOW_PICTURE_FAIL = " SHOW_PICTURE_FAIL";

export const UPDATE_PICTURE_REQUEST = "UPDATE_PICTURE_REQUEST";
export const UPDATE_PICTURE_SUCCESS = "UPDATE_PICTURE_SUCCESS";
export const UPDATE_PICTURE_FAIL = " UPDATE_PICTURE_FAIL";
