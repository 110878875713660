
import { CREATE_PICTURE_FAIL, CREATE_PICTURE_REQUEST, CREATE_PICTURE_SUCCESS, DELETE_PICTURE_FAIL, DELETE_PICTURE_REQUEST, DELETE_PICTURE_SUCCESS, FETCH_PICTURE_FAIL, FETCH_PICTURE_REQUEST, FETCH_PICTURE_SUCCESS, SHOW_PICTURE_FAIL, SHOW_PICTURE_REQUEST, SHOW_PICTURE_SUCCESS } from "../ActionType/PictureType";
  
  export const createPictureReduer = (
    state = { loading: true, picture: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case CREATE_PICTURE_REQUEST:
        return {
          loading: true,
        };
      case CREATE_PICTURE_SUCCESS:
        return {
          loading: false,
          picture: action.payload,
          error: "",
        };
      case CREATE_PICTURE_FAIL:
        return {
          loading: false,
          picture: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };
  
  export const getPictureReduer = (
    state = { loading: true, pictures: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case FETCH_PICTURE_REQUEST:
        return {
          loading: true,
        };
      case FETCH_PICTURE_SUCCESS:
        return {
          loading: false,
          pictures: action.payload,
          total: action.total,
          error: "",
        };
      case FETCH_PICTURE_FAIL:
        return {
          loading: false,
          pictures: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };
  
  export const deletePictureReduer = (
    state = { loading: true, picture: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case DELETE_PICTURE_REQUEST:
        return {
          loading: true,
        };
      case DELETE_PICTURE_SUCCESS:
        return {
          loading: false,
          picture: action.payload,
          error: "",
        };
      case DELETE_PICTURE_FAIL:
        return {
          loading: false,
          picture: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };
  

    
  export const showPictureReduer = (
    state = { loading: true, picture: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case SHOW_PICTURE_REQUEST:
        return {
          loading: true,
        };
      case SHOW_PICTURE_SUCCESS:
        return {
          loading: false,
          picture: action.payload,
          error: "",
        };
      case SHOW_PICTURE_FAIL:
        return {
          loading: false,
          picture: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };