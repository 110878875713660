import {
  CREATE_GALLERY_FAIL,
  CREATE_GALLERY_REQUEST,
  CREATE_GALLERY_SUCCESS,
  DELETE_GALLERY_FAIL,
  DELETE_GALLERY_REQUEST,
  DELETE_GALLERY_SUCCESS,
  FETCH_GALLERY_FAIL,
  FETCH_GALLERY_REQUEST,
  FETCH_GALLERY_SUCCESS,
  SHOW_GALLERY_FAIL,
  SHOW_GALLERY_REQUEST,
  SHOW_GALLERY_SUCCESS,
} from "../ActionType/GallaryType/GallaryType";


export const getGalleryReducer = (
    state = { loading: true, gallerys: [],total:0, error: "" },
    action
  ) => {
    switch (action.type) {
      case FETCH_GALLERY_REQUEST:
        return {
          loading: true,
        };
      case FETCH_GALLERY_SUCCESS:
        return {
          loading: false,
          gallerys: action.payload,
          total:action.total,
          error: "",
        };
      case FETCH_GALLERY_FAIL:
        return {
          loading: false,
          gallerys: [],
          error: "",
        };
      default:
        return state;
    }
  };

  
export const createGalleryReducer = (
  state = { loading: true, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_GALLERY_REQUEST:
      return {
        loading: true,
      };
    case CREATE_GALLERY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case CREATE_GALLERY_FAIL:
      return {
        loading: false,
        data: [],
        error: "",
      };
    default:
      return state;
  }
};

export const deleteGalleryReducer = (
  state = { loading: true, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case DELETE_GALLERY_REQUEST:
      return {
        loading: true,
      };
    case DELETE_GALLERY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case DELETE_GALLERY_FAIL:
      return {
        loading: false,
        data: [],
        error: "",
      };
    default:
      return state;
  }
};


export const showGalleryReducer = (
  state = { loading: true, datas: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_GALLERY_REQUEST:
      return {
        loading: true,
      };
    case SHOW_GALLERY_SUCCESS:
      return {
        loading: false,
        datas: action.payload,
        error: "",
      };
    case SHOW_GALLERY_FAIL:
      return {
        loading: false,
        datas: [],
        error: "",
      };
    default:
      return state;
  }
};
