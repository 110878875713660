import React, { useEffect, useState } from "react";
import gt from "../../Image/gt.png";
import Home from "../../Image/home.png";
import { motion } from "framer-motion";
import Progress from "../Progress/Progress";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getActivityAction } from "../../Action/ActivityAction/ActivityAction";
import { getBgActivityAction } from "../../Action/ActivityAction/bgActivityAction";
const Activity = ({ bg }) => {
  const navigate = useNavigate();
  const getLanguge = useSelector((state) => state.getLanguge);
  const { lang } = getLanguge;
  const dispatch = useDispatch();
  const getActivity = useSelector((state) => state.getActivity);
  useEffect(() => {
    dispatch(getBgActivityAction());
  }, []);
  const getBgActivity = useSelector((state) => state.getBgActivity);
  const { bgActivity } = getBgActivity;
  useEffect(() => {
    // console.log(getActivity)
    dispatch(getActivityAction(1, 100));
  }, []);
  const { loading, activitys } = getActivity;
  const itemsPerPageDesktop = 5;
  const itemsPerPageMobile = 1;
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = () => {
    // Adjust the number of items based on the screen size
    return window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop;
  };

  if (activitys == undefined || activitys.length === 0) {
    return null; // or display a loading indicator, message, etc.
  }

  const startIdx = currentPage * itemsPerPage();
  const endIdx = Math.min((currentPage + 1) * itemsPerPage(), activitys.length);

  const handleNext = () => {
    setCurrentPage(
      (prevPage) =>
        (prevPage + 1) % Math.ceil(activitys.length / itemsPerPage())
    );
  };

  const handlePrev = () => {
    setCurrentPage(
      (prevPage) =>
        (prevPage - 1 + Math.ceil(activitys.length / itemsPerPage())) %
        Math.ceil(activitys.length / itemsPerPage())
    );
  };
  return (
    <>
      {loading ||
      activitys == undefined ||
      bgActivity == undefined ||
      bgActivity.length == 0 ? (
        ""
      ) : (
        <>
          <div
            onClick={() => navigate("/activity")}
            className="   bg-black lg:bg-top bg-center lg:bg-cover relative h-screen brightness-[.7] w-full  page-section"
            id="activity"
            style={{
              backgroundSize: "cover",
              backgroundImage: `url("${bgActivity.map((bg) => {
                return bg.bg_image;
              })}")`,
            }}
          />
          <div className="relative">
            <motion.div
              initial={{ opacity: 0.1, transition: { duration: 0.5 } }}
              animate={{ x: 0, transition: { duration: 0.5 }, opacity: 1 }}
              className={
                lang == "en" || lang == "tu"
                  ? " text-white px-4 w-full py-2 absolute -top-[80%] lg:-top-[90%] ml-2"
                  : lang == "ar"
                  ? "text-white px-4 w-full py-2 absolute -top-[80%] lg:-top-[90%] ml-2 text-right"
                  : "text-white px-4 w-full py-2 absolute -top-[80%] lg:-top-[90%] ml-2"
              }
            >
              <p className="text-xl uppercase ml-2  ">
                {lang == "en"
                  ? " Look at the"
                  : lang == "ar"
                  ? "انظر الى"
                  : lang == "tu"
                  ? "Şuna bak"
                  : "Look at the"}
              </p>
              <p className="text-5xl lg:text-7xl   ">
                {lang == "en"
                  ? "Activity"
                  : lang == "tu"
                  ? "Aktivite"
                  : lang == "ar"
                  ? "نشاط"
                  : "Activity"}
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 100, transition: { duration: 0.5 } }}
              animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
              className="bg-[#1e1e1e]    text-white py-10 px-2 lg:px-4 w-full "
            >
              <motion.div
                initial={{ opacity: 0.1, y: -10, transition: { duration: 1 } }}
                animate={{ y: 0, transition: { duration: 1 }, opacity: 1 }}
                className="flex   lg:flex gap-x-4 gap-y-3 justify-center  relative"
              >
                <button
                  onClick={handlePrev}
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 px-4 py-2 text-white bg-gray-800 rounded-l-full cursor-pointer hover:bg-gray-700 focus:outline-none focus:ring focus:border-blue-300"
                >
                  &lt;
                </button>
                <button
                  onClick={handleNext}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 px-4 py-2 text-white bg-gray-800 rounded-r-full cursor-pointer hover:bg-gray-700 focus:outline-none focus:ring focus:border-blue-300"
                >
                  &gt;
                </button>

                {activitys.slice(startIdx, endIdx).map((item, index) => (
                  <motion.div
                    key={index + startIdx}
                    initial={{ opacity: 0, y: 20, transition: { duration: 1 } }}
                    animate={{ opacity: 1, y: 0, transition: { duration: 1 } }}
                    exit={{ opacity: 0, y: 20, transition: { duration: 1 } }}
                    className="flip-card "
                  >
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <motion.img
                          src={item.image}
                          className="h-full w-full object-cover"
                          alt={`Gallery Item ${index + startIdx}`}
                          initial={{ opacity: 0 }}
                          animate={{
                            opacity: 1,
                            transition: { duration: 0.5 },
                          }}
                        />
                      </div>
                      <div className="flip-card-back flex flex-col">
                        <p
                          onClick={() =>
                            navigate("/activity/details/" + item.id)
                          }
                          className="titleAll text-lg hover:underline  cursor-pointer"
                        >
                          {lang === "en"
                            ? "Details"
                            : lang === "tu"
                            ? "Detaylar"
                            : lang == "ar"
                            ? "تفاصيل"
                            : "Details"}
                        </p>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
            <Progress />
          </div>
        </>
      )}
    </>
  );
};

export default Activity;
