// ScrollAnimation.js
import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ScrollAnimation = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    // threshold:4,
  });
  useEffect(()=>{
      controls.start(inView ? 'visible' : 'hidden');
  },[inView])


  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 50 ,transition:{duration:1}},
        visible: { opacity: 1, y: 0 ,transition:{duration:1} },
      }}
      initial="hidden"
      animate={controls}
    >
      {children}
    </motion.div>
  );
};

export default ScrollAnimation;
