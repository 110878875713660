export const CREATE_ACTIVITY_SUCCESS = "CREATE_ACTIVITY_SUCCESS";
export const CREATE_ACTIVITY_REQUEST = "CREATE_ACTIVITY_REQUEST";
export const CREATE_ACTIVITY_FAIL = "CREATE_ACTIVITY_FAIL";

export const FETCH_ACTIVITY_SUCCESS = "FETCH_ACTIVITY_SUCCESS";
export const FETCH_ACTIVITY_REQUEST = "FETCH_ACTIVITY_REQUEST";
export const FETCH_ACTIVITY_FAIL = "FETCH_ACTIVITY_FAIL";

export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_REQUEST = "DELETE_ACTIVITY_REQUEST";
export const DELETE_ACTIVITY_FAIL = "DELETE_ACTIVITY_FAIL";

export const SHOW_ACTIVITY_SUCCESS = "SHOW_ACTIVITY_SUCCESS";
export const SHOW_ACTIVITY_REQUEST = "SHOW_ACTIVITY_REQUEST";
export const SHOW_ACTIVITY_FAIL = "SHOW_ACTIVITY_FAIL";

export const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_REQUEST = "UPDATE_ACTIVITY_REQUEST";
export const UPDATE_ACTIVITY_FAIL = "UPDATE_ACTIVITY_FAIL";


export const CREATE_IMAGE_ACTIVITY_SUCCESS = "CREATE_IMAGE_ACTIVITY_SUCCESS";
export const CREATE_IMAGE_ACTIVITY_REQUEST = "CREATE_IMAGE_ACTIVITY_REQUEST";
export const CREATE_IMAGE_ACTIVITY_FAIL = "CREATE_IMAGE_ACTIVITY_FAIL";

export const FETCH_IMAGE_ACTIVITY_SUCCESS = "FETCH_IMAGE_ACTIVITY_SUCCESS";
export const FETCH_IMAGE_ACTIVITY_REQUEST = "FETCH_IMAGE_ACTIVITY_REQUEST";
export const FETCH_IMAGE_ACTIVITY_FAIL = "FETCH_IMAGE_ACTIVITY_FAIL";

export const DELETE_IMAGE_ACTIVITY_SUCCESS = "DELETE_IMAGE_ACTIVITY_SUCCESS";
export const DELETE_IMAGE_ACTIVITY_REQUEST = "DELETE_IMAGE_ACTIVITY_REQUEST";
export const DELETE_IMAGE_ACTIVITY_FAIL = "DELETE_IMAGE_ACTIVITY_FAIL";

export const SHOW_IMAGE_ACTIVITY_SUCCESS = "SHOW_IMAGE_ACTIVITY_SUCCESS";
export const SHOW_IMAGE_ACTIVITY_REQUEST = "SHOW_IMAGE_ACTIVITY_REQUEST";
export const SHOW_IMAGE_ACTIVITY_FAIL = "SHOW_IMAGE_ACTIVITY_FAIL";

export const SHOW_IMAGE_BY_ACTIVITY_ID_SUCCESS = "SHOW_IMAGE_BY_ACTIVITY_ID_SUCCESS";
export const SHOW_IMAGE_BY_ACTIVITY_ID_REQUEST = "SHOW_IMAGE_BY_ACTIVITY_ID_REQUEST";
export const SHOW_IMAGE_BY_ACTIVITY_ID_FAIL = "SHOW_IMAGE_BY_ACTIVITY_ID_FAIL";

export const UPDATE_IMAGE_ACTIVITY_SUCCESS = "UPDATE_IMAGE_ACTIVITY_SUCCESS";
export const UPDATE_IMAGE_ACTIVITY_REQUEST = "UPDATE_IMAGE_ACTIVITY_REQUEST";
export const UPDATE_IMAGE_ACTIVITY_FAIL = "UPDATE_IMAGE_ACTIVITY_FAIL";