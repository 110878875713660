import React, { useEffect, useState } from "react";
import gt from "../../Image/gt.png";
import Home from "../../Image/home.png";
import { motion } from "framer-motion";
import Progress from "../Progress/Progress";
import { getProductAction } from "../../Action/ProductAction/productAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getBgProductAction } from "../../Action/ProductAction/BgProductAction";
const Product = () => {
  const navigate=useNavigate();
  const dispatch = useDispatch();
  // const { lang } = useParams();
  const getLanguge = useSelector((state) => state.getLanguge);
  const { lang } = getLanguge;
  const getBgProduct=useSelector(state=>state.getBgProduct);
  const {bgProduct}=getBgProduct
  
  useEffect(() => {
    dispatch(getProductAction());
    dispatch(getBgProductAction())
  }, []);
  const getProduct = useSelector((state) => state.getProduct);
  const { loading, Products } = getProduct;
  const itemsPerPageDesktop = 5;
  const itemsPerPageMobile = 1;
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = () => {
    // Adjust the number of items based on the screen size
    return window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop;
  };


  if (!Products || Products.length === 0) {
    return null; // or display a loading indicator, message, etc.
  }

  const startIdx = currentPage * itemsPerPage();
  const endIdx = Math.min((currentPage + 1) * itemsPerPage(), Products.length);

  const handleNext = () => {
    setCurrentPage(
      (prevPage) => (prevPage + 1) % Math.ceil(Products.length / itemsPerPage())
    );
  };

  const handlePrev = () => {
    setCurrentPage(
      (prevPage) =>
        (prevPage - 1 + Math.ceil(Products.length / itemsPerPage())) %
        Math.ceil(Products.length / itemsPerPage())
    );
  };

  

  return (
    <>
      {Products == undefined ||bgProduct==undefined || loading ? (
        ""
      ) : (
        <>
          <div
          onClick={()=>navigate("/product")}
            className=" bg-black  lg:bg-cover lg:bg-top bg-center lg:relative  bg-cover lg:bg-none h-screen brightness-[.7] w-full  page-section"
            id="product"
            style={{
              backgroundSize: "cover",
              backgroundImage: `url("${bgProduct.map((pro)=>{
                return pro.bg_image
              })}")`,
            }}
          />

          <div className="relative">
            <motion.div
              initial={{ opacity: 0.1, transition: { duration: 0.5 } }}
              animate={{ x: 0, transition: { duration: 0.5 }, opacity: 1 }}
              className={` text-white px-4 py-2  absolute -top-[80%] lg:-top-[110%] ${
                lang == "ar" ? "text-right right-0" : "text-left"
              }  `}
            >
              <p
                className={
                  lang == "en" || lang == "tu"
                    ? "text-xl uppercase ml-2  "
                    : lang == "ar"
                    ? "text-xl uppercase ml-2 text-right  "
                    : "text-xl uppercase ml-2  "
                }
              >
                {lang == "en"
                  ? "Look at the"
                  : lang == "tu"
                  ? "Şuna bak"
                  : lang == "ar"
                  ? "انظر الى"
                  : "Look at the"}
              </p>
              <p
                className={
                  lang == "en" || lang == "tu"
                    ? "text-5xl lg:text-7xl   "
                    : lang == "ar"
                    ? "text-5xl lg:text-7xl    text-right  "
                    : "text-5xl lg:text-7xl   "
                }
              >
                {lang == "en"
                  ? "Products"
                  : lang == "tu"
                  ? "Ürünler"
                  : lang == "ar"
                  ? " منتجات"
                  : "Products"}
              </p>

              <p
                className={
                  lang == "en" || lang == "tu"
                    ? "text-lg xl:text-xl  ml-2  "
                    : lang == "ar"
                    ? "text-lg xl:text-xl  ml-2 text-right  "
                    : "text-lg xl:text-xl  ml-2  "
                }
              >
                {lang == "en"
                  ? "The Main Products"
                  : lang == "tu"
                  ? "Ana ürünler"
                  : lang == "ar"
                  ? "المنتجات الرئيسية"
                  : "The Main Products"}
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 100, transition: { duration: 0.5 } }}
              animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
              className="bg-[#1e1e1e]   text-white py-10 px-2 lg:px-4    w-full "
            >
              <motion.div
                initial={{ opacity: 0.1, y: -10, transition: { duration: 1 } }}
                animate={{ y: 0, transition: { duration: 1 }, opacity: 1 }}
                className="flex   lg:flex gap-x-4 gap-y-3 justify-center  relative"
              >
                <button
                  onClick={handlePrev}
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 px-4 py-2 text-white bg-gray-800 rounded-l-full cursor-pointer hover:bg-gray-700 focus:outline-none focus:ring focus:border-blue-300"
                >
                  &lt;
                </button>
                <button
                  onClick={handleNext}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 px-4 py-2 text-white bg-gray-800 rounded-r-full cursor-pointer hover:bg-gray-700 focus:outline-none focus:ring focus:border-blue-300"
                >
                  &gt;
                </button>

                {Products.slice(startIdx, endIdx).map((item, index) => (
                  <motion.div
                    key={index + startIdx}
                    initial={{ opacity: 0, y: 20, transition: { duration: 1 } }}
                    animate={{ opacity: 1, y: 0, transition: { duration: 1 } }}
                    exit={{ opacity: 0, y: 20, transition: { duration: 1 } }}
                    className="flip-card"
                  >
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <motion.img
                          src={item.image}
                          className="h-full w-full object-cover"
                          alt={`Gallery Item ${index + startIdx}`}
                          initial={{ opacity: 0 }}
                          animate={{
                            opacity: 1,
                            transition: { duration: 0.5 },
                          }}
                        />
                      </div>
                      <div className="flip-card-back">
                        <p onClick={()=>navigate("/product/"+item.id)} className=" text-lg  hover:underline cursor-pointer">
                          {lang === "en"
                            ? "Details"
                            : lang === "tu"
                            ? "Detaylar"
                            : "Details"}
                        </p>
                    
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
            <Progress />
          </div>

          {/* <Progress /> */}
        </>
      )}
    </>
  );
};

export default Product;
