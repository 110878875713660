import { api } from "../../Api/api";

import Swal from "sweetalert2";
import { getToken } from "../login";
import {
  CREATE_SLIDER_PRODUCT_FAIL,
  CREATE_SLIDER_PRODUCT_REQUEST,
  CREATE_SLIDER_PRODUCT_SUCCESS,
  DELETE_SLIDER_PRODUCT_FAIL,
  DELETE_SLIDER_PRODUCT_SUCCESS,
  FETCH_SLIDER_PRODUCT_FAIL,
  FETCH_SLIDER_PRODUCT_REQUEST,
  FETCH_SLIDER_PRODUCT_SUCCESS,
  SHOW_SLIDER_PRODUCT_FAIL,
  SHOW_SLIDER_PRODUCT_REQUEST,
  SHOW_SLIDER_PRODUCT_SUCCESS,
  UPDATE_SLIDER_PRODUCT_FAIL,
  UPDATE_SLIDER_PRODUCT_REQUEST,
  UPDATE_SLIDER_PRODUCT_SUCCESS,
} from "../../ActionType/ProductType/ProductSliderType";
import { SHOW_SLIDER_BY_PRODUCT_ID_FAIL, SHOW_SLIDER_BY_PRODUCT_ID_REQUEST, SHOW_SLIDER_BY_PRODUCT_ID_SUCCESS } from "../../ActionType/ProductType/ProductSliderByProductId";

export const getSliderProductAction = () => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: FETCH_SLIDER_PRODUCT_REQUEST,
  });
  api
    .get("productSlider", config)
    .then((res) => {
      dispatch({
        type: FETCH_SLIDER_PRODUCT_SUCCESS,
        payload: res.data.data,
        total: res.data.total,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_SLIDER_PRODUCT_FAIL,
        payload: [],
        error: "",
      });
    });
};

export const createSliderProductAction =
  (product_id, title, title_ar, title_tu, image, en_text, ar_text, tu_text) =>
  (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    const formData = new FormData();
    formData.append("product_id", product_id);
    formData.append("image", image);
    formData.append("en_text", en_text);

    formData.append("title_ar", title_ar);
    formData.append("title_tu", title_tu);
    formData.append("tu_text", tu_text);
    formData.append("ar_text", ar_text);
    formData.append("title", title);

    dispatch({
      type: CREATE_SLIDER_PRODUCT_REQUEST,
    });

    api
      .post("productSlider/create", formData, config)
      .then((res) => {
        if (res.status == 201) {
          console.log(res);
          Swal.fire({
            icon: "success",
            timer: 2000,
            title: "Good Job",
            text: "Information Was Saved",
          });
          dispatch({
            type: CREATE_SLIDER_PRODUCT_SUCCESS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          timer: 2000,
          title: "Error",
          text: "Please Check Your Request",
        });
        dispatch({
          type: CREATE_SLIDER_PRODUCT_FAIL,
        });
      });
  };

export const deleteSliderProductAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  api
    .delete(`productSlider/delete/${id}`, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: DELETE_SLIDER_PRODUCT_SUCCESS,
          payload: res.data,
        });
        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Delete",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: DELETE_SLIDER_PRODUCT_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      console.log(err);
    });
};

export const showSliderProductAction = (id) => (dispatch) => {
  //   console.log(formData)
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: SHOW_SLIDER_PRODUCT_REQUEST,
    // payload: res.data,
  });
  api
    .get(`productSlider/show/${id}`, config)
    .then((res) => {
      // console.log("sfdds : ",res)
      if (res.status == 200) {
        // console.log(res)
        dispatch({
          type: SHOW_SLIDER_PRODUCT_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SHOW_SLIDER_PRODUCT_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      // console.log(err);
    });
};

export const updateSliderProductAction = (id, image) => (dispatch) => {
  const formData = new FormData();

  formData.append("image", image);
  dispatch({
    type: UPDATE_SLIDER_PRODUCT_REQUEST,
  });
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post(`productSlider/update/${id}`, formData, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: UPDATE_SLIDER_PRODUCT_SUCCESS,
          payload: res.data,
        });
        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Saved",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_SLIDER_PRODUCT_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      console.log(err);
    });
};
export const showSliderProductByProductIdAction = (id) =>dispatch=> {
  //   console.log(formData)
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: SHOW_SLIDER_BY_PRODUCT_ID_REQUEST,
    // payload: res.data,
  });
  api
    .get(`productSlider/showByProductId/${id}`, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: SHOW_SLIDER_BY_PRODUCT_ID_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SHOW_SLIDER_BY_PRODUCT_ID_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      // console.log(err);
    });
};
