import { CREATE_HOME_FAIL, CREATE_HOME_REQUEST, CREATE_HOME_SUCCESS, DELETE_HOME_FAIL, DELETE_HOME_REQUEST, DELETE_HOME_SUCCESS, FETCH_HOME_FAIL, FETCH_HOME_REQUEST, FETCH_HOME_SUCCESS, SHOW_HOME_FAIL, SHOW_HOME_REQUEST, SHOW_HOME_SUCCESS, UPDATE_HOME_FAIL, UPDATE_HOME_REQUEST, UPDATE_HOME_SUCCESS } from "../ActionType/HomeType/HomeType";

  export const getHomeReducer = (
    state = { loading: true, Homes: [], total: 0, error: "" },
    action
  ) => {
    switch (action.type) {
      case FETCH_HOME_REQUEST:
        return {
          loading: true,
        };
      case FETCH_HOME_SUCCESS:
        return {
          loading: false,
          Homes: action.payload,
          total: action.total,
          error: "",
        };
      case FETCH_HOME_FAIL:
        return {
          loading: false,
          Homes: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  export const createHomeReducer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case CREATE_HOME_REQUEST:
        return {
          loading: true,
        };
      case CREATE_HOME_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case CREATE_HOME_FAIL:
        return {
          loading: false,
          data: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  export const deleteHomeReducer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case DELETE_HOME_REQUEST:
        return {
          loading: true,
        };
      case DELETE_HOME_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case DELETE_HOME_FAIL:
        return {
          loading: false,
          data: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  export const showHomeReducer = (
    state = { loading: true, datas: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case SHOW_HOME_REQUEST:
        return {
          loading: true,
        };
      case SHOW_HOME_SUCCESS:
        return {
          loading: false,
          datas: action.payload,
          error: "",
        };
      case SHOW_HOME_FAIL:
        return {
          loading: false,
          datas: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  export const updateHomeReducer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case UPDATE_HOME_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_HOME_SUCCESS:
        return {
          loading: false,
          datas: action.payload,
          error: "",
        };
      case UPDATE_HOME_FAIL:
        return {
          loading: false,
          datas: [],
          error: "",
        };
      default:
        return state;
    }
  };
  