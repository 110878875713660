import axios from "axios";
const URL="https://admin.omtayalitim.com/app/api/v1"
export const api = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/v1/",
  baseURL:URL
  
//   headers: {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//   },
});
