import React, { useEffect, useState } from "react";
import "./css/sidebar.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { IoEarthSharp, IoLogoFacebook } from "react-icons/io5";
import { FaInstagram, FaTiktok } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { MdWifiCalling3 } from "react-icons/md";
import Logo from "../../Image/logo.png";
import { useDispatch } from "react-redux";
import { FETCH_LANGUGE_SUCCESS } from "../../ActionType/langugeType";
const Sidebar = ({ activeSection,clearActivation }) => {
  const [lang, setLang] = useState("en");
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const languages = ["English", "Arabic", "Turkish"];
  const [isOpenLanguge, setIsOpenLanguege] = useState(false);
  // const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    setHovered(!hovered);
  };

  const dispatch = useDispatch();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="parentSide">
      <nav className="flex flex-col">
        <Link to={"/"} onClick={scrollToTop} className="cursor-pointer">
          <div className="logo">
            <img src={Logo} className=" lg:w-24 xl:w-52 m-auto" alt="" />
            
          </div>
        </Link>

        <NavLink
        // onClick={()=}
          to={``}
          smooth={true}
          duration={500}
          className={({ isActive }) =>
          isActive && activeSection == "home"
              ? `text-[#AA8453] text-lg mt-4 uppercase ${
                  lang == "en" || lang == "tu"
                    ? "  pl-2 xl:pl-10"
                    : "text-right xl:pr-10"
                }  hover:text-[#AA8453] duration-500`
              : `text-lg mt-4 ${
                  lang == "en" || lang == "tu"
                    ? "  pl-2 xl:pl-10"
                    : "text-right xl:pr-10"
                } hover:text-[#AA8453]  duration-500 uppercase`
          }
        >
          <div>
            {lang == "en" ? (
              <p>Home</p>
            ) : lang == "tu" ? (
              <p>ANA SAYFA</p>
            ) : lang == "ar" ? (
              <p>الصفحة الرئيسية</p>
            ) : (
              ""
            )}
          </div>
        </NavLink>

        <NavLink
        onClick={()=>clearActivation("")}
          smooth={true}
          duration={500}
          to={`/about`}
          className={({ isActive }) =>
          isActive || activeSection == "about"
            ? `text-[#AA8453] uppercase text-lg mt-4  ${
                lang == "en" || lang == "tu"
                  ? "  pl-2 xl:pl-10"
                  : "text-right xl:pr-10"
              }  hover:text-[#AA8453] duration-500`
            : `text-lg mt-4 ${
                lang == "en" || lang == "tu"
                  ? "  pl-2 xl:pl-10"
                  : "text-right xl:pr-10"
              } hover:text-[#AA8453]  duration-500 uppercase`
        }
        >
          <div>
            {lang == "en" ? (
              <p>About</p>
            ) : lang == "tu" ? (
              <p>HAKKINDA</p>
            ) : lang == "ar" ? (
              <p>معلومات عنا</p>
            ) : (
              ""
            )}
          </div>
        </NavLink>

        <NavLink
           onClick={()=>clearActivation("")}
          smooth={true}
          duration={500}
          to={`/galary`}
          className={({ isActive }) =>
            isActive || activeSection == "gallery"
              ? `text-[#AA8453] uppercase text-lg mt-4  ${
                  lang == "en" || lang == "tu"
                    ? "  pl-2 xl:pl-10"
                    : "text-right xl:pr-10"
                }  hover:text-[#AA8453] duration-500`
              : `text-lg mt-4 ${
                  lang == "en" || lang == "tu"
                    ? "  pl-2 xl:pl-10"
                    : "text-right xl:pr-10"
                } hover:text-[#AA8453]  duration-500 uppercase`
          }
        >
          <div>
            {lang == "en" ? (
              <p>Gallery</p>
            ) : lang == "tu" ? (
              <p>GALERI</p>
            ) : lang == "ar" ? (
              <p> معرض الصور</p>
            ) : (
              ""
            )}
          </div>
        </NavLink>
        <NavLink
           onClick={()=>clearActivation("")}
          smooth={true}
          duration={500}
          to={`/cataloge`}
          className={({ isActive }) =>
            isActive || activeSection == "cataloge"
              ? `text-[#AA8453] text-lg mt-4 uppercase  ${
                  lang == "en" || lang == "tu"
                    ? "  pl-2 xl:pl-10"
                    : "text-right xl:pr-10"
                }  hover:text-[#AA8453] duration-500`
              : `text-lg mt-4 ${
                  lang == "en" || lang == "tu"
                    ? "  pl-2 xl:pl-10"
                    : "text-right xl:pr-10"
                } hover:text-[#AA8453]  duration-500 uppercase`
          }
        >
          <div>
            {lang == "en" ? (
              <p>Catalogue</p>
            ) : lang == "tu" ? (
              <p>KATALOG</p>
            ) : lang == "ar" ? (
              <p>فهرس</p>
            ) : (
              ""
            )}
          </div>
        </NavLink>
        <NavLink
           onClick={()=>clearActivation("")}
          smooth={true}
          duration={500}
          to={`/product`}
          className={({ isActive }) =>
            isActive || activeSection == "product"
              ? `text-[#AA8453] text-lg mt-4  ${
                  lang == "en" || lang == "tu"
                    ? "  pl-2 xl:pl-10"
                    : "text-right xl:pr-10"
                }  hover:text-[#AA8453] duration-500`
              : `text-lg mt-4 ${
                  lang == "en" || lang == "tu"
                    ? "  pl-2 xl:pl-10"
                    : "text-right xl:pr-10"
                } hover:text-[#AA8453]  duration-500 uppercase`
          }
        >
          <div>
            {lang == "en" ? (
              <p>PRODUCTS</p>
            ) : lang == "tu" ? (
              <p>ÜRÜNLER</p>
            ) : lang == "ar" ? (
              <p>منتجات</p>
            ) : (
              ""
            )}
          </div>
        </NavLink>

        <NavLink
           onClick={()=>clearActivation("")}
          smooth={true}
          duration={500}
          to={`activity`}
          className={({ isActive }) =>
            isActive || activeSection == "activity"
              ? `text-[#AA8453] text-lg mt-4  ${
                  lang == "en" || lang == "tu"
                    ? "  pl-2 xl:pl-10"
                    : "text-right xl:pr-10"
                }  hover:text-[#AA8453] duration-500`
              : `text-lg mt-4 ${
                  lang == "en" || lang == "tu"
                    ? "  pl-2 xl:pl-10"
                    : "text-right xl:pr-10"
                } hover:text-[#AA8453]  duration-500 uppercase`
          }
        >
          <div>
            {lang == "en" ? (
              <p>ACTIVITY</p>
            ) : lang == "tu" ? (
              <p>AKTIVITE</p>
            ) : lang == "ar" ? (
              <p>نشاط</p>
            ) : (
              ""
            )}
            {/* <p>Activity</p> */}
          </div>
        </NavLink>
        <NavLink
           onClick={()=>clearActivation("")}
          smooth={true}
          duration={500}
          to={`contact`}
          className={({ isActive }) =>
            isActive || activeSection == "contact"
              ? `text-[#AA8453] text-lg mt-4  ${
                  lang == "en" || lang == "tu"
                    ? "  pl-2 xl:pl-10"
                    : "text-right xl:pr-10"
                }  hover:text-[#AA8453] duration-500`
              : `text-lg mt-4 ${
                  lang == "en" || lang == "tu"
                    ? "  pl-2 xl:pl-10"
                    : "text-right xl:pr-10"
                } hover:text-[#AA8453]  duration-500 uppercase`
          }
        >
          <div>
            {lang == "en" ? (
              <p>CONTACT</p>
            ) : lang == "tu" ? (
              <p>BIZE ULAŞIN</p>
            ) : lang == "ar" ? (
              <p>اتصل بنا</p>
            ) : (
              ""
            )}
          </div>
        </NavLink>
      </nav>
      <div className="flex flex-col  my-auto pl-10  gap-y-2">
        <div className="relative inline-block">
          <div
            onClick={() => setIsOpenLanguege(!isOpenLanguge)}
            className="bg-[#1e1e1e] w-7/12  items-center gap-4   px-4 py-2 rounded-sm shadow-sm flex cursor-pointer"
          >
            <p>{selectedLanguage}</p>
            <div className="text-[#AA8453]">
              <IoEarthSharp />
            </div>
          </div>

          {/* Dropdown content */}

          <div
            className={`bg-[#1a1a1a] z-50 w-7/12 cursor-pointer  absolute  ${
              isOpenLanguge
                ? "opacity-100 duration-500 left-0 top-10"
                : "opacity-0 -left-96 duration-500"
            }  flex flex-col gap-y-2 curs `}
          >
            {languages.map((language) => {
              return (
                <Link
                  onClick={() => {
                    setSelectedLanguage(language);
                    setLang(language.slice(0, 2).toLowerCase());
                    dispatch({
                      type: FETCH_LANGUGE_SUCCESS,
                      payload: language.slice(0, 2).toLowerCase(),
                    });
                    setIsOpenLanguege(false);
                  }}
                  className="hover:bg-[#1e1e1e] px-2 py-1 "
                  key={language}
                  // to={`/${language.slice(0, 2).toLowerCase()}`}
                >
                  {language}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="flex gap-2 ml-1  mt-2">
          <Link
            to={"https://www.facebook.com/omtayalitimcompany?mibextid=zOiqff"}
            target="_blank"
          >
            <p className="icon">
              <IoLogoFacebook />
            </p>
          </Link>

          <Link
            to={
              "https://www.instagram.com/omtacompany/?igsh=MXBxanlhb2twdDc2Yg%3D%3D"
            }
            target="_blank"
          >
            <p className="icon">
              <FaInstagram />
            </p>
          </Link>

          <p className="icon">
            <Link to={"https://t.me/omtaofficial"} target="_blank">
              <FaTelegramPlane />
            </Link>
          </p>

          <Link
            to={"https://www.tiktok.com/@omta.yalitim?_t=8jZzWkklwOb&_r=1"}
            target="_blank"
          >
            <p className="icon">
              <FaTiktok />
            </p>
          </Link>
          <div className="relative inline-block">
            <p
              className={`icon cursor-pointer transition-transform ${
                hovered ? "transform-scale-110" : ""
              }`}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
            >
              <MdWifiCalling3 />
            </p>

            <div
              className={
                hovered
                  ? `absolute bg-slate-950 xl:-left-[6.5rem] rounded-md shadow-lg -top-28 duration-500  px-2 py-2  `
                  : "absolute -top-14  -z-50 transform   opacity-0 -left-[6.5rem] bg-[#1e1e1e] text-white py-2 px-1 shadow-md rounded    duration-500 "
              }
            >
              <p>+905337400302</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
