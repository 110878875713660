import Swal from "sweetalert2";

import { api } from "../../Api/api";
import { getToken } from "../login";
import {
  CREATE_CATALOGE_FAIL,
  CREATE_CATALOGE_REQUEST,
  CREATE_CATALOGE_SUCCESS,
  DELETE_CATALOGE_FAIL,
  DELETE_CATALOGE_REQUEST,
  DELETE_CATALOGE_SUCCESS,
  FETCH_CATALOGE_FAIL,
  FETCH_CATALOGE_REQUEST,
  FETCH_CATALOGE_SUCCESS,
  SHOW_CATALOGE_FAIL,
  SHOW_CATALOGE_REQUEST,
  SHOW_CATALOGE_SUCCESS,
  UPDATE_CATALOGE_FAIL,
  UPDATE_CATALOGE_SUCCESS,
} from "../../ActionType/CatalogeType/CatalogeType";
import { CREATE_PICTURE_FAIL, CREATE_PICTURE_REQUEST, CREATE_PICTURE_SUCCESS, DELETE_PICTURE_FAIL, DELETE_PICTURE_REQUEST, DELETE_PICTURE_SUCCESS, FETCH_PICTURE_FAIL, FETCH_PICTURE_REQUEST, FETCH_PICTURE_SUCCESS, SHOW_PICTURE_FAIL, SHOW_PICTURE_REQUEST, SHOW_PICTURE_SUCCESS } from "../../ActionType/PictureType";

export const createPictureAction = (picture, cataloge_id) => (dispatch) => {
  const formData = new FormData();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: CREATE_PICTURE_REQUEST,
  });
  formData.append("cataloge_id", cataloge_id);
  picture.map((pic) => {
    formData.append("picture[]", pic);
  });
 
  console.log(
    picture, cataloge_id
  )
  api
    .post("picture/create", formData, config)
    .then((response) => {
      const { status, data } = response;
      console.log(response);
      if (status == 200) {
        dispatch({
          type: CREATE_PICTURE_SUCCESS,
          payload: data,
        });
        Swal.fire({
          icon: "success",
          timer: 2000,

          title: "Goode Job",
          text: "Information was Saved Successfuly",
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: CREATE_PICTURE_FAIL,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,

        title: "Opps ...",
        text: "Check Your Request",
      });
    });
};

export const getPictureAction = (page) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    params: {
      page,
    },
  };

  dispatch({
    type: FETCH_PICTURE_REQUEST,
  });

  api
    .get("picture", config)
    .then((response) => {
      
      const { status, data } = response;
      if (status == 200) {
        dispatch({
          type: FETCH_PICTURE_SUCCESS,
          payload: data.data,
          total: data.total,
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: FETCH_PICTURE_FAIL,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Opps ...",
        text: "Check Your Request",
      });
    });
};

export const deletePictureAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: DELETE_PICTURE_REQUEST,
  });

  api
    .delete("picture/delete/" + id, config)
    .then((response) => {
      const { status, data } = response;
      if (status == 200) {
        dispatch({
          type: DELETE_PICTURE_SUCCESS,
          payload: data.data,
        });
        Swal.fire({
          icon: "success",
          timer: 2000,

          title: "Goode Job",
          text: "Information was Delete Successfuly",
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: DELETE_PICTURE_FAIL,
        // payload:data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,

        title: "Opps ...",
        text: "Check Your Request",
      });
    });
};

export const showPictureAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: SHOW_PICTURE_REQUEST,
  });

  api
    .get("picture/show/" + id, config)
    .then((response) => {
      // console.log(response.data);
      const { status, data } = response;
      if (status == 200) {
        // console.log(data);
        dispatch({
          type: SHOW_PICTURE_SUCCESS,
          payload: data,
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: SHOW_PICTURE_FAIL,
        // payload:data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,

        title: "Opps ...",
        text: "Check Your Request",
      });
    });
};

export const updateCatalogeAction =
  (id, title, ar_text, en_text, ku_text, image) => (dispatch) => {
    const formData = new FormData();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    formData.append("title", title);
    formData.append("ar_text", ar_text);
    formData.append("en_text", en_text);
    formData.append("ku_text", ku_text);
    formData.append("image", image);

    api
      .post(`cataloge/update/${id}`, formData, config)
      .then((response) => {
        // console.log(response);
        const { status, data } = response;
        if (status == 200) {
          // console.log(data);
          dispatch({
            type: UPDATE_CATALOGE_SUCCESS,
            payload: data,
          });

          Swal.fire({
            icon: "success",
            timer: 2000,

            title: "Goode Job",
            text: "Information was Update Successfuly",
          });
        }
      })
      .catch((erro) => {
        dispatch({
          type: UPDATE_CATALOGE_FAIL,
          // payload:data,
        });
        Swal.fire({
          icon: "error",
          timer: 2000,

          title: "Opps ...",
          text: "Check Your Request",
        });
      });
  };
