export const CREATE_BACKGROUND_CONTACT_REQUEST =
  "CREATE_BACKGROUND_CONTACT_REQUEST";
export const CREATE_BACKGROUND_CONTACT_SUCCESS =
  "CREATE_BACKGROUND_CONTACT_SUCCESS";
export const CREATE_BACKGROUND_CONTACT_FAIL = "CREATE_BACKGROUND_CONTACT_FAIL";

export const FETCH_BACKGROUND_CONTACT_REQUEST =
  "FETCH_BACKGROUND_CONTACT_REQUEST";
export const FETCH_BACKGROUND_CONTACT_SUCCESS =
  "FETCH_BACKGROUND_CONTACT_SUCCESS";
export const FETCH_BACKGROUND_CONTACT_FAIL = "FETCH_BACKGROUND_CONTACT_FAIL";

export const DELETE_BACKGROUND_CONTACT_REQUEST =
  "  DELETE_BACKGROUND_CONTACT_REQUEST";
export const DELETE_BACKGROUND_CONTACT_SUCCESS =
  "  DELETE_BACKGROUND_CONTACT_SUCCESS";
export const DELETE_BACKGROUND_CONTACT_FAIL = " DELETE_BACKGROUND_CONTACT_FAIL";

export const SHOW_BACKGROUND_CONTACT_REQUEST =
  "  SHOW_BACKGROUND_CONTACT_REQUEST";
export const SHOW_BACKGROUND_CONTACT_SUCCESS =
  "  SHOW_BACKGROUND_CONTACT_SUCCESS";
export const SHOW_BACKGROUND_CONTACT_FAIL = " SHOW_BACKGROUND_CONTACT_FAIL";

export const UPDATE_BACKGROUND_CONTACT_REQUEST =
  "  UPDATE_BACKGROUND_CONTACT_REQUEST";
export const UPDATE_BACKGROUND_CONTACT_SUCCESS =
  "  UPDATE_BACKGROUND_CONTACT_SUCCESS";
export const UPDATE_BACKGROUND_CONTACT_FAIL = " UPDATE_BACKGROUND_CONTACT_FAIL";
