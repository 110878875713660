
import { api } from "../../Api/api";
  
import Swal from "sweetalert2";
import { getToken } from "../login";
import { CREATE_BACKGROUND_CONTACT_FAIL, CREATE_BACKGROUND_CONTACT_REQUEST, CREATE_BACKGROUND_CONTACT_SUCCESS, DELETE_BACKGROUND_CONTACT_FAIL, DELETE_BACKGROUND_CONTACT_SUCCESS, FETCH_BACKGROUND_CONTACT_FAIL, FETCH_BACKGROUND_CONTACT_REQUEST, FETCH_BACKGROUND_CONTACT_SUCCESS, SHOW_BACKGROUND_CONTACT_FAIL, SHOW_BACKGROUND_CONTACT_REQUEST, SHOW_BACKGROUND_CONTACT_SUCCESS, UPDATE_BACKGROUND_CONTACT_FAIL, UPDATE_BACKGROUND_CONTACT_REQUEST, UPDATE_BACKGROUND_CONTACT_SUCCESS } from "../../ActionType/backgroundContactType/BackgroundContactType";

export const getBackgroundContactAction = () => (dispatch) => {

  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    }, 
  };

  dispatch({
    type: FETCH_BACKGROUND_CONTACT_REQUEST,
  });
  api
    .get("bgContact", config)
    .then((res) => {
      dispatch({
        type: FETCH_BACKGROUND_CONTACT_SUCCESS,
        payload: res.data,
        // total:res.data.total,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_BACKGROUND_CONTACT_FAIL,
        payload: [],
        error: "",
      });
    });
};

export const createBackgroundContactAction = ( image) => dispatch=> {

  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    
  };
  const formData = new FormData();

  
  formData.append("image", image);


  dispatch({
    type:CREATE_BACKGROUND_CONTACT_REQUEST,
    
  });

  api
    .post("bgContact/create", formData, config)
    .then((res) => {
      if (res.status == 201) {
        
        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Saved",
        });
        dispatch({
          type:CREATE_BACKGROUND_CONTACT_SUCCESS,
          payload:res.data
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      dispatch({
        type:CREATE_BACKGROUND_CONTACT_FAIL,
      });
    });
};

export const deleteBackgroundContactAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    
  };

  api
    .delete(`bgContact/delete/${id}`, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: DELETE_BACKGROUND_CONTACT_SUCCESS,
          payload: res.data,
        });
        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Delete",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: DELETE_BACKGROUND_CONTACT_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      console.log(err);
    });
};

export const showBackgroundContactAction = (id) => (dispatch) => {
  //   console.log(formData)
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    
  };
  dispatch({
    type: SHOW_BACKGROUND_CONTACT_REQUEST,

  });
  api
    .get(`bgContact/show/${id}`, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: SHOW_BACKGROUND_CONTACT_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SHOW_BACKGROUND_CONTACT_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      // console.log(err);
    });
};

export const updateBackgroundContactAction = (id, image) => (dispatch) => {
  const formData = new FormData();

  formData.append("image", image);
  dispatch({
    type:UPDATE_BACKGROUND_CONTACT_REQUEST  
  })
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    
  };
  api
    .post(`bgContact/update/${id}`,formData, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type:UPDATE_BACKGROUND_CONTACT_SUCCESS,
          payload:res.data
        })
        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Saved",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_BACKGROUND_CONTACT_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });

    });
};
