import React, { useEffect, useState } from "react";
import gt from "../../Image/gt.png";
import Home from "../../Image/home.png";
import { motion } from "framer-motion";
import Progress from "../Progress/Progress";
import { getProductAction } from "../../Action/ProductAction/productAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  showSliderProductAction,
  showSliderProductByProductIdAction,
} from "../../Action/ProductAction/ProductSliderAction";
const ProductSlideDetalis = () => {
  const { id } = useParams();
  //   const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { lang } = useParams();
  const getLanguge = useSelector((state) => state.getLanguge);
  const { lang } = getLanguge;

  useEffect(() => {
    dispatch(showSliderProductAction(id));
  }, []);
  
  const showSliderProduct = useSelector((state) => state.showSliderProduct);
  const { loading, sliderProduct } = showSliderProduct;
  
  // const sen=sliderProduct.split(".").filter(Boolean)
  return (
    <>
      {sliderProduct == undefined || loading ? (
        ""
      ) : (
        <>
          <motion.div
            initial={{ y: -100, transition: { duration: 1 } }}
            animate={{ y: 0, transition: { duration: 1 } }}
            className="   m-auto mt-2 lg:mt-0 object-cover  h-auto   brightness-[.7]"
            id="product"
          >
            <img
              src={sliderProduct.image}
              className="   mt-16 lg:mt-0 bg-black lg:bg-top bg-center lg:bg-cover relative lg:h-screen brightness-[.7] lg:w-full"
              alt=""
            />
          </motion.div>

          <motion.div
            initial={{ x: -90, transition: { duration: 1 } }}
            animate={{ x: 0, transition: { duration: 1 } }}
            className=" mt-9 text-white w-11/12 m-auto py-2    lg:px-32 hover:cursor-pointer   pb-20"
          >
            <div>
              <p
                className={
                  lang == "ar"
                    ? "font-bold text-2xl lg:text-4xl text-right"
                    : "font-bold text-2xl lg:text-4xl"
                }
              >
                {lang == "ar" ? (
                  <p>{sliderProduct.title_ar}</p>
                ) : lang == "en" ? (
                  <p>{sliderProduct.title}</p>
                ) : lang == "tu" ? (
                  <p>{sliderProduct.title_tu}</p>
                ) : (
                  <p>{sliderProduct.title}</p>
                )}
              </p>
            </div>
            {lang == "en" && (
              <p className="text-base lg:text-xl mt-2">
                {sliderProduct.en_text.split(".").map((sentence, index, array) => (
                  <React.Fragment key={index}>
                    {sentence.trim()}
                    {index !== array.length - 1 && (
                      <span>
                        . <br /> <br />
                      </span>
                    )}{" "}
                    {/* Add a line break after each sentence except for the last one */}
                  </React.Fragment>
                ))}
              </p>
            )}
            {lang == "ar" && (
              <p className="text-base text-[rgba(255,255,255,0.5)] lg:text-xl mt-2">
                {sliderProduct.ar_text.split(".").map((sentence, index, array) => (
                  <React.Fragment key={index}>
                    {sentence.trim()}
                    {index !== array.length - 1 && (
                      <span>
                        . <br /> <br />
                      </span>
                    )}{" "}
                    {/* Add a line break after each sentence except for the last one */}
                  </React.Fragment>
                ))}
              </p>
            )}
            {lang == "tu" && (
              <p className="text-base text-[rgba(255,255,255,0.5)] lg:text-xl mt-2">
                {sliderProduct.tu_text.split(".").map((sentence, index, array) => (
                  <React.Fragment key={index}>
                    {sentence.trim()}
                    {index !== array.length - 1 && (
                      <span>
                        <br /> <br />
                      </span>
                    )}{" "}
                    {/* Add a line break after each sentence except for the last one */}
                  </React.Fragment>
                ))}
              </p>
            )}
            {lang == "" && (
              <p className="text-base text-[rgba(255,255,255,0.5)] lg:text-xl mt-2">
                {sliderProduct.en_text.split(".").map((sentence, index, array) => (
                  <React.Fragment key={index}>
                    {sentence.trim()}
                    {index !== array.length - 1 && (
                      <span>
                        . <br /> <br />
                      </span>
                    )}{" "}
                    {/* Add a line break after each sentence except for the last one */}
                  </React.Fragment>
                ))}
              </p>
            )}
          </motion.div>

          <Progress />
        </>
      )}
    </>
  );
};

export default ProductSlideDetalis;
