import { CREATE_SLIDER_BY_PRODUCT_ID_FAIL, CREATE_SLIDER_BY_PRODUCT_ID_REQUEST, CREATE_SLIDER_BY_PRODUCT_ID_SUCCESS, DELETE_SLIDER_BY_PRODUCT_ID_FAIL, DELETE_SLIDER_BY_PRODUCT_ID_REQUEST, DELETE_SLIDER_BY_PRODUCT_ID_SUCCESS, FETCH_SLIDER_BY_PRODUCT_ID_FAIL, FETCH_SLIDER_BY_PRODUCT_ID_SUCCESS, SHOW_SLIDER_BY_PRODUCT_ID_FAIL, SHOW_SLIDER_BY_PRODUCT_ID_REQUEST, SHOW_SLIDER_BY_PRODUCT_ID_SUCCESS, UPDATE_SLIDER_BY_PRODUCT_ID_FAIL, UPDATE_SLIDER_BY_PRODUCT_ID_REQUEST, UPDATE_SLIDER_BY_PRODUCT_ID_SUCCESS } from "../ActionType/ProductType/ProductSliderByProductId";
import { SHOW_SLIDER_PRODUCT_FAIL, SHOW_SLIDER_PRODUCT_REQUEST, SHOW_SLIDER_PRODUCT_SUCCESS } from "../ActionType/ProductType/ProductSliderType";


export const getSliderByProductIdReduer = (
  state = { loading: true, productSliders: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_SLIDER_BY_PRODUCT_ID_FAIL:
      return {
        loading: true,
      };
    case FETCH_SLIDER_BY_PRODUCT_ID_SUCCESS:
      return {
        loading: false,
        productSliders: action.payload,
        total: action.total,
        error: "",
      };
    case FETCH_SLIDER_BY_PRODUCT_ID_FAIL:
      return {
        loading: false,
        productSliders: [],
        error: "",
      };
    default:
      return state;
  }
};

export const createSliderByProductIdReduer = (
  state = { loading: true, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_SLIDER_BY_PRODUCT_ID_REQUEST:
      return {
        loading: true,
      };
    case CREATE_SLIDER_BY_PRODUCT_ID_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case CREATE_SLIDER_BY_PRODUCT_ID_FAIL:
      return {
        loading: false,
        data: [],
        error: "",
      };
    default:
      return state;
  }
};

export const deleteSliderByProductIdReduer = (
  state = { loading: true, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case DELETE_SLIDER_BY_PRODUCT_ID_REQUEST:
      return {
        loading: true,
      };
    case DELETE_SLIDER_BY_PRODUCT_ID_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case DELETE_SLIDER_BY_PRODUCT_ID_FAIL:
      return {
        loading: false,
        data: [],
        error: "",
      };
    default:
      return state;
  }
};

export const showSliderByProductIdReduer = (
  state = { loading: true, datas: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_SLIDER_BY_PRODUCT_ID_REQUEST:
      return {
        loading: true,
      };
    case SHOW_SLIDER_BY_PRODUCT_ID_SUCCESS:
      return {
        loading: false,
        datas: action.payload,
        error: "",
      };
    case SHOW_SLIDER_BY_PRODUCT_ID_FAIL:
      return {
        loading: false,
        datas: [],
        error: "",
      };
    default:
      return state;
  }
};

export const updateSliderByProductIdReduer = (
  state = { loading: true, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_SLIDER_BY_PRODUCT_ID_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_SLIDER_BY_PRODUCT_ID_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case UPDATE_SLIDER_BY_PRODUCT_ID_FAIL:
      return {
        loading: false,
        data: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};


export const showProductSliderReducer = (
  state = { loading: true, sliderProduct: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_SLIDER_PRODUCT_REQUEST:
      return {
        loading: true,
      };
    case SHOW_SLIDER_PRODUCT_SUCCESS:
      return {
        loading: false,
        sliderProduct: action.payload,
        error: "",
      };
    case SHOW_SLIDER_PRODUCT_FAIL:
      return {
        loading: false,
        sliderProduct: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};
