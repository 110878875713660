import {
  CREATE_SLIDER_FAIL,
  CREATE_SLIDER_REQUEST,
  CREATE_SLIDER_SUCCESS,
  DELETE_SLIDER_FAIL,
  DELETE_SLIDER_REQUEST,
  DELETE_SLIDER_SUCCESS,
  FETCH_SLIDER_FAIL,
  FETCH_SLIDER_REQUEST,
  FETCH_SLIDER_SUCCESS,
  SHOW_SLIDER_FAIL,
  SHOW_SLIDER_REQUEST,
  SHOW_SLIDER_SUCCESS,
  UPDATE_SLIDER_FAIL,
  UPDATE_SLIDER_REQUEST,
  UPDATE_SLIDER_SUCCESS,
} from "../ActionType/SliderType/slider";

export const getSliderReducer = (
  state = { loading: true, sliders: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_SLIDER_REQUEST:
      return {
        loading: true,
      };
    case FETCH_SLIDER_SUCCESS:
      return {
        loading: false,
        sliders: action.payload,
        total: action.total,
        error: "",
      };
    case FETCH_SLIDER_FAIL:
      return {
        loading: false,
        sliders: [],
        error: "",
      };
    default:
      return state;
  }
};

export const createSliderReducer = (
  state = { loading: true, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_SLIDER_REQUEST:
      return {
        loading: true,
      };
    case CREATE_SLIDER_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case CREATE_SLIDER_FAIL:
      return {
        loading: false,
        data: [],
        error: "",
      };
    default:
      return state;
  }
};

export const deleteSliderReducer = (
  state = { loading: true, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case DELETE_SLIDER_REQUEST:
      return {
        loading: true,
      };
    case DELETE_SLIDER_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case DELETE_SLIDER_FAIL:
      return {
        loading: false,
        data: [],
        error: "",
      };
    default:
      return state;
  }
};

export const showSliderReducer = (
  state = { loading: true, datas: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_SLIDER_REQUEST:
      return {
        loading: true,
      };
    case SHOW_SLIDER_SUCCESS:
      return {
        loading: false,
        datas: action.payload,
        error: "",
      };
    case SHOW_SLIDER_FAIL:
      return {
        loading: false,
        datas: [],
        error: "",
      };
    default:
      return state;
  }
};

export const updateSliderReducer = (
  state = { loading: true, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_SLIDER_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_SLIDER_SUCCESS:
      return {
        loading: false,
        datas: action.payload,
        error: "",
      };
    case UPDATE_SLIDER_FAIL:
      return {
        loading: false,
        datas: [],
        error: "",
      };
    default:
      return state;
  }
};
