
export const CREATE_SLIDER_BY_PRODUCT_ID_REQUEST = "CREATE_SLIDER_BY_PRODUCT_ID_REQUEST";
export const CREATE_SLIDER_BY_PRODUCT_ID_SUCCESS = "CREATE_SLIDER_BY_PRODUCT_ID_SUCCESS";
export const CREATE_SLIDER_BY_PRODUCT_ID_FAIL = "CREATE_SLIDER_BY_PRODUCT_ID_FAIL";

export const FETCH_SLIDER_BY_PRODUCT_ID_REQUEST = "FETCH_SLIDER_BY_PRODUCT_ID_REQUEST";
export const FETCH_SLIDER_BY_PRODUCT_ID_SUCCESS = "FETCH_SLIDER_BY_PRODUCT_ID_SUCCESS";
export const FETCH_SLIDER_BY_PRODUCT_ID_FAIL = "FETCH_SLIDER_BY_PRODUCT_ID_FAIL";

export const DELETE_SLIDER_BY_PRODUCT_ID_REQUEST = "DELETE_SLIDER_BY_PRODUCT_ID_REQUEST";
export const DELETE_SLIDER_BY_PRODUCT_ID_SUCCESS = "DELETE_SLIDER_BY_PRODUCT_ID_SUCCESS";
export const DELETE_SLIDER_BY_PRODUCT_ID_FAIL = " DELETE_SLIDER_BY_PRODUCT_ID_FAIL";

export const SHOW_SLIDER_BY_PRODUCT_ID_REQUEST = "SHOW_SLIDER_BY_PRODUCT_ID_REQUEST";
export const SHOW_SLIDER_BY_PRODUCT_ID_SUCCESS = "SHOW_SLIDER_BY_PRODUCT_ID_SUCCESS";
export const SHOW_SLIDER_BY_PRODUCT_ID_FAIL = " SHOW_SLIDER_BY_PRODUCT_ID_FAIL";

export const UPDATE_SLIDER_BY_PRODUCT_ID_REQUEST = "UPDATE_SLIDER_BY_PRODUCT_ID_REQUEST";
export const UPDATE_SLIDER_BY_PRODUCT_ID_SUCCESS = "UPDATE_SLIDER_BY_PRODUCT_ID_SUCCESS";
export const UPDATE_SLIDER_BY_PRODUCT_ID_FAIL = " UPDATE_SLIDER_BY_PRODUCT_ID_FAIL";
