import React, { useEffect, useState } from "react";
import Room from "../../Image/home.png";
import { motion } from "framer-motion";
import Progress from "../Progress/Progress";
import { useDispatch, useSelector } from "react-redux";
import { createContactAction } from "../../Action/ContactAction/ContactAction";
import Footer from "../Footer/Footer";
import { getBackgroundContactAction } from "../../Action/backgroundContactAction/BackgroundContactAction";
import { Link, useNavigate } from "react-router-dom";
import { FaInstagram, FaTelegramPlane, FaTiktok } from "react-icons/fa";
import { IoLogoFacebook } from "react-icons/io5";
import { MdWifiCalling3 } from "react-icons/md";
import { api } from "../../Api/api";
import { getToken } from "../../Action/login";
import Swal from "sweetalert2";
const Contact = () => {
  const navigate=useNavigate();
  const getLanguge = useSelector((state) => state.getLanguge);
  const { lang } = getLanguge;

  const [inputValue, setInputValue] = useState({
    name: "",
    subject: "",
    number: "",
    message: "",
    email: "",
    // email: "",
  });
  const [error, setError] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    number: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  // const { name, subject, number, message, email } = inputValue;
  const validateForm = () => {
    const newErrors = {};

    // Name validation
    if (!inputValue.name.trim()) {
      newErrors.name = 'Please fill in the name field';
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!inputValue.email.trim() || !emailRegex.test(inputValue.email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    // Subject validation (customize as needed)
    if (!inputValue.subject.trim()) {
      newErrors.subject = 'Please fill in the subject field';
    }

    // Number validation (customize as needed)
    if (!inputValue.number.trim()) {
      newErrors.number = 'Please fill in the number field';
    }

    // Message validation (customize as needed)
    if (!inputValue.message.trim()) {
      newErrors.message = 'Please fill in the message field';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      // Proceed with API request
      const config = {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      };
      Swal.showLoading()
      api.post('email/send', {
        name: inputValue.name,
        subject: inputValue.subject,
        email: inputValue.email,
        number: inputValue.number,
        message: inputValue.message,
      }, config)
        .then((res) => {
          console.log(res)
          if(res.status==200){
            Swal.fire({
              icon:"success",
              timer:1500,
              title:"Good Job",
              text:"Email was sent successfully"
            })
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    dispatch(getBackgroundContactAction());
  }, []);
  const getBackgrounContact = useSelector((state) => state.getBackgrounContact);
  const { bgContacts, loading } = getBackgrounContact;

  const [hovered, setHovered] = useState(false);
  const handleHover = () => {
    setHovered(!hovered);
  };
  return (
    <>
      {bgContacts == undefined ? (
        ""
      ) : (
        <React.Fragment>
          <div
          onClick={()=>navigate("/contact")}
            className="   lg:bg-top bg-center lg:bg-cover relative h-screen brightness-[.7] w-full  page-section"
            id="contact"
            style={{
              backgroundSize: "cover",
              backgroundImage: `url("${bgContacts.map((bg) => {
                return bg.image;
              })}")`,
            }}
          ></div>
          {/* {bgContacts.map((bg)=>{
                return bg.image
              })} */}
          <div className=" relative">
            <motion.div
              initial={{ opacity: 0.1, transition: { duration: 0.5 } }}
              animate={{ x: 0, transition: { duration: 0.5 }, opacity: 1 }}
              className={
                lang == "en" || lang == "tu"
                  ? " text-white w-full px-4 py-2 absolute  -top-[25%] lg:-top-[75%]"
                  : lang == "ar"
                  ? " text-white text-right w-full px-4 py-2 absolute  -top-[25%] lg:-top-[75%] "
                  : " text-white w-full px-4 py-2 absolute  -top-[25%] lg:-top-[75%]"
              }
            >
              <p className=" text-sm lg:text-xl uppercase ml-2   ">
                {lang == "en"
                  ? "For more Information"
                  : lang == "ar"
                  ? "للمزيد من المعلومات"
                  : lang == "tu"
                  ? "Daha fazla bilgi için"
                  : "For more Information"}
              </p>
              <p className="text-5xl lg:text-7xl   ">
                {lang == "en"
                  ? "Contact Us"
                  : lang == "tu"
                  ? "bize Ulaşın"
                  : lang == "ar"
                  ? "اتصل بنا"
                  : "Contact Us"}
              </p>

              <p className="text-sm lg:text-lg  ml-2 mt-2    ">
                {lang == "en"
                  ? "Contact us in these different ways"
                  : lang == "ar"
                  ? "اتصل بنا بهذه الطرق المختلفة"
                  : lang == "tu"
                  ? "Bize bu farklı yollarla ulaşın"
                  : "Contact us in these different ways"}
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 100, transition: { duration: 0.5 } }}
              animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
              className="bg-[#1e1e1e]     text-[rgba(255,255,255,0.5)]  pt-3   px-1 lg:px-10   w-full  "
            >
              <div className={"flex flex-col xl:flex-row gap-36 gap-y-3"}>
                <motion.div
                  className="flex flex-col"
                  initial={{ x: -200, transition: { duration: 1 } }}
                  animate={{ x: 0, transition: { duration: 1 } }}
                >
                  <div>
                    <p
                      className={
                        "text-3xl lg:text-4xl text-[rgb(170,132,83)]  uppercase"
                      }
                    >
                      Omta Yalitm
                    </p>
                  </div>
                  

                  <div>
                    <p>Tel: {bgContacts.map((bg) => bg.tel)} </p>
                    
                  </div>
                  <div>
                    <p>Email Info: {bgContacts.map((bg) => bg.email)} </p>
                    
                  </div>
                  <div className="flex text-sm gap-2">
                    <p>Address: </p>
                    <p className="text-sm">
                      {bgContacts.map((bg) => bg.address)}
                    </p>
                  </div>

                  <div className="mt-2 w-full">
                    <iframe
                      src={bgContacts.map((bg) => bg.map1)}
                      allowfullscreen=""
                      width={"100%"}
                      className="rounded-md"
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </motion.div>
                <div>
                  <div>
                    <p className="text-3xl text-[#AA8453]">Get In Touch</p>
                  </div>
                  <div>
                    <motion.form
                      initial={{ x: 200, transition: { duration: 1 } }}
                      animate={{ x: 0, transition: { duration: 1 } }}
                      action=""
                      className="pb-10"
                    >
                      <div className=" mt-5    grid  xl:grid-cols-2  gap-2">
                        <div>
                          <input
                            className="input"
                            placeholder="Name"
                            id="inline-full-name"
                            type="text"
                            name="name"
                            value={inputValue.name}
                            onChange={handleInputChange}
                          />
                           {errors.name && <p>{errors.name}</p>}
                        </div>

                        <div>
                          <input
                            type="text"
                            name="email"
                            className="input"
                            placeholder=" Email"
                            value={inputValue.email}
                            onChange={handleInputChange}
                          />
                          {errors.email && <p>{errors.email}</p>}
                        </div>
                        <div>
                          <input
                            type="text"
                            name="number"
                            className="input"
                            placeholder="Number "
                            value={inputValue.number}
                            onChange={handleInputChange}
                          />
                          {errors.number && <p>{errors.number}</p>}
                        </div>
                        <div>
                          <input
                            type="text"
                            name="subject"
                            placeholder="Subject "
                            className="input"
                            value={inputValue.subject}
                            onChange={handleInputChange}
                          />
                          {errors.subject && <p>{errors.subject}</p>}
                        </div>
                      </div>
                      <div>
                        <textarea
                          className="input mt-2 max-h-52"
                          // onResize={false}
                          name="message"
                          value={inputValue.message}
                          onChange={handleInputChange}
                          id=""
                          cols="30"
                          rows="6"
                          placeholder="Your Message"
                        ></textarea>
                        {errors.message && <p>{errors.message}</p>}
                      </div>

                      <div>
                        <button
                          onClick={handleSubmit}
                          className="bg-[#aa8435] w-full py-2 px-1 rounded-md shadow-md hover:bg-[#8f7233] duration-700"
                        >
                          Send
                        </button>
                      </div>

                      <div className="flex  md:hidden ml-6 gap-[1.2rem]  mt-4  justify-center">
                        <Link
                          to={
                            "https://www.facebook.com/omtayalitimcompany?mibextid=zOiqff"
                          }
                          target="_blank"
                        >
                          <p className=" text-xl">
                            <IoLogoFacebook />
                          </p>
                        </Link>

                        <Link
                          to={
                            "https://www.instagram.com/omtacompany/?igsh=MXBxanlhb2twdDc2Yg%3D%3D"
                          }
                          target="_blank"
                        >
                          <p className=" text-xl">
                            <FaInstagram />
                          </p>
                        </Link>

                        <Link
                          to={
                            "https://www.tiktok.com/@omta.yalitim?_t=8jZzWkklwOb&_r=1"
                          }
                          target="_blank"
                        >
                          <p className=" text-xl">
                            <FaTiktok />
                          </p>
                        </Link>

                        <Link
                          to={"https://t.me/omtaofficial"}
                          className="text-xl"
                          target="_blank"
                        >
                          <FaTelegramPlane />
                        </Link>

                        <div className="relative pb-6 left-0 inline-block">
                          <p
                            className={`  text-xl  cursor-pointer transition-transform ${
                              hovered ? "transform-scale-110" : ""
                            }`}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHover}
                          >
                            <MdWifiCalling3 />
                          </p>

                          <div
                            className={
                              hovered
                                ? ` bg-slate-900  p-2 rounded-md  shadow-md duration-700 absolute -left-[8.2rem] top-8 opacity-100`
                                : "bg-slate-900  p-2 rounded-md shadow-md duration-700 absolute top-8 -left-[100rem]  "
                            }
                          >
                            <p>+905337400302</p>
                          </div>
                        </div>
                      </div>
                    </motion.form>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
          <Progress />

          <div>
            <Footer lang={lang} />
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default Contact;
