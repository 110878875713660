import React from "react";
import img1 from "../../Image/img1.png";
import img2 from "../../Image/img 2.png";
import img3 from "../../Image/img 3.png";
import img4 from "../../Image/img 4.png";
import img5 from "../../Image/img 5.png";
import img6 from "../../Image/img 6.png";
import { useSelector } from "react-redux";

const OurSevice = () => {
  const getLanguge = useSelector((state) => state.getLanguge);
  const { lang } = getLanguge;

  return (
    <div
      className="  w-full rounded-lg  p-8 xl:p-16   bg-[#1e1e1e] page-section"
      id="ourService"
    >
      <h1
        className={
          lang == "en" || lang == "tu"
            ? " mb-1 text-base text-[#aa8435] tracking-[0.2rem]"
            : lang == "ar"
            ? " mb-1 text-4xl text-[#aa8435] tracking-[0.2rem] text-right "
            : " mb-1 text-base text-[#aa8435] tracking-[0.2rem]"
        }
      >
        {" "}
        {lang == "ar"
          ? "خدماتنا"
          : lang == "en"
          ? "OUR SERVICES"
          : lang == "tu"
          ? "HİZMETLERİMİZ"
          : "OUR SERVICES"}
      </h1>
      <div
        className={
          lang == "en" || lang == "tu"
            ? `text-[rgba(255,255,255,0.5)]  text-3xl lg:text-5xl`
            : lang == "ar"
            ? "text-[rgba(255,255,255,0.5)]  text-right text-3xl lg:text-5xl"
            : "text-[rgba(255,255,255,0.5)]  text-3xl lg:text-5xl"
        }
      >
        {lang == "en" ? (
          <span> Omta Facilities </span>
        ) : lang == "tu" ? (
          <span> Omta Tesisleri </span>
        ) : lang == "ar" ? (
          <span className="text-right"> مرافق أومتا </span>
        ) : (
          " Omta Facilities "
        )}
      </div>

      <div className=" grid grid-cols-2  md:grid-cols-2  xl:grid-cols-3 gap-4 mt-3">
        <div className="single-facility ">
          <img src={img1} className="h-28 md:h-60 w-[48%]  md:w-6/12  lg:w-[45%] m-auto " alt="" />
        </div>
        <div className="single-facility ">
          <img src={img2} className="h-28 md:h-60 w-[48%]  lg:w-[45%] m-auto " alt="" />
        </div>
        <div className="single-facility ">
          <img src={img3} className="h-28 md:h-60 w-[48%]  lg:w-[45%] m-auto " alt="" />
        </div>
        <div className="single-facility ">
          <img src={img4} className="h-28 md:h-60 w-[48%] md:w-[50%]  lg:w-[45%] m-auto " alt="" />
        </div>
        <div className="single-facility ">
          <img src={img5} className="h-28 md:h-60 w-[48%]  lg:w-[45%] m-auto " alt="" />
        </div>
        <div className="single-facility ">
          <img src={img6} className="h-28 md:h-60 w-[48%] md:w-[50%]  lg:w-[45%] m-auto " alt="" />
        </div>
      </div>
    </div>
  );
};

export default OurSevice;
