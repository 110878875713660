export const CREATE_CATALOGE_REQUEST = "CREATE_CATALOGE_REQUEST";
export const CREATE_CATALOGE_SUCCESS = "CREATE_CATALOGE_SUCCESS";
export const CREATE_CATALOGE_FAIL = "CREATE_CATALOGE_FAIL";

export const FETCH_CATALOGE_REQUEST = "FETCH_CATALOGE_REQUEST";
export const FETCH_CATALOGE_SUCCESS = "FETCH_CATALOGE_SUCCESS";
export const FETCH_CATALOGE_FAIL = "FETCH_CATALOGE_FAIL";

export const DELETE_CATALOGE_REQUEST = "  DELETE_CATALOGE_REQUEST";
export const DELETE_CATALOGE_SUCCESS = "  DELETE_CATALOGE_SUCCESS";
export const DELETE_CATALOGE_FAIL = " DELETE_CATALOGE_FAIL";

export const SHOW_CATALOGE_REQUEST = "  SHOW_CATALOGE_REQUEST";
export const SHOW_CATALOGE_SUCCESS = "  SHOW_CATALOGE_SUCCESS";
export const SHOW_CATALOGE_FAIL = " SHOW_CATALOGE_FAIL";

export const UPDATE_CATALOGE_REQUEST = "  UPDATE_CATALOGE_REQUEST";
export const UPDATE_CATALOGE_SUCCESS = "  UPDATE_CATALOGE_SUCCESS";
export const UPDATE_CATALOGE_FAIL = " UPDATE_CATALOGE_FAIL";
