import axios from "axios";
// import CryptoJS from "crypto-js";

import Swal from "sweetalert2";
import { USER_LOGIN_REQUSET, USER_LOGIN_SUCCESS } from "../ActionType/loginTypes";
import { api } from "../Api/api";


export const login = (email, password) => (dispatch) => {
  
  loginApi
    .post("/login", {
      email,
      password,
    })
    .then((response) => {
      console.log(response.status)
      dispatch({ type: USER_LOGIN_REQUSET });
      if (response.data.status === 200) {
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: response.data.userInfo,
          error: "",
        });
        console.log(response.data.token)
        localStorage.setItem("tokendefault22330998@34#", response.data.token);
        window.location.reload();
      } else if (response.data.status == 404) {
        
        localStorage.removeItem("token");
        localStorage.removeItem("locationId");
        localStorage.removeItem("notifi");
        localStorage.removeItem("loggedIn");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.error,
          timer: 2000,
        });
      }
    })
    .catch(() => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};

export const getToken = () => {
  const token = localStorage.getItem("tokendefault22330998@34#");
  return "2|zczshDRiSoSBqlglG1b6xSBaYtwuLLq5c3zrak3W";  //server
  // return "1|VKDcN8owPgVPefcVDlnNVQw6RIyFC8DcBXts2bqC";
};

const URL="https://admin.omtayalitim.com/app/api/v1"
export const loginApi = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/v1",
  baseURL: URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});


