import { CREATE_PRODUCT_FAIL, CREATE_PRODUCT_REQUEST, CREATE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAIL, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, FETCH_PRODUCT_FAIL, FETCH_PRODUCT_REQUEST, FETCH_PRODUCT_SUCCESS, SHOW_PRODUCT_FAIL, SHOW_PRODUCT_REQUEST, SHOW_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAIL, UPDATE_PRODUCT_REQUEST, UPDATE_PRODUCT_SUCCESS } from "../ActionType/ProductType/productType";


  
  export const getProductReducer = (
      state = { loading: true, products: [],total:0, error: "" },
      action
    ) => {
      switch (action.type) {
        case FETCH_PRODUCT_REQUEST:
          return {
            loading: true,
          };
        case FETCH_PRODUCT_SUCCESS:
          return {
            loading: false,
            Products: action.payload,
            total:action.total,
            error: "",
          };
        case FETCH_PRODUCT_FAIL:
          return {
            loading: false,
            Products: [],
            error: "",
          };
        default:
          return state;
      }
    };
  
    
  export const createProductReducer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case CREATE_PRODUCT_REQUEST:
        return {
          loading: true,
        };
      case CREATE_PRODUCT_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case CREATE_PRODUCT_FAIL:
        return {
          loading: false,
          data: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  export const deleteProductReducer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case DELETE_PRODUCT_REQUEST:
        return {
          loading: true,
        };
      case DELETE_PRODUCT_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case DELETE_PRODUCT_FAIL:

        return {
          loading: false,
          data: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  
  export const showProductReducer = (
    state = { loading: true, datas: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case SHOW_PRODUCT_REQUEST:
        return {
          loading: true,
        };
      case SHOW_PRODUCT_SUCCESS:
        return {
          loading: false,
          datas: action.payload,
          error: "",
        };
      case SHOW_PRODUCT_FAIL:
        return {
          loading: false,
          datas: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  export const updateProductReduer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case UPDATE_PRODUCT_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_PRODUCT_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case UPDATE_PRODUCT_FAIL:
        return {
          loading: false,
          data: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };