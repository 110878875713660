export const CREATE_BG_CATALOGE_REQUEST = "CREATE_BG_CATALOGE_REQUEST";
export const CREATE_BG_CATALOGE_SUCCESS = "CREATE_BG_CATALOGE_SUCCESS";
export const CREATE_BG_CATALOGE_FAIL = "CREATE_BG_CATALOGE_FAIL";

export const FETCH_BG_CATALOGE_REQUEST = "FETCH_BG_CATALOGE_REQUEST";
export const FETCH_BG_CATALOGE_SUCCESS = "FETCH_BG_CATALOGE_SUCCESS";
export const FETCH_BG_CATALOGE_FAIL = "FETCH_BG_CATALOGE_FAIL";

export const DELETE_BG_CATALOGE_REQUEST = "  DELETE_BG_CATALOGE_REQUEST";
export const DELETE_BG_CATALOGE_SUCCESS = "  DELETE_BG_CATALOGE_SUCCESS";
export const DELETE_BG_CATALOGE_FAIL = " DELETE_BG_CATALOGE_FAIL";

export const SHOW_BG_CATALOGE_REQUEST = "  SHOW_BG_CATALOGE_REQUEST";
export const SHOW_BG_CATALOGE_SUCCESS = "  SHOW_BG_CATALOGE_SUCCESS";
export const SHOW_BG_CATALOGE_FAIL = " SHOW_BG_CATALOGE_FAIL";

export const UPDATE_BG_CATALOGE_REQUEST = "  UPDATE_BG_CATALOGE_REQUEST";
export const UPDATE_BG_CATALOGE_SUCCESS = "  UPDATE_BG_CATALOGE_SUCCESS";
export const UPDATE_BG_CATALOGE_FAIL = " UPDATE_BG_CATALOGE_FAIL";
