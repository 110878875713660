import { FETCH_LANGUGE_FAIL, FETCH_LANGUGE_REQUEST, FETCH_LANGUGE_SUCCESS } from "../ActionType/langugeType";

export const getLangugeReduer = (
    state = { loading: true, lang: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case FETCH_LANGUGE_REQUEST:
        return {
          loading: true,
        };
      case FETCH_LANGUGE_SUCCESS:
        return {
          loading: false,
          lang: action.payload || 'en',
        //   total: action.total,
          error: "",
        };
      case FETCH_LANGUGE_FAIL:
        return {
          loading: false,
          lang: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };
  