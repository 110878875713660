import React, { useEffect, useState } from "react";
import gt from "../../Image/gt.png";
import Home from "../../Image/home.png";
import { motion } from "framer-motion";
import Progress from "../Progress/Progress";
import { getProductAction } from "../../Action/ProductAction/productAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showSliderProductByProductIdAction } from "../../Action/ProductAction/ProductSliderAction";
const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { lang } = useParams();
  const getLanguge = useSelector((state) => state.getLanguge);
  const { lang } = getLanguge;

  useEffect(() => {
    dispatch(showSliderProductByProductIdAction(id));
  }, []);
  const showSliderByProductId = useSelector(
    (state) => state.showSliderByProductId
  );
  const { loading, datas } = showSliderByProductId;
  const itemsPerPageDesktop = 5;
  const itemsPerPageMobile = 1;
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = () => {
    // Adjust the number of items based on the screen size
    return window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop;
  };

  useEffect(() => {
    if (datas && datas.length > 0) {
      const interval = setInterval(() => {
        setCurrentPage(
          (prevPage) =>
            (prevPage + 1) % Math.ceil(datas.length / itemsPerPage())
        );
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [datas, itemsPerPage()]);

  if (!datas || datas.length === 0) {
    return null; // or display a loading indicator, message, etc.
  }

  const startIdx = currentPage * itemsPerPage();
  const endIdx = Math.min((currentPage + 1) * itemsPerPage(), datas.length);

  const handleNext = () => {
    setCurrentPage(
      (prevPage) => (prevPage + 1) % Math.ceil(datas.length / itemsPerPage())
    );
  };

  const handlePrev = () => {
    setCurrentPage(
      (prevPage) =>
        (prevPage - 1 + Math.ceil(datas.length / itemsPerPage())) %
        Math.ceil(datas.length / itemsPerPage())
    );
  };

  return (
    <>
      {datas == undefined || loading ? (
        ""
      ) : (
        <>
          

          <motion.div
            initial={{ y: -90 }}
            animate={{ y: 0 }}
            className=" hover:cursor-pointer mt-4   grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 px-4 pb-20"
          >
            {datas.map((data, index) => {
              return (
                <div
                  onClick={() => {
                    navigate("/product/slide/details/" + data.id);
                  }}
                  key={index}
                  className="bg-slate-950     text-white rounded-md shadow-md mt-2"
                >
                  <div className=" ">
                    <img
                      src={data.image}
                      className="w-full h-72 rounded-md hover:scale-[.98]  duration-500 object-cover"
                      alt=""
                    />
                  </div>
                  <div
                    className={
                      lang == "ar"
                        ? "px-1 text-xl py-3 text-right text-[rgba(255,255,255,0.5)]"
                        : "px-1 text-xl py-3 text-left text-[rgba(255,255,255,0.5)]"
                    }
                  >
                    {lang == "ar" ? (
                      <p className="text-[rgba(255,255,255,0.5)]">{data.title_ar}</p>
                    ) : lang == "en" ? (
                      <p className="text-[rgba(255,255,255,0.5)]">{data.title}</p>
                    ) : lang == "tu" ? (
                      <p className="text-[rgba(255,255,255,0.5)]">{data.title_tu}</p>
                    ) : (
                      <p className="text-[rgba(255,255,255,0.5)]">{data.title}</p>
                    )}
                  </div>
                </div>
              );
            })}
          </motion.div>

          {/* <Progress /> */}
        </>
      )}
    </>
  );
};

export default ProductDetails;
