
  import { api } from "../../Api/api";
  
  import Swal from "sweetalert2";
  import { getToken } from "../login";
import { CREATE_PRODUCT_FAIL, CREATE_PRODUCT_REQUEST, CREATE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAIL, DELETE_PRODUCT_SUCCESS, FETCH_PRODUCT_FAIL, FETCH_PRODUCT_REQUEST, FETCH_PRODUCT_SUCCESS, SHOW_PRODUCT_FAIL, SHOW_PRODUCT_REQUEST, SHOW_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAIL, UPDATE_PRODUCT_REQUEST, UPDATE_PRODUCT_SUCCESS } from "../../ActionType/ProductType/productType";
  
  export const getProductAction = () => (dispatch) => {
  
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
   
    };
  
  
    dispatch({
      type: FETCH_PRODUCT_REQUEST,
    });
    api
      .get("product", config)
      .then((res) => {
        dispatch({
          type: FETCH_PRODUCT_SUCCESS,
          payload: res.data.data,
          total:res.data.total,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_PRODUCT_FAIL,
          payload: [],
          error: "",
        });
      });
  };
  
  export const createProductAction = (title, title_ar,title_tu, image) => dispatch=> {
  
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      
    };
    const formData = new FormData();
    formData.append("title", title);
    formData.append("title_ar", title_ar);
    formData.append("title_tu", title_tu);
    
    formData.append("image", image);
  
  
    dispatch({
      type:CREATE_PRODUCT_REQUEST,
      
    });
  
    api
      .post("product/create", formData, config)
      .then((res) => {
        if (res.status == 201) {
          console.log(res)
          Swal.fire({
            icon: "success",
            timer: 2000,
            title: "Good Job",
            text: "Information Was Saved",
          });
          dispatch({
            type:CREATE_PRODUCT_SUCCESS,
            payload:res.data
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          timer: 2000,
          title: "Error",
          text: "Please Check Your Request",
        });
        dispatch({
          type:CREATE_PRODUCT_FAIL,
        });
      });
  };
  
  export const deleteProductAction = (id) => (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      
    };
  
    api
      .delete(`product/delete/${id}`, config)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: DELETE_PRODUCT_SUCCESS,
            payload: res.data,
          });
          Swal.fire({
            icon: "success",
            timer: 2000,
            title: "Good Job",
            text: "Information Was Delete",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: DELETE_PRODUCT_FAIL,
          // payload: res.data,
        });
        Swal.fire({
          icon: "error",
          timer: 2000,
          title: "Error",
          text: "Please Check Your Request",
        });
        console.log(err);
      });
  };
  
  export const showProductAction = (id) => (dispatch) => {
    //   console.log(formData)
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      
    };
    dispatch({
      type: SHOW_PRODUCT_REQUEST,
      // payload: res.data,
    });
    api
      .get(`product/show/${id}`, config)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: SHOW_PRODUCT_SUCCESS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: SHOW_PRODUCT_FAIL,
          // payload: res.data,
        });
        Swal.fire({
          icon: "error",
          timer: 2000,
          title: "Error",
          text: "Please Check Your Request",
        });
        // console.log(err);
      });
  };
  
  export const updateProductAction = (id,title, title_ar,title_tu, image) => (dispatch) => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("title_ar", title_ar);
    formData.append("title_tu", title_tu);
 
    formData.append("image", image);
    dispatch({
      type:UPDATE_PRODUCT_REQUEST  
    })
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      
    };
    api
      .post(`product/update/${id}`,formData, config)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type:UPDATE_PRODUCT_SUCCESS,
            payload:res.data
          })
          Swal.fire({
            icon: "success",
            timer: 2000,
            title: "Good Job",
            text: "Information Was Saved",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_PRODUCT_FAIL,
          // payload: res.data,
        });
        Swal.fire({
          icon: "error",
          timer: 2000,
          title: "Error",
          text: "Please Check Your Request",
        });
        console.log(err);
      });
  };
  