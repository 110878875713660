import React, { useEffect } from "react";

import { motion } from "framer-motion";
import Progress from "../Progress/Progress";
import { useDispatch, useSelector } from "react-redux";
import { getAboutAction } from "../../Action/About/aboutAction";
import Logo from '../../Image/logo.png'
import { useNavigate } from "react-router-dom";
const About = () => {
  const navigate=useNavigate();
  const getLanguge = useSelector((state) => state.getLanguge);
  const { lang } = getLanguge;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAboutAction(1));
  }, []);

  
  const getAbout = useSelector((state) => state.getAbout);
  const { abouts } = getAbout;
  // const [expand, setExpand] = useState(false);

  
  return (
    <>
      {abouts == undefined ? (
        ""
      ) : (
        <>
          <div
          onClick={()=>navigate("/about")}
            className="   bg-black  bg-center lg:bg-top lg:bg-cover relative  h-screen brightness-[.7] w-full  page-section"
            id="about"
            style={{
              backgroundImage: `url("${abouts.map((about) => {
                return about.image;
              })}")`,
            }}
          />

          <div className="relative">

          <motion.div
            initial={{ opacity: 0.1, y: -10 }}
            animate={{ y: 0, transition: { duration: 0.5 }, opacity: 1 }}
            className={` text-white px-4 py-2  absolute -top-[50%] lg:-top-[65%] ${
              lang == "ar" ? "text-right right-0" : "text-left"
            }  `}
          >
            <p className={`text-lg uppercase    `}>
              OMTA Yalitim
            </p>
            <p
              className={`  text-5xl  lg:text-6xl   ${
                lang == "ar" ? "text-right" : "text-left"
              }   `}
            >
              {lang == "en"
                ? "About Us"
                : lang == "tu"
                ? "Hakkımızda"
                : lang == "ar"
                ? "معلومات عنا"
                : "About Us"}
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 10, transition: { duration: 0 } }}
            animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }}
            className={
              lang == "en" || lang == "tu"
                ? `bg-[#1e1e1e]     flex flex-col lg:flex-row  items-center w-full gap-10   lg:gap-48     justify-between     text-[rgba(255,255,255,0.5)]  pb-10 pt-10 px-4  duration-500 `
                : lang == "ar"
                ? "bg-[#1e1e1e]     flex flex-col lg:flex-row-reverse w-full gap-10   lg:gap-48  items-center        text-[rgba(255,255,255,0.5)]  pb-10 pt-10 px-4  duration-500 "
                : "bg-[#1e1e1e]     flex flex-col lg:flex-row w-full gap-10   lg:gap-48 items-center justify-between          text-[rgba(255,255,255,0.5)]  pb-10 pt-10 px-4  duration-500 "
            }
          >
            <div className="w-full">
              <p
                className={
                  lang == "en" || lang == "tu"
                    ? `text-3xl titleAll uppercase  text-[#AA8453]`
                    : lang == "ar"
                    ? `text-3xl titleAll  text-[#AA8453] text-right uppercase`
                    : "text-3xl titleAll  text-[#AA8453] uppercase"
                }
              >
                OMTA Yalitim
              </p>
              {abouts.map((about, index) => {
                return (
                  <p key={index}>
                    {lang == "en"
                      ? about.en_text
                      : lang == "tu"
                      ? about.tu_text
                      : lang == "ar"
                      ? about.ar_text
                      : about.en_text}
                  </p>
                );
              })}
            </div>

            <div className=" lg:w-9/12 px-3">
              <img src={Logo} className="opacity-20 object-cover" alt="" />      
            </div>
          </motion.div>
          </div>
          <Progress />
        </>
      )}

      {/* <Footer/> */}
    </>
  );
};

export default About;
