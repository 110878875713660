import {
  CREATE_ABOUT_FAIL,
  CREATE_ABOUT_REQUEST,
  CREATE_ABOUT_SUCCESS,
  DELETE_ABOUT_FAIL,
  DELETE_ABOUT_REQUEST,
  DELETE_ABOUT_SUCCESS,
  FETCH_ABOUT_FAIL,
  FETCH_ABOUT_REQUEST,
  FETCH_ABOUT_SUCCESS,
  SHOW_ABOUT_FAIL,
  SHOW_ABOUT_REQUEST,
  SHOW_ABOUT_SUCCESS,
  UPDATE_ABOUT_FAIL,
  UPDATE_ABOUT_REQUEST,
  UPDATE_ABOUT_SUCCESS,
} from "../ActionType/AboutType/about";

export const createAboutReduer = (
  state = { loading: true, abouts: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_ABOUT_REQUEST:
      return {
        loading: true,
      };
    case CREATE_ABOUT_SUCCESS:
      return {
        loading: false,
        abouts: action.payload,
        error: "",
      };
    case CREATE_ABOUT_FAIL:
      return {
        loading: false,
        abouts: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};



export const getAboutReduer = (
    state = { loading: true, abouts: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case FETCH_ABOUT_REQUEST:
        return {
          loading: true,
        };
      case FETCH_ABOUT_SUCCESS:
        return {
          loading: false,
          abouts: action.payload,
          total:action.total,
          error: "",
        };
      case FETCH_ABOUT_FAIL:
        return {
          loading: false,
          abouts: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };

  export const deleteAboutReduer = (
    state = { loading: true, abouts: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case DELETE_ABOUT_REQUEST:
        return {
          loading: true,
        };
      case DELETE_ABOUT_SUCCESS:
        return {
          loading: false,
          abouts: action.payload,
          error: "",
        };
      case DELETE_ABOUT_FAIL:
        return {
          loading: false,
          abouts: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };

  
  export const showAboutReduer = (
    state = { loading: true, about: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case SHOW_ABOUT_REQUEST:
        return {
          loading: true,
        };
      case SHOW_ABOUT_SUCCESS:
        return {
          loading: false,
          about: action.payload,
          error: "",
        };
      case SHOW_ABOUT_FAIL:
        return {
          loading: false,
          about: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };

  export const updateAboutReduer = (
    state = { loading: true, about: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case UPDATE_ABOUT_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_ABOUT_SUCCESS:
        return {
          loading: false,
          about: action.payload,
          error: "",
        };
      case UPDATE_ABOUT_FAIL:
        return {
          loading: false,
          about: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };