export const CREATE_SLIDER_PRODUCT_REQUEST = "CREATE_SLIDER_PRODUCT_REQUEST";
export const CREATE_SLIDER_PRODUCT_SUCCESS = "CREATE_SLIDER_PRODUCT_SUCCESS";
export const CREATE_SLIDER_PRODUCT_FAIL = "CREATE_SLIDER_PRODUCT_FAIL";

export const FETCH_SLIDER_PRODUCT_REQUEST = "FETCH_SLIDER_PRODUCT_REQUEST";
export const FETCH_SLIDER_PRODUCT_SUCCESS = "FETCH_SLIDER_PRODUCT_SUCCESS";
export const FETCH_SLIDER_PRODUCT_FAIL = "FETCH_SLIDER_PRODUCT_FAIL";

export const DELETE_SLIDER_PRODUCT_REQUEST = "DELETE_SLIDER_PRODUCT_REQUEST";
export const DELETE_SLIDER_PRODUCT_SUCCESS = "DELETE_SLIDER_PRODUCT_SUCCESS";
export const DELETE_SLIDER_PRODUCT_FAIL = " DELETE_SLIDER_PRODUCT_FAIL";

export const SHOW_SLIDER_PRODUCT_REQUEST = "SHOW_SLIDER_PRODUCT_REQUEST";
export const SHOW_SLIDER_PRODUCT_SUCCESS = "SHOW_SLIDER_PRODUCT_SUCCESS";
export const SHOW_SLIDER_PRODUCT_FAIL = " SHOW_SLIDER_PRODUCT_FAIL";

export const UPDATE_SLIDER_PRODUCT_REQUEST = "UPDATE_SLIDER_PRODUCT_REQUEST";
export const UPDATE_SLIDER_PRODUCT_SUCCESS = "UPDATE_SLIDER_PRODUCT_SUCCESS";
export const UPDATE_SLIDER_PRODUCT_FAIL = " UPDATE_SLIDER_PRODUCT_FAIL";
