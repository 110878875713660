export const CREATE_BG_PRODUCT_REQUEST = "CREATE_BG_PRODUCT_REQUEST";
export const CREATE_BG_PRODUCT_SUCCESS = "CREATE_BG_PRODUCT_SUCCESS";
export const CREATE_BG_PRODUCT_FAIL = "CREATE_BG_PRODUCT_FAIL";

export const FETCH_BG_PRODUCT_REQUEST = "FETCH_BG_PRODUCT_REQUEST";
export const FETCH_BG_PRODUCT_SUCCESS = "FETCH_BG_PRODUCT_SUCCESS";
export const FETCH_BG_PRODUCT_FAIL = "FETCH_BG_PRODUCT_FAIL";

export const DELETE_BG_PRODUCT_REQUEST = "DELETE_BG_PRODUCT_REQUEST";
export const DELETE_BG_PRODUCT_SUCCESS = "DELETE_BG_PRODUCT_SUCCESS";
export const DELETE_BG_PRODUCT_FAIL = " DELETE_BG_PRODUCT_FAIL";

export const SHOW_BG_PRODUCT_REQUEST = "SHOW_BG_PRODUCT_REQUEST";
export const SHOW_BG_PRODUCT_SUCCESS = "SHOW_BG_PRODUCT_SUCCESS";
export const SHOW_BG_PRODUCT_FAIL = " SHOW_BG_PRODUCT_FAIL";

export const UPDATE_BG_PRODUCT_REQUEST = "UPDATE_BG_PRODUCT_REQUEST";
export const UPDATE_BG_PRODUCT_SUCCESS = "UPDATE_BG_PRODUCT_SUCCESS";
export const UPDATE_BG_PRODUCT_FAIL = " UPDATE_BG_PRODUCT_FAIL";
