export const CREATE_ABOUT_SUCCESS = "CREATE_ABOUT_SUCCESS";
export const CREATE_ABOUT_REQUEST = "CREATE_ABOUT_REQUEST";
export const CREATE_ABOUT_FAIL = "CREATE_ABOUT_FAIL";

export const FETCH_ABOUT_SUCCESS = "FETCH_ABOUT_SUCCESS";
export const FETCH_ABOUT_REQUEST = "FETCH_ABOUT_REQUEST";
export const FETCH_ABOUT_FAIL = "FETCH_ABOUT_FAIL";

export const DELETE_ABOUT_SUCCESS = "DELETE_ABOUT_SUCCESS";
export const DELETE_ABOUT_REQUEST = "DELETE_ABOUT_REQUEST";
export const DELETE_ABOUT_FAIL = "DELETE_ABOUT_FAIL";

export const SHOW_ABOUT_SUCCESS = "SHOW_ABOUT_SUCCESS";
export const SHOW_ABOUT_REQUEST = "SHOW_ABOUT_REQUEST";
export const SHOW_ABOUT_FAIL = "SHOW_ABOUT_FAIL";

export const UPDATE_ABOUT_SUCCESS = "UPDATE_ABOUT_SUCCESS";
export const UPDATE_ABOUT_REQUEST = "UPDATE_ABOUT_REQUEST";
export const UPDATE_ABOUT_FAIL = "UPDATE_ABOUT_FAIL";