import {
  CREATE_BACKGROUND_CONTACT_FAIL,
  CREATE_BACKGROUND_CONTACT_REQUEST,
  CREATE_BACKGROUND_CONTACT_SUCCESS,
  DELETE_BACKGROUND_CONTACT_FAIL,
  DELETE_BACKGROUND_CONTACT_REQUEST,
  DELETE_BACKGROUND_CONTACT_SUCCESS,
  FETCH_BACKGROUND_CONTACT_FAIL,
  FETCH_BACKGROUND_CONTACT_REQUEST,
  FETCH_BACKGROUND_CONTACT_SUCCESS,
  SHOW_BACKGROUND_CONTACT_FAIL,
  SHOW_BACKGROUND_CONTACT_REQUEST,
  SHOW_BACKGROUND_CONTACT_SUCCESS,
  UPDATE_BACKGROUND_CONTACT_FAIL,
  UPDATE_BACKGROUND_CONTACT_REQUEST,
  UPDATE_BACKGROUND_CONTACT_SUCCESS,
} from "../ActionType/backgroundContactType/BackgroundContactType";

export const createBackgrounContactReduer = (
  state = { loading: true, bgContact: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_BACKGROUND_CONTACT_REQUEST:
      return {
        loading: true,
      };
    case CREATE_BACKGROUND_CONTACT_SUCCESS:
      return {
        loading: false,
        bgContacts: action.payload,
        error: "",
      };
    case CREATE_BACKGROUND_CONTACT_FAIL:
      return {
        loading: false,
        bgContacts: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};

export const getBackgrounContactReduer = (
  state = { loading: true, bgContacts: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_BACKGROUND_CONTACT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_BACKGROUND_CONTACT_SUCCESS:
      return {
        loading: false,
        bgContacts: action.payload,
        total: action.total,
        error: "",
      };
    case FETCH_BACKGROUND_CONTACT_FAIL:
      return {
        loading: false,
        bgContacts: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};

export const deleteBackgrounContactReduer = (
  state = { loading: true, bgContact: [], error: "" },
  action
) => {
  switch (action.type) {
    case DELETE_BACKGROUND_CONTACT_REQUEST:
      return {
        loading: true,
      };
    case DELETE_BACKGROUND_CONTACT_SUCCESS:
      return {
        loading: false,
        bgContact: action.payload,
        error: "",
      };
    case DELETE_BACKGROUND_CONTACT_FAIL:
      return {
        loading: false,
        bgContact: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};

export const showBackgrounContactReduer = (
  state = { loading: true, bgContact: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_BACKGROUND_CONTACT_REQUEST:
      return {
        loading: true,
      };
    case SHOW_BACKGROUND_CONTACT_SUCCESS:
      return {
        loading: false,
        bgContact: action.payload,
        error: "",
      };
    case SHOW_BACKGROUND_CONTACT_FAIL:
      return {
        loading: false,
        bgContact: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};

export const updateBackgrounContactReduer = (
  state = { loading: true, bgContact: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_BACKGROUND_CONTACT_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_BACKGROUND_CONTACT_SUCCESS:
      return {
        loading: false,
        bgContact: action.payload,
        error: "",
      };
    case UPDATE_BACKGROUND_CONTACT_FAIL:
      return {
        loading: false,
        bgContact: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};
