export const CREATE_BG_ACTIVITY_SUCCESS = "CREATE_BG_ACTIVITY_SUCCESS";
export const CREATE_BG_ACTIVITY_REQUEST = "CREATE_BG_ACTIVITY_REQUEST";
export const CREATE_BG_ACTIVITY_FAIL = "CREATE_BG_ACTIVITY_FAIL";

export const FETCH_BG_ACTIVITY_SUCCESS = "FETCH_BG_ACTIVITY_SUCCESS";
export const FETCH_BG_ACTIVITY_REQUEST = "FETCH_BG_ACTIVITY_REQUEST";
export const FETCH_BG_ACTIVITY_FAIL = "FETCH_BG_ACTIVITY_FAIL";

export const DELETE_BG_ACTIVITY_SUCCESS = "DELETE_BG_ACTIVITY_SUCCESS";
export const DELETE_BG_ACTIVITY_REQUEST = "DELETE_BG_ACTIVITY_REQUEST";
export const DELETE_BG_ACTIVITY_FAIL = "DELETE_BG_ACTIVITY_FAIL";

export const SHOW_BG_ACTIVITY_SUCCESS = "SHOW_BG_ACTIVITY_SUCCESS";
export const SHOW_BG_ACTIVITY_REQUEST = "SHOW_BG_ACTIVITY_REQUEST";
export const SHOW_BG_ACTIVITY_FAIL = "SHOW_BG_ACTIVITY_FAIL";

export const UPDATE_BG_ACTIVITY_SUCCESS = "UPDATE_BG_ACTIVITY_SUCCESS";
export const UPDATE_BG_ACTIVITY_REQUEST = "UPDATE_BG_ACTIVITY_REQUEST";
export const UPDATE_BG_ACTIVITY_FAIL = "UPDATE_BG_ACTIVITY_FAIL";