import React, { useEffect } from "react";

const Footer = ({ lang }) => {
  return (
    <footer
      className={` bottom-0  ${
        lang == "en" || lang == "tu" ? "right-0" : lang == "ar" ? "left-0" : ""
      }  right-0 w-full xl:w-full bg-[#171717] text-white p-2 lg:p-4 text-center`}
    >
      {/* Add your footer content here */}
      <div className="flex  justify-between">
        <div>
          <p>&copy; 2024 All rights reserved. </p>
        </div>
        <div>
          <p className="text-[#ab8554] text-3xl flex gap-4"> BR </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
