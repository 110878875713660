import React, { useEffect, useState } from "react";
import "./App.css";
import Sidebar from "./Pages/Bars/Sidebar";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Navbar from "./Pages/Bars/Navbar";
import Galary from "./Pages/Galary/Galary";
import Catalog from "./Pages/Catalog/Catalog";
import Product from "./Pages/Products/Product";
import Activity from "./Pages/Activity/Activity";
import Contact from "./Pages/Contact/Contact";

import { LanguageProvider } from "./Pages/Lang/LanguageProvider";
import Footer from "./Pages/Footer/Footer";
import { useSelector } from "react-redux";
import CatalogeDetails from "./Pages/Catalog/CatalogeDetails";
import ProductDetails from "./Pages/Products/ProductDetails";
import ProductSlideDetalis from "./Pages/Products/ProductSlideDetalis";
import ActivityDetalis from "./Pages/Activity/ActivityDetalis";
import GallerySide from "./Pages/Galary/GallerySide";
const App = () => {
  // let languge=localStorage.getItem("languge");
  const getLanguge=useSelector(state=>state.getLanguge)
  const {lang}=getLanguge;

  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.page-section');

      sections.forEach((section) => {
        const boundingBox = section.getBoundingClientRect();

        if (boundingBox.top <= 150 && boundingBox.bottom >= 150) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <>
      <div className="h-full">
        
        <div className={lang=="en" || lang=="tu" ?`h-full  flex`:lang=="ar"?"h-full flex flex-row-reverse":"h-full  flex"}>
          <BrowserRouter>
            <div className="hidden lg:block lg:w-[21%] h-full">
              
              <Sidebar activeSection={activeSection}  clearActivation={setActiveSection}/>
            </div>
          

            <div className="w-full  overflow-y-auto overflow-x-hidden ">
            <div className="lg:hidden">
              <Navbar />
            </div>
              <LanguageProvider>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About  />} />
                  <Route path="/galary" element={<GallerySide  />} />
                  <Route path="/cataloge" element={<Catalog  />} />
           
                  <Route path="/activity" element={<Activity  />} />
                  <Route path="/activity/details/:id" element={<ActivityDetalis  />} />
                  <Route path="/contact" element={<Contact  />} />
                  <Route path="/cataloge/details/:id" element={<CatalogeDetails  />} />

                  <Route path="/product" element={<Product  />} />
                  <Route path="/product/:id" element={<ProductDetails  />} />
                  <Route path="/product/slide/details/:id" element={<ProductSlideDetalis  />} />
                </Routes>
              </LanguageProvider>
            </div>
          </BrowserRouter>
        </div>
      </div>

      
      {/* <div className="w-full h-20 fixed bottom-0 text-white   bg-black">
        <footer className=" w-full">
          <div className="flex px-10 justify-between pt-4 items-center">
            <div>
              <p>2024 @ All Right Recerved </p>
            </div>
            <div>
              <p>Bryar </p>
              <p>DESIGN & DEVELOPMENT</p>
            </div>
          </div>
        </footer>
      </div> */}
    </>
  );
};

export default App;
