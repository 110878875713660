// Home.js
import React, { useEffect, useRef, useState } from "react";
import "./home.css";
import About from "../About/About";
import Galary from "../Galary/Galary";
import Catalog from "../Catalog/Catalog";
import Product from "../Products/Product";
import Activity from "../Activity/Activity";
import Contact from "../Contact/Contact";
// import Progress from '../Progress/Progress';
import { Link as ScrollLink } from "react-scroll";
import ScrollAnimation from "../ScrollAnimation/ScrollAnimation";
import { FaArrowDown } from "react-icons/fa6";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { getSliderAction } from "../../Action/SliderAction/SliderAction";

import OurSevice from "../service/OurSevice";
import { getHomeAction } from "../../Action/HomeAction/HomeAction";

const Home = () => {
  // const getLanguge = useSelector((state) => state.getLanguge);
  // const { lang } = getLanguge;
  const getLanguge = useSelector((state) => state.getLanguge);
  const { lang } = getLanguge;

  const getSlider = useSelector((state) => state.getSlider);
  const { sliders, loading: sliderLoading } = getSlider;

  const getHome = useSelector((state) => state.getHome);
  const { Homes, loading: homeLoading } = getHome;

  // const getSlider = useSelector((state) => state.getSlider);
  // const { sliders, loading } = getSlider;
  const dispatch = useDispatch();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    dispatch(getSliderAction());
  }, []);
  useEffect(() => {
    let currentIndex = 0; // Initialize currentIndex to 0

    const changeImage = () => {
      const header = document.querySelector(".App-header");

      // Ensure header exists before proceeding
      if (!header) {
        return;
      }

      // Create a new image element
      const newImage = new Image();
      newImage.src = sliders[currentIndex]?.image;
      newImage.style.opacity = "0"; // Start with opacity 0
      newImage.style.transition =
        "opacity 1s ease-in-out, transform 10s ease-in-out"; // Apply transition effect for opacity and scale
      newImage.style.position = "absolute"; // Position absolutely to overlap with the previous image
      newImage.style.transform = "scale(1.4)"; // Initial scale

      newImage.style.width = "100%"; // Set width to 100% to fill the screen
      newImage.style.height = "100%"; // Set height to 100% to cover the entire screen

      // Append the new image to the header
      header.appendChild(newImage);

      // Fade in the new image and adjust scale
      setTimeout(() => {
        newImage.style.opacity = "1"; // Increase opacity to 1
        newImage.style.transform = "scale(1)"; // Set scale to 1
      }, 100); // Delay for smoother transition

      // Increment currentIndex and ensure it wraps around
      currentIndex = (currentIndex + 1) % sliders.length;
    };

    // Call changeImage initially
    changeImage();

    // Setup interval to call changeImage every 9000 milliseconds
    const intervalId = setInterval(changeImage, 9000);

    // Cleanup function
    return () => {
      clearInterval(intervalId);
    };
  }, [sliders]);

  // Apply additional mobile styles using media queries
  const mobileStyles = `
    @media (max-width: 767px) {
      .App-header {
        // Add your mobile styles for the header here
        // For example, adjust font size or padding
      }
  
      .App-header img {
        object-fit: cover; // Apply object-fit: cover specifically for mobile
      }
    }
  `;

  const styleTag = document.createElement("style");
  styleTag.type = "text/css";
  styleTag.appendChild(document.createTextNode(mobileStyles));
  document.head.appendChild(styleTag);

  useEffect(() => {
    dispatch(getHomeAction());
  }, []);

  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  useEffect(() => {
    dispatch(getSliderAction());
    dispatch(getHomeAction());
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  
  const [loadedPages, setLoadedPages] = useState([1]); // Initially, page 1 is loaded

  useEffect(() => {
    dispatch(getSliderAction());
    dispatch(getHomeAction());
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;

    if (scrollPosition - lastScrollPosition >= windowHeight) {
      setLastScrollPosition(scrollPosition);
      setCurrentPage((prevPage) => prevPage + 1);
      setLoadedPages((prevPages) => [...prevPages, currentPage + 1]);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentPage]); // Re-run effect when currentPage changes

  useEffect(() => {
    console.log("Current Page:", currentPage);
    // Here you can load more data based on currentPage
    // dispatch(loadMoreDataAction(currentPage));
  }, [currentPage]);


  return (
    <>
      {sliders == undefined ||
      sliderLoading ||
      homeLoading ||
      Homes == undefined ? (
        <div className="w-full h-full absolute left-0 top-0 bg-black">
          <span className="loader absolute top-[50%] left-[48%] lg:left-[59%] text-white"></span>
        </div>
      ) : (
        <div className="overflow-hidden">
          <div className="overflow-hidden page-section" id="home">
            <header
              className={`App-header  brightness-[.6] z-0 `}
              style={{
                backgroundImage:
                  sliders && sliders.length > 0
                    ? `url(${sliders[currentImageIndex]?.image})`
                    : "",
              }}
            ></header>

            <div
              className={
                lang == "ar"
                  ? "absolute top-1/2 right-[50%] overflow-hidden  lg:right-[60%] transform z-50 translate-x-1/2 -translate-y-1/2 text-white text-center"
                  : "absolute top-1/2 left-[50%] overflow-hidden  lg:left-[60%] transform z-50 -translate-x-1/2 -translate-y-1/2 text-white text-center"
              }
            >
              <div className="text-center ">
                <div className=" text-white">
                  <motion.p
                    initial={{ y: -200, transition: { duration: 1 } }}
                    animate={{
                      color: "white",
                      y: 0,
                      transition: { duration: 1 },
                    }}
                    className="titleAll text-2xl xl:text-5xl"
                  >
                    {Homes.map((home, index) => {
                      return (
                        <React.Fragment key={index}>
                          {lang == "en" ? (
                            <p>{home.title}</p>
                          ) : lang == "tu" ? (
                            <p>{home.title_tu}</p>
                          ) : lang == "ar" ? (
                            <p>{home.title_ar}</p>
                          ) : (
                            <p>{home.title}</p>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </motion.p>
                  <motion.p
                    initial={{ y: -200, transition: { duration: 1 } }}
                    animate={{
                      color: "white",
                      y: 0,
                      transition: { duration: 0.5 },
                    }}
                    className="text-lg xl:text-3xl"
                  >
                    {Homes.map((home, index) => {
                      return (
                        <React.Fragment key={index}>
                          {lang == "en" ? (
                            <p>{home.desc}</p>
                          ) : lang == "tu" ? (
                            <p>{home.desc_tu}</p>
                          ) : lang == "ar" ? (
                            <p>{home.desc_ar}</p>
                          ) : (
                            <p>{home.desc}</p>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </motion.p>
                </div>

                {Homes.map((home, index) => {
                  return (
                    <motion.a
                      key={index}
                      href={home.link}
                      initial={{ y: -200, transition: { duration: 1 } }}
                      animate={{ y: 0, transition: { duration: 0.2 } }}
                      className="btn41-43 btn-41 titleAll text-white hover:text-amber-700 mt-10"
                    >
                      Click Here
                    </motion.a>
                  );
                })}
              </div>
            </div>

            <ScrollLink to="about" smooth={true} duration={500}>
              <div
                className={
                  lang == "ar"
                    ? " animate-bounce lg:right-[58.5%] right-[46%] text-white  absolute bottom-36 lg:bottom-32 cursor-pointer hover:text-[#AA8453] hover:border-[#AA8453] border border-white rounded-full py-3 px-3"
                    : "scroll-down-arrow text-white  absolute bottom-36 lg:bottom-32 cursor-pointer hover:text-[#AA8453] hover:border-[#AA8453] border border-white rounded-full py-3 px-3"
                }
              >
                <FaArrowDown />
              </div>
            </ScrollLink>
          </div>
          {
            loadedPages.includes(1)?<ScrollAnimation>
            <div id="about">
              <About />
            </div>
          </ScrollAnimation>:""
          }
            
          
          
          <ScrollAnimation>
            <div id="OurSevice " className="  rounded-md ">
              <OurSevice />
            </div>
          </ScrollAnimation>

          {
            loadedPages.includes(2)?<ScrollAnimation>
            <div id="gallery">
              <Galary />
            </div>
          </ScrollAnimation>:""
          }
          
          {loadedPages.includes(4) ?<ScrollAnimation>
            <div id="catalog">
              <Catalog />
            </div>
          </ScrollAnimation> :""
          }
          
          {loadedPages.includes(5) ?
          <ScrollAnimation>
            <div id="product">
              <Product />
            </div>
          </ScrollAnimation>:""}
          {loadedPages.includes(6) ? 
          <ScrollAnimation>
            <div id="activity">
              <Activity />
            </div>
          </ScrollAnimation>:""}
          {loadedPages.includes(7) ?
          <ScrollAnimation>
            <div id="contact">
              <Contact />
            </div>
          </ScrollAnimation>:""}
        </div>
      )}
      {/* <Footer/> */}
    </>
  );
};

export default Home;
