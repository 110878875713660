export const CREATE_GALLERY_REQUEST = "CREATE_GALLERY_REQUEST";
export const CREATE_GALLERY_SUCCESS = "CREATE_GALLERY_SUCCESS";
export const CREATE_GALLERY_FAIL = "CREATE_GALLERY_FAIL";

export const FETCH_GALLERY_REQUEST = "FETCH_GALLERY_REQUEST";
export const FETCH_GALLERY_SUCCESS = "FETCH_GALLERY_SUCCESS";
export const FETCH_GALLERY_FAIL = "FETCH_GALLERY_FAIL";

export const DELETE_GALLERY_REQUEST = "  DELETE_GALLERY_REQUEST";
export const DELETE_GALLERY_SUCCESS = "  DELETE_GALLERY_SUCCESS";
export const DELETE_GALLERY_FAIL = " DELETE_GALLERY_FAIL";

export const SHOW_GALLERY_REQUEST = "  SHOW_GALLERY_REQUEST";
export const SHOW_GALLERY_SUCCESS = "  SHOW_GALLERY_SUCCESS";
export const SHOW_GALLERY_FAIL = " SHOW_GALLERY_FAIL";

export const UPDATE_GALLERY_REQUEST = "  UPDATE_GALLERY_REQUEST";
export const UPDATE_GALLERY_SUCCESS = "  UPDATE_GALLERY_SUCCESS";
export const UPDATE_GALLERY_FAIL = " UPDATE_GALLERY_FAIL";
