import { CREATE_IMAGE_ACTIVITY_FAIL, CREATE_IMAGE_ACTIVITY_REQUEST, CREATE_IMAGE_ACTIVITY_SUCCESS, DELETE_IMAGE_ACTIVITY_FAIL, DELETE_IMAGE_ACTIVITY_REQUEST, DELETE_IMAGE_ACTIVITY_SUCCESS, SHOW_IMAGE_BY_ACTIVITY_ID_FAIL, SHOW_IMAGE_BY_ACTIVITY_ID_REQUEST, SHOW_IMAGE_BY_ACTIVITY_ID_SUCCESS } from "../ActionType/ActivityType/ActivityType";

export const createImageActivityReducer = (
    state = { loading: true, gallerys: [],total:0, error: "" },
    action
  ) => {
    switch (action.type) {
      case CREATE_IMAGE_ACTIVITY_REQUEST:
        return {
          loading: true,
        };
      case CREATE_IMAGE_ACTIVITY_SUCCESS:
        return {
          loading: false,
          gallerys: action.payload,
          total:action.total,
          error: "",
        };
      case CREATE_IMAGE_ACTIVITY_FAIL:
        return {
          loading: false,
          gallerys: [],
          error: "",
        };
      default:
        return state;
    }
  };


  export const showImageByActivityIdReducer = (
    state = { loading: true, activitys: [],total:0, error: "" },
    action
  ) => {
    switch (action.type) {
      case SHOW_IMAGE_BY_ACTIVITY_ID_REQUEST:
        return {
          loading: true,
        };
      case SHOW_IMAGE_BY_ACTIVITY_ID_SUCCESS:
        return {
          loading: false,
          activitys: action.payload,
          total:action.total,
          error: "",
        };
      case SHOW_IMAGE_BY_ACTIVITY_ID_FAIL:
        return {
          loading: false,
          activitys: [],
          error: "",
        };
      default:
        return state;
    }
  };


  export const deleteImageActivityReducer = (
    state = { loading: true, activitys: [],total:0, error: "" },
    action
  ) => {
    switch (action.type) {
      case DELETE_IMAGE_ACTIVITY_REQUEST:
        return {
          loading: true,
        };
      case DELETE_IMAGE_ACTIVITY_SUCCESS:
        return {
          loading: false,
          activitys: action.payload,
          total:action.total,
          error: "",
        };
      case DELETE_IMAGE_ACTIVITY_FAIL:
        return {
          loading: false,
          activitys: [],
          error: "",
        };
      default:
        return state;
    }
  };