import {
  CREATE_BG_GALLERY_FAIL,
  CREATE_BG_GALLERY_REQUEST,
  CREATE_BG_GALLERY_SUCCESS,
  DELETE_BG_GALLERY_FAIL,
  DELETE_BG_GALLERY_REQUEST,
  DELETE_BG_GALLERY_SUCCESS,
  FETCH_BG_GALLERY_FAIL,
  FETCH_BG_GALLERY_REQUEST,
  FETCH_BG_GALLERY_SUCCESS,
  SHOW_BG_GALLERY_FAIL,
  SHOW_BG_GALLERY_REQUEST,
  SHOW_BG_GALLERY_SUCCESS,
} from "../ActionType/GallaryType/BgGalleryType";

export const getBgGalleryReducer = (
  state = { loading: true, bgGallery: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_BG_GALLERY_REQUEST:
      return {
        loading: true,
      };
    case FETCH_BG_GALLERY_SUCCESS:
      return {
        loading: false,
        bgGallery: action.payload,
        // total:action.total,
        error: "",
      };
    case FETCH_BG_GALLERY_FAIL:
      return {
        loading: false,
        bgGallery: [],
        error: "",
      };
    default:
      return state;
  }
};

export const createBgGalleryReducer = (
  state = { loading: true, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_BG_GALLERY_REQUEST:
      return {
        loading: true,
      };
    case CREATE_BG_GALLERY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case CREATE_BG_GALLERY_FAIL:
      return {
        loading: false,
        data: [],
        error: "",
      };
    default:
      return state;
  }
};

export const deleteBgGalleryReducer = (
  state = { loading: true, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case DELETE_BG_GALLERY_REQUEST:
      return {
        loading: true,
      };
    case DELETE_BG_GALLERY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case DELETE_BG_GALLERY_FAIL:
      return {
        loading: false,
        data: [],
        error: "",
      };
    default:
      return state;
  }
};

export const showBgGalleryReducer = (
  state = { loading: true, datas: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_BG_GALLERY_REQUEST:
      return {
        loading: true,
      };
    case SHOW_BG_GALLERY_SUCCESS:
      return {
        loading: false,
        datas: action.payload,
        error: "",
      };
    case SHOW_BG_GALLERY_FAIL:
      return {
        loading: false,
        datas: [],
        error: "",
      };
    default:
      return state;
  }
};
