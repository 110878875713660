import {
  CREATE_PDF_FAIL,
  CREATE_PDF_REQUEST,
  CREATE_PDF_SUCCESS,
  DELETE_PDF_FAIL,
  DELETE_PDF_REQUEST,
  DELETE_PDF_SUCCESS,
  FETCH_PDF_FAIL,
  FETCH_PDF_REQUEST,
  FETCH_PDF_SUCCESS,
} from "../ActionType/PdfType/PdfType";

export const createPDFReduer = (
  state = { loading: true, pdfs: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_PDF_REQUEST:
      return {
        loading: true,
      };
    case CREATE_PDF_SUCCESS:
      return {
        loading: false,
        pdfs: action.payload,
        error: "",
      };
    case CREATE_PDF_FAIL:
      return {
        loading: false,
        pdfs: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};

export const getPdfReduer = (
  state = { loading: true, pdfs: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_PDF_REQUEST:
      return {
        loading: true,
      };
    case FETCH_PDF_SUCCESS:
      return {
        loading: false,
        pdfs: action.payload,
        total: action.total,
        error: "",
      };
    case FETCH_PDF_FAIL:
      return {
        loading: false,
        pdfs: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};

export const deletePdfReduer = (
  state = { loading: true, pdf: [], error: "" },
  action
) => {
  switch (action.type) {
    case DELETE_PDF_REQUEST:
      return {
        loading: true,
      };
    case DELETE_PDF_SUCCESS:
      return {
        loading: false,
        pdf: action.payload,
        error: "",
      };
    case DELETE_PDF_FAIL:
      return {
        loading: false,
        pdf: [],
        error: "Please CHeck your Query",
      };
    default:
      return state;
  }
};
