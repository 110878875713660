
import Swal from "sweetalert2";

import { api } from "../../Api/api";
import { getToken } from "../login";
import { CREATE_BG_CATALOGE_FAIL, CREATE_BG_CATALOGE_REQUEST, CREATE_BG_CATALOGE_SUCCESS, DELETE_BG_CATALOGE_FAIL, DELETE_BG_CATALOGE_REQUEST, DELETE_BG_CATALOGE_SUCCESS, FETCH_BG_CATALOGE_FAIL, FETCH_BG_CATALOGE_REQUEST, FETCH_BG_CATALOGE_SUCCESS, SHOW_BG_CATALOGE_FAIL, SHOW_BG_CATALOGE_REQUEST, SHOW_BG_CATALOGE_SUCCESS, UPDATE_BG_CATALOGE_FAIL, UPDATE_BG_CATALOGE_SUCCESS } from "../../ActionType/CatalogeType/bgCatalogeType";

export const createBgCatalogeAction =
  ( image) => (dispatch) => {
    const formData = new FormData();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: CREATE_BG_CATALOGE_REQUEST,
    });
    
    formData.append("bg_image", image);

    api
      .post("bgCataloge/create", formData, config)
      .then((response) => {
        const { status, data } = response;
        if (status == 200) {
          dispatch({
            type: CREATE_BG_CATALOGE_SUCCESS,
            payload: data,
          });
          Swal.fire({
            icon: "success",
            timer: 2000,

            title: "Goode Job",
            text: "Information was Saved Successfuly",
          });
        }
      })
      .catch((erro) => {
        dispatch({
          type: CREATE_BG_CATALOGE_FAIL,
        });
        Swal.fire({
          icon: "error",
          timer: 2000,

          title: "Opps ...",
          text: "Check Your Request",
        });
      });
  };

export const getBgCatalogeAction = () => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    
  };

  dispatch({
    type: FETCH_BG_CATALOGE_REQUEST,
  });

  api
    .get("bgCataloge", config)
    .then((response) => {
        
    //   console.log(response)
      const { status, data } = response;
      if (status == 200) {
        dispatch({
          type: FETCH_BG_CATALOGE_SUCCESS,
          payload: data,
        //   total: data.total,
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: FETCH_BG_CATALOGE_FAIL,
        // payload:data,
      });
      
    });
};

export const deleteBgCatalogeAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: DELETE_BG_CATALOGE_REQUEST,
  });

  api
    .delete("bgCataloge/delete/" + id, config)
    .then((response) => {
      const { status, data } = response;
      if (status == 200) {
        dispatch({
          type: DELETE_BG_CATALOGE_SUCCESS,
          payload: data.data,
        });
        Swal.fire({
          icon: "success",
          timer: 2000,

          title: "Goode Job",
          text: "Information was Delete Successfuly",
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: DELETE_BG_CATALOGE_FAIL,
        // payload:data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,

        title: "Opps ...",
        text: "Check Your Request",
      });
    });
};

export const showBgCatalogeAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: SHOW_BG_CATALOGE_REQUEST,
  });

  api
    .get("bgCataloge/show/" + id, config)
    .then((response) => {
      console.log(response)
      const { status, data } = response;
      if (status == 200) {
        // console.log(data);
        dispatch({
          type: SHOW_BG_CATALOGE_SUCCESS,
          payload: data,
        });
      }
    })
    .catch((erro) => {
      dispatch({
        type: SHOW_BG_CATALOGE_FAIL,
        // payload:data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,

        title: "Opps ...",
        text: "Check Your Request",
      });
    });
};

export const updateBgCatalogeAction =
  (id,  image) => (dispatch) => {
    const formData = new FormData();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    
    formData.append("bg_image", image);

    api
      .post(`bgCataloge/update/${id}`, formData, config)
      .then((response) => {
        // console.log(response);
        const { status, data } = response;
        if (status == 200) {
          // console.log(data);
          dispatch({
            type: UPDATE_BG_CATALOGE_SUCCESS,
            payload: data,
          });

          Swal.fire({
            icon: "success",
            timer: 2000,

            title: "Goode Job",
            text: "Information was Update Successfuly",
          });
        }
      })
      .catch((erro) => {
        dispatch({
          type: UPDATE_BG_CATALOGE_FAIL,
          // payload:data,
        });
        Swal.fire({
          icon: "error",
          timer: 2000,

          title: "Opps ...",
          text: "Check Your Request",
        });
      });
  };
