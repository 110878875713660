import React, { useEffect, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import Progress from "../Progress/Progress";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { showActivityAction } from "../../Action/ActivityAction/ActivityAction";
import { showImageByActivityIdAction } from "../../Action/ImageActivityAction/ImageActivityAction";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
const ActivityDetalis = () => {

  const [hiddenBottomImgae, setHiddenImageBottom] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const openImage = (index) => {
    // console.log(index)
    setCurrentImageIndex(index);
  };
  const handleKeyDown = (e) => {
    if (e.key === "ArrowLeft") {
      // handlePrev();
      prevImageModal();
    } else if (e.key === "ArrowRight") {
      // handleNext();
      nextImageModal();
    }
  };

  useEffect(() => {
    if (currentImageIndex == null) {
      return;
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentImageIndex]);
  const { id } = useParams();
  //   const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { lang } = useParams();
  const getLanguge = useSelector((state) => state.getLanguge);
  const { lang } = getLanguge;

  useEffect(() => {
    dispatch(showActivityAction(id));
  }, []);

  const showImageByActivityId = useSelector(
    (state) => state.showImageByActivityId
  );
  const { activitys } = showImageByActivityId;
  // const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showImageByActivityIdAction(id));
  }, []);

  const showActivity = useSelector((state) => state.showActivity);
  const { loading, activity } = showActivity;

  const itemsPerPageDesktop = 7;
  const itemsPerPageMobile = 2;
  const [currentPage, setCurrentPage] = useState(0);
  // const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const itemsPerPage = () => {
    return window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop;
  };

  if (!activitys || activitys.length === 0) {
    return null;
  }

  const totalItems = activitys.length;
  const showSlider = totalItems >= itemsPerPageDesktop; // Condition to show slider

  const startIdx = currentPage * itemsPerPage();
  const endIdx = Math.min((currentPage + 1) * itemsPerPage(), activitys.length);

  const handleNext = () => {
    setCurrentPage(
      (prevPage) => (prevPage + 1) % Math.ceil(totalItems / itemsPerPage())
    );
  };

  const handlePrev = () => {
    setCurrentPage(
      (prevPage) =>
        (prevPage - 1 + Math.ceil(totalItems / itemsPerPage())) %
        Math.ceil(totalItems / itemsPerPage())
    );
  };
  const handleSliderChange = (newIndex) => {
    // Calculate the new current page based on the selected image index
    const newPage = Math.floor(newIndex / itemsPerPage());

    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  };

  const closeImage = () => {
    setCurrentImageIndex(null);
  };
  const nextImageModal = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === activitys.length - 1 ? 0 : prevIndex + 1
    );
    handleSliderChange(currentImageIndex);
  };

  const prevImageModal = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? activitys.length - 1 : prevIndex - 1
    );
    handleSliderChange(currentImageIndex);
  };

  return (
    <>
      {activity == undefined || loading || !activitys ? (
        ""
      ) : (
        <>
          <motion.div
            initial={{ y: -100, transition: { duration: 1 } }}
            animate={{ y: 0, transition: { duration: 1 } }}
            // className="   m-auto  object-cover  h-screen   brightness-[.7]"
            id="product"
          >
            <img
              src={activity.image}
              className="mt-16 lg:mt-0 bg-black lg:bg-top bg-center lg:bg-cover relative lg:h-screen brightness-[.7] lg:w-full "
              alt=""
            />
          </motion.div>

          <motion.div
            initial={{ x: -90, transition: { duration: 1 } }}
            animate={{ x: 0, transition: { duration: 1 } }}
            className=" mt-9 text-white w-11/12 m-auto py-2    px-3 lg:px-32 hover:cursor-pointer   pb-20"
          >
            <div>
              <p
                className={
                  lang == "ar"
                    ? "font-bold text-4xl text-right "
                    : "font-bold text-4xl text-left"
                }
              >
                {lang == "en" ? (
                  <p>{activity.title}</p>
                ) : lang == "ar" ? (
                  <p>{activity.title_ar}</p>
                ) : lang == "tu" ? (
                  <p>{activity.title_tu}</p>
                ) : (
                  <p>{activity.title}</p>
                )}
              </p>
            </div>
            {lang == "en" && (
              <p className="text-xl mt-2">
                {activity.en_text.split(".").map((sentence, index, array) => (
                  <React.Fragment key={index}>
                    {sentence.trim()}
                    {index !== array.length - 1 && (
                      <span>
                        . <br /> <br />
                      </span>
                    )}{" "}
                    {/* Add a line break after each sentence except for the last one */}
                  </React.Fragment>
                ))}
              </p>
            )}
            {lang == "ar" && (
              <p className="text-xl text-[rgba(255,255,255,0.5)] text-right mt-2">
                {activity.ar_text.split(".").map((sentence, index, array) => (
                  <React.Fragment key={index}>
                    {sentence.trim()}
                    {index !== array.length - 1 && (
                      <span>
                        . <br /> <br />
                      </span>
                    )}{" "}
                    {/* Add a line break after each sentence except for the last one */}
                  </React.Fragment>
                ))}
              </p>
            )}
            {lang == "tu" && (
              <p className="text-xl text-[rgba(255,255,255,0.5)] mt-2">
                {activity.tu_text.split(".").map((sentence, index, array) => (
                  <React.Fragment key={index}>
                    {sentence.trim()}
                    {index !== array.length - 1 && (
                      <span>
                        <br /> <br />
                      </span>
                    )}{" "}
                    {/* Add a line break after each sentence except for the last one */}
                  </React.Fragment>
                ))}
              </p>
            )}
            {lang == "" && (
              <p className="text-xl text-[rgba(255,255,255,0.5)] mt-2">
                {activity.en_text.split(".").map((sentence, index, array) => (
                  <React.Fragment key={index}>
                    {sentence.trim()}
                    {index !== array.length - 1 && (
                      <span>
                        . <br /> <br />
                      </span>
                    )}{" "}
                    {/* Add a line break after each sentence except for the last one */}
                  </React.Fragment>
                ))}
              </p>
            )}
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-5 px-10 pb-20">
          {activitys.slice(startIdx, endIdx).map((item, index) => (
                  <motion.div
                    key={index + startIdx}
                    // initial={{ opacity: 0, y: 20, transition: { duration: 1 } }}
                    // animate={{ opacity: 1, y: 0, transition: { duration: 1 } }}
                    // exit={{ opacity: 0, y: 20, transition: { duration: 1 } }}
                    className=""
                  >
                    <div className="">
                      <div className="">
                        <Link onClick={() => openImage(index + startIdx)}>
                          <motion.img
                            src={item.image}
                            className="rounded-md bg-cover shadow-md hover:scale-105 duration-500"
                            alt={`Gallery Item ${index + startIdx}`}
                          />
                        </Link>
                      </div>
                    </div>
                  </motion.div>
                ))}
          </div>

          {/* <AnimatePresence>
            {currentImageIndex !== null && (
              <motion.div
                key="modal"
                className="fixed top-0 z-50 bg-black w-full  left-0 "
              >
                <motion.div
                  key="modalContent"
                  // initial={{ opacity: 0, y: 20 }}
                  // animate={{ opacity: 1, y: 0 }}
                  // exit={{ opacity: 0, y: 20 }}
                  className="bg-black relative z-50"
                  // transition={{ duration: 2 }}
                >
                  <motion.img
                    key={activitys[currentImageIndex].image}
                    src={activitys[currentImageIndex].image}
                    alt={`Gallery Item ${currentImageIndex}`}
                    className="h-screen w-full brightness-90 object-contain bg-black"

                    // transition={{ duration: 2 }}
                  />
                  <motion.div
                    // initial={{ opacity: 0.1, y: -10, transition: { duration: 1 } }}
                    // animate={{ y: 0, transition: { duration: 1 }, opacity: 1 }}
                    className="flex   lg:flex gap-x-4 gap-y-3 justify-center  items-center  relative"
                  >
                    <button
                      onClick={handlePrev}
                      className="absolute left-0   bg-[#ab8554] rounded-md shadow-md lg:left-72 bottom-56 lg:bottom-20 transform -translate-y-1/2 px-4 py-2 text-white   cursor-pointer  "
                    >
                      <FaArrowLeft />
                    </button>
                    <button
                      onClick={handleNext}
                      className="absolute right-0   rotate-180 lg:right-72 bg-[#ab8554] rounded-md shadow-md bottom-56 lg:bottom-20 transform -translate-y-1/2 px-4 py-2 text-white   cursor-pointer"
                    >
                      <FaArrowLeft />
                    </button>

                    {activitys.slice(startIdx, endIdx).map((item, index) => (
                      <motion.div key={index + startIdx} className="">
                        <div className="">
                          <div className="">
                            <Link onClick={() => openImage(index + startIdx)}>
                              <motion.img
                                src={item.image}
                                className={`relative bottom-56 h-16 lg:bottom-20 rounded-md border-4 object-cover duration-500 hover:scale-105 ${
                                  index + startIdx === currentImageIndex
                                    ? "relative bottom-56 lg:bottom-20 h-16 rounded-md border-4 object-cover duration-500 hover:scale-110 border-[#AA8453]  "
                                    : ""
                                }`}
                                alt={`Gallery Item ${index + startIdx}`}
                                // initial={{ opacity: 0 }}
                                // animate={{
                                //   opacity: 1,
                                //   transition: { duration: 0.5 },
                                // }}
                              />
                            </Link>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </motion.div>

                  <button
                    onClick={(e) => prevImageModal(e)}
                    className="absolute top-1/2  left-4 transform rotate-180 animate-pulse text-xl -translate-y-1/2 text-gray-100 hover:text-white duration-300"
                  >
                    <FaArrowRight />
                  </button>
                  <button
                    onClick={(e) => nextImageModal(e)}
                    className="absolute top-1/2 right-4 transform text-xl -translate-y-1/2 animate-pulse text-gray-100 hover:text-white duration-300"
                  >
                    <FaArrowRight />
                  </button>
                  <span
                    className="close absolute top-20 lg:top-4 right-4 text-white text-3xl lg:text-5xl cursor-pointer"
                    onClick={closeImage}
                  >
                    &times;
                  </span>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence> */}

<AnimatePresence>
        {currentImageIndex !== null && (
          <motion.div
            key="modal"
            className="fixed top-0 z-50  w-full bg-[#100f0f] left-0 "
            // onMouseMove={()=>setHiddenImageBottom(false)}
          >
            <motion.div
              key="modalContent"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="  "
              transition={{ duration: 0.5 }}
            >
              <motion.img
                onMouseMove={() => setHiddenImageBottom(false)}
                onMouseLeave={() => setHiddenImageBottom(true)}
                key={activitys[currentImageIndex].image}
                src={activitys[currentImageIndex].image}
                alt={`Gallery Item ${currentImageIndex}`}
                className="w-screen h-screen  z-50 m-auto brightness-90  object-contain "
                transition={{ duration: 0.5 }}
              />
      
                <motion.div
                  // initial={{ opacity: 0.1, y: -10, transition: { duration: 1 } }}
                  // animate={{ y: 0, transition: { duration: 1 }, opacity: 1 }}
                  className={`flex   lg:flex gap-x-4 gap-y-3 justify-center ${hiddenBottomImgae ?"opacity-100 duration-700":" md:opacity-0 duration-700"}  items-center  relative`}
                  
                >
                  <button
                    onClick={handlePrev}
                    className="absolute left-0   bg-[#ab8554] rounded-md shadow-md lg:left-72 bottom-56 lg:bottom-20 transform -translate-y-1/2 px-4 py-2 text-white   cursor-pointer  "
                  >
                    <FaArrowLeft />
                  </button>
                  <button
                    onClick={handleNext}
                    className="absolute right-0   rotate-180 lg:right-72 bg-[#ab8554] rounded-md shadow-md bottom-56 lg:bottom-20 transform -translate-y-1/2 px-4 py-2 text-white   cursor-pointer"
                  >
                    <FaArrowLeft />
                  </button>

                  {activitys.slice(startIdx, endIdx).map((item, index) => (
                    <motion.div key={index + startIdx} >
                      <div className="">
                        <div className="">
                          <Link onClick={() => openImage(index + startIdx)}>
                            <motion.img
                              src={item.image}
                              className={`relative  bottom-56 h-16 lg:bottom-20 rounded-md border-4 object-cover duration-500 hover:scale-105 ${
                                index + startIdx === currentImageIndex
                                  ? "relative bottom-56 lg:bottom-20 h-16 rounded-md border-4 object-cover duration-500 hover:scale-110 border-[#AA8453]  "
                                  : ""
                              }`}
                              alt={`Gallery Item ${index + startIdx}`}
                              // initial={{ opacity: 0 }}
                              // animate={{
                              //   opacity: 1,
                              //   transition: { duration: 0.5 },
                              // }}
                            />
                          </Link>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </motion.div>
          

              <button
                onClick={(e) => prevImageModal(e)}
                className="absolute top-1/2  bg-[#ab8554] px-3 py-2 left-4 transform rotate-180 animate-pulse text-xl -translate-y-1/2 text-gray-100 hover:text-white duration-300"
              >
                <FaArrowRight />
              </button>
              <button
                onClick={(e) => nextImageModal(e)}
                className="absolute top-1/2 right-4 transform bg-[#ab8554] px-3 py-2 text-xl -translate-y-1/2 animate-pulse text-gray-100 hover:text-white duration-300"
              >
                <FaArrowRight />
              </button>
              <span
                className="close absolute top-20 lg:top-4 right-4 text-white text-3xl lg:text-5xl cursor-pointer"
                onClick={closeImage}
              >
                &times;
              </span>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>


          <style>
            {`
            body {
              overflow: ${currentImageIndex == null ? "auto" : "hidden"};
            }
          `}
          </style>

          {currentImageIndex==null &&<Progress />}
        </>
      )}
    </>
  );
};

export default ActivityDetalis;
