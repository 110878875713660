import { api } from "../../Api/api";

import Swal from "sweetalert2";
import { getToken } from "../login";
import {
  CREATE_HOME_FAIL,
  CREATE_HOME_REQUEST,
  CREATE_HOME_SUCCESS,
  DELETE_HOME_FAIL,
  DELETE_HOME_REQUEST,
  DELETE_HOME_SUCCESS,
  FETCH_HOME_FAIL,
  FETCH_HOME_REQUEST,
  FETCH_HOME_SUCCESS,
  SHOW_HOME_FAIL,
  SHOW_HOME_REQUEST,
  SHOW_HOME_SUCCESS,
  UPDATE_HOME_FAIL,
  UPDATE_HOME_REQUEST,
  UPDATE_HOME_SUCCESS,
} from "../../ActionType/HomeType/HomeType";

export const getHomeAction = () => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  // dispatch({
  //   type: FETCH_HOME_REQUEST,
  // });
  api
    .get("home", config)
    .then((res) => {
      dispatch({
        type: FETCH_HOME_SUCCESS,
        payload: res.data.data,
        // total: res.data.total,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_HOME_FAIL,
        payload: [],
        error: "",
      });
    });
};

export const createHomeAction =
  (title_en, title_ar, title_tu, desc, desc_ar, desc_tu, link) =>
  (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    const formData = new FormData();

    dispatch({
      type: CREATE_HOME_REQUEST,
    });

    api
      .post(
        "home/create",
        {
          title: title_en,
          title_ar,
          title_tu,
          desc,
          desc_ar,
          desc_tu,
          link,
        },
        config
      )
      .then((res) => {
        console.log(res);
        if (res.status == 201) {
          dispatch({
            type: CREATE_HOME_SUCCESS,
            payload: res.data,
          });

          Swal.fire({
            icon: "success",
            timer: 2000,
            title: "Good Job",
            text: "Information Was Saved",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          timer: 2000,
          title: "Error",
          text: "Please Check Your Request",
        });
        dispatch({
          type: CREATE_HOME_FAIL,
          payload: [],
        });
      });
  };

export const deleteHomeAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: DELETE_HOME_REQUEST,
  });
  api
    .delete(`home/delete/${id}`, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: DELETE_HOME_SUCCESS,
          payload: res.data,
        });
        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Delete",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: DELETE_HOME_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      console.log(err);
    });
};

export const showHomeAction = (id) => (dispatch) => {
  //   console.log(formData)
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: SHOW_HOME_REQUEST,
    // payload: res.data,
  });
  api
    .get(`home/show/${id}`, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: SHOW_HOME_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SHOW_HOME_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      // console.log(err);
    });
};

export const updateHomeAction =
  (id, title, title_ar, title_tu, desc, desc_ar, desc_tu, link) =>
  (dispatch) => {
    // const formData = new FormData();
    console.log( title, title_ar, title_tu, desc, desc_tu, desc_ar, link)
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: UPDATE_HOME_REQUEST,
      // payload: res.data,
    });
    api
      .post(
        `home/update/${id}`,
        { title, title_ar, title_tu, desc, desc_tu, desc_ar, link },
        config
      )
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: UPDATE_HOME_SUCCESS,
            payload: res.data,
          });
          //   console.log(res)
          Swal.fire({
            icon: "success",
            timer: 2000,
            title: "Good Job",
            text: "Information Was Saved",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_HOME_FAIL,
          // payload: res.data,
        });
        console.log(err)
        Swal.fire({
          icon: "error",
          timer: 2000,
          title: "Error",
          text: "Please Check Your Request",
        });
        console.log(err);
      });
  };
