import { CREATE_CATALOGE_FAIL, CREATE_CATALOGE_REQUEST, CREATE_CATALOGE_SUCCESS, DELETE_CATALOGE_FAIL, DELETE_CATALOGE_REQUEST, DELETE_CATALOGE_SUCCESS, FETCH_CATALOGE_FAIL, FETCH_CATALOGE_REQUEST, FETCH_CATALOGE_SUCCESS, SHOW_CATALOGE_FAIL, SHOW_CATALOGE_REQUEST, SHOW_CATALOGE_SUCCESS, UPDATE_CATALOGE_FAIL, UPDATE_CATALOGE_REQUEST, UPDATE_CATALOGE_SUCCESS } from "../ActionType/CatalogeType/CatalogeType";

  
  export const getCatalogeReducer = (
      state = { loading: true, cataloges: [],total:0, error: "" },
      action
    ) => {
      switch (action.type) {
        case FETCH_CATALOGE_REQUEST:
          return {
            loading: true,
          };
        case FETCH_CATALOGE_SUCCESS:
          return {
            loading: false,
            cataloges: action.payload,
            total:action.total,
            error: "",
          };
        case FETCH_CATALOGE_FAIL:
          return {
            loading: false,
            cataloges: [],
            error: "",
          };
        default:
          return state;
      }
    };
  
    
  export const createCatalogeReducer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case CREATE_CATALOGE_REQUEST:
        return {
          loading: true,
        };
      case CREATE_CATALOGE_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case CREATE_CATALOGE_FAIL:
        return {
          loading: false,
          data: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  export const deleteCatalogeReducer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case DELETE_CATALOGE_REQUEST:
        return {
          loading: true,
        };
      case DELETE_CATALOGE_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case DELETE_CATALOGE_FAIL:
        return {
          loading: false,
          data: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  
  export const showCatalogeReducer = (
    state = { loading: true, datas: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case SHOW_CATALOGE_REQUEST:
        return {
          loading: true,
        };
      case SHOW_CATALOGE_SUCCESS:
        return {
          loading: false,
          datas: action.payload,
          error: "",
        };
      case SHOW_CATALOGE_FAIL:
        return {
          loading: false,
          datas: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  export const updateCatalogeReduer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case UPDATE_CATALOGE_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_CATALOGE_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case UPDATE_CATALOGE_FAIL:
        return {
          loading: false,
          data: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };